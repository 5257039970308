import React from 'react'
import { gql, useQuery } from 'urql'

type FeatureId =
  | 'exp_lounge_chatbot_ask_feedback'
  | 'exp_lounge_instant_encores'
  | 'exp_sponsorships'
  | 'showAltLiveLabels'
  | 'showEncoreBadging'
  | 'exp_lounge_embedded_chat_ask_name'
  | 'exp_embedded_lounge_chatbot_ask_feedback'
  | 'exp_embedded_lounge_chat_affiliate_marketing_message'

type ProviderProps = { children: React.ReactNode; embeddingOrgId?: string }
type FeatureFlags = Record<string, boolean>
type FeatureToggleContext = { embeddingOrgId?: string; features: FeatureFlags }

const Context = React.createContext<FeatureToggleContext | undefined>(undefined)

export const QUERY_FEATURE_TOGGLES = gql`
  query FeatureToggles {
    features(currentVersion: "", appName: "gsu-web") {
      id
      enabled
    }
  }
`

export function FeatureToggleProvider({ children, embeddingOrgId }: ProviderProps): JSX.Element {
  const features: FeatureFlags = {}
  const [{ data, fetching }] = useQuery({ query: QUERY_FEATURE_TOGGLES })
  if (data?.features) {
    data.features.forEach((element: { id: string; enabled: boolean }) => {
      features[element.id] = element.enabled
    })
  }

  if (fetching) return null
  return <Context.Provider value={{ embeddingOrgId, features }}>{children}</Context.Provider>
}

/**
 * Optional parameters for useFeatureToggle hook:
 * @param defaultValue The boolean value should be returned when embeddingOrgId is different from forEmbeddingOrgId OR featue flag is not set up in ConfigCat.
 * @param forEmbeddingOrgId The orgId for which the feature flag is applicable for. For e.g. `aol`
 */
interface FeatureToggleOptionalParams {
  defaultValue?: boolean // Added defaultValue to avoid convention required while defining feature toggle flag in ConfigCat like appended enabled/disabled so that we can decide the feature is true or false by default on the client side.
  forEmbeddingOrgId?: string
}

/**
 * A hook for querying the state of a feature toggle.
 * @param featureId The Id of the feature as set up in ConfigCat. Probably a human readable string. E.g. `red_header_on_home_page`
 * @param optionalParams FeatureToggleOptionalParams
 * @returns A boolean indicating whether this feature toggle is enabled for this user.
 */
export function useFeatureToggle(featureId: FeatureId, optionalParams?: FeatureToggleOptionalParams): boolean {
  const context = React.useContext(Context)
  const defaultValue = optionalParams?.defaultValue ?? true
  if (context === undefined) {
    throw new Error('useFeatureToggles must be used within a FeatureToggleProvider')
  }
  // If embeddingOrgId isn't matched with forEmbeddingOrgId, return default value
  if (context.embeddingOrgId !== optionalParams?.forEmbeddingOrgId) return defaultValue
  return context.features[featureId] ?? defaultValue
}
