import classNames from 'classnames/bind'
import React from 'react'
import Icon from '~/components/v2/icon'
import Loader from '~/components/widgets/Loader'
import { ButtonVariants } from '~/themes'
import { ICONS, SIZES as IconSizes } from '~/utils/icons'
import * as styles from './style'

const cx = classNames.bind(styles)

export type Sizes = 'xxxs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export interface Props {
  children?: string | JSX.Element
  className?: string
  disabled?: boolean
  fontSize?: Sizes
  hideTextOnSmallScreens?: boolean
  iconLeft?: ICONS
  iconRight?: ICONS
  iconSize?: IconSizes
  label?: React.ReactNode | React.ReactNode[]
  loading?: boolean
  sharpCorners?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  stretch?: boolean
  theme?: ButtonVariants
  onClick?: ((event: React.MouseEvent) => void) | (() => void) | ((...args: any[]) => void)
}

export default React.forwardRef(function Button(
  {
    className,
    disabled,
    hideTextOnSmallScreens,
    iconLeft,
    iconRight,
    iconSize,
    label,
    sharpCorners = false,
    size = 'md',
    stretch,
    theme = 'primary',
    loading,
    onClick,
  }: Props,
  ref: React.Ref<any>,
): JSX.Element {
  const classes = cx({
    root: true,
    [styles[`${size}`]]: true,
    [`${className}`]: true,
    disabled,
    loading,
    stretch,
    sharpCorners,
    'gsu-btn': true,
    iconOnly: (!!iconLeft && !label && !iconRight) || (!!iconRight && !label && !iconLeft),
    hideTextOnSmallScreens,
  })

  const handleClick = (event: React.MouseEvent): void => {
    if (onClick && !disabled) {
      onClick(event)
    }
    return
  }

  const sz = iconSize ? iconSize : size === 'lg' || size === 'xl' ? IconSizes.NORMAL : IconSizes.SMALL_PLUS

  const labelsIcons = (
    <>
      {iconLeft ? <Icon icon={iconLeft} size={sz} /> : null}
      {label && <span data-node="text">{label}</span>}
      {iconRight ? <Icon icon={iconRight} size={sz} /> : null}
    </>
  )

  if (loading)
    return (
      <button className={classes} disabled sx={{ variant: `buttons.${theme}` }}>
        <Loader loading={true} />
      </button>
    )

  return (
    <span
      data-testid="button-link"
      className={classes}
      ref={ref}
      onClick={handleClick}
      sx={{ variant: `buttons.${theme}` }}
    >
      {labelsIcons}
    </span>
  )
})
