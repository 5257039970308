import type { Theme } from '@theme-ui/core'
import aolTheme from './aol'
import defaultTheme from './default'
import gsudemoTheme from './gsudemo'
import imediaTheme from './imedia'
import silversneakersTheme from './silversneakers'
import womansworldTheme from './womansworld'

export type ButtonVariants =
  | 'accent'
  | 'clear'
  | 'clearMuted'
  | 'glass'
  | 'greyOutline'
  | 'primary'
  | 'primaryOutline'
  | 'secondary'
  | 'secondaryMuted'
  | 'secondaryOutline'
  | 'slideToggle'
  | 'smoke'
  | 'tertiary'
  | 'white'
  | 'whiteOutline'

type AvailableThemes = {
  [key: string]: Theme
}

export const availableThemes: AvailableThemes = {
  aol: aolTheme,
  default: defaultTheme,
  gsudemo: gsudemoTheme,
  imedia: imediaTheme,
  womansworld: womansworldTheme,
  silversneakers: silversneakersTheme,
  'silversneakers-aetna': silversneakersTheme,
  'silversneakers-fitness': silversneakersTheme,
}
