import { css } from 'linaria';
export const customClass = "c1qxyygn";
export const container = "c17ghw43";
export const row = "r1p5gtxg";
export const border = "ban9a8y";
export const code = "cwamm1z";
export const heading = "h1okhhku";
export const settingsDialogReplayMessage = "sk7ewgo";
export const settingsDialogDisabledZoomSettings = "s119vvq9";
export const disableJoin = "d1krdxef";
export const checkbox = "c1udhbof";
export const buttonContainer = "bhdm3os";
export const blueButton = "b1kbfa2t";
export const orangeButton = "olx2jog";

require("../../../../.linaria-cache/components/v2/dialogs/Settings/styles.linaria.module.css");