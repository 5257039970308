import { useRouter } from 'next/router'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { getConfig } from '~/config/config'
import { AppName, eventProducer, LoungeUIElementName, WatchContentType } from '~/modules/events'
import { CurrentUser, LobbySession } from '~/shared/api-generated-types'
import { useAuth } from '~/store/auth'
import { useAppContext } from '~/store/state'

export enum SessionWindowStatus {
  BEFOREEXIT = 'BEFOREEXIT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

const { publicRuntimeConfig } = getConfig()

type EventsTrackerContext = {
  isEventsTrackingReady: boolean
  trackAppStartEvent(): void
  trackClickEvent(targetElement: LoungeUIElementName): void
  trackLobbyPresenceEvent(): void
  trackLobbySessionEvent(sessionStartTime: Date): void
  trackMeetingLaunchEvent(): void
  trackPageViewEvent(): void
  trackSocialChatMessageEvent(): void
  trackSocialReactionEvent(reaction: string): void
  trackWatchEvent(contentType: WatchContentType, durationInSeconds?: number): void
  trackRecommendationClickEvent(targetClassId: string, targetSlotId: string, targetPageUrl: string): void
}

const EventsTrackerContext = createContext<EventsTrackerContext>({
  isEventsTrackingReady: false,
  trackAppStartEvent: () => {},
  trackClickEvent: () => {},
  trackLobbyPresenceEvent: () => {},
  trackLobbySessionEvent: () => {},
  trackMeetingLaunchEvent: () => {},
  trackPageViewEvent: () => {},
  trackSocialChatMessageEvent: () => {},
  trackSocialReactionEvent: () => {},
  trackWatchEvent: () => {},
  trackRecommendationClickEvent: () => {},
})

type Props = {
  children: ReactNode
  currentUser: CurrentUser
  lobbySession: LobbySession | undefined
}

export function EventsTrackerProvider({ children, currentUser, lobbySession }: Props): JSX.Element {
  const router = useRouter()
  const [isEventsTrackingReady, setIsEventsTrackingReady] = useState<boolean>(false)

  const [auth] = useAuth()
  const encodedExternalToken = auth.encodedExternalToken
  const decodedExternalToken = auth.decodedExternalToken

  const { state } = useAppContext()
  const embeddingOrgId = state.embeddingOrgId
  const embeddedDeviceId = state.embeddedDeviceId
  const embeddedAnalyticsInfo = state.embeddedAnalyticsInfo

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      // Trigger events before the page unloads.
      if (embeddingOrgId) {
        // We are in embedded mode.
        // But the page is unloading (user closed tab or navigated away).
        // If we set events tracking ready now then it will do nothing if it was already ready.
        // But if events tracking was not ready before (because we didn't get a token and the timeout hasn't fired yet)
        // then setting it ready now will cause the main component (\components\v2\main\index.tsx)
        // to send the AppStart, LobbyPresence, and PageView events.

        setIsEventsTrackingReady(true)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [embeddingOrgId])

  useEffect(() => {
    // If not an embedded page, exit early and set `isEventsTrackingReady`
    if (!embeddingOrgId) {
      setIsEventsTrackingReady(true)
      return
    }

    // Wait a little for a token, but start sending events if we don't get one soon.
    const timeout = setTimeout(() => {
      setIsEventsTrackingReady(true)
    }, 5000)

    // But also we would need to check for a page unload (navigate away, or close tab, or whatever)
    // And fire the events then, token or not (See beforeunload handler above).

    eventProducer.configureOptions({
      embeddedOptions: {
        identifier: embeddingOrgId,
        deviceIdOverride: embeddedDeviceId,
        embeddedDomain: embeddedAnalyticsInfo?.domain,
      },
    })
    eventProducer

    if (encodedExternalToken) {
      setIsEventsTrackingReady(true)
      clearTimeout(timeout)
    }

    // Cleanup the timeout if necessary when the component unmounts or conditions change
    return () => {
      clearTimeout(timeout)
    }
  }, [encodedExternalToken, router.isReady, embeddingOrgId, embeddedDeviceId])

  useEffect(() => {
    if (isEventsTrackingReady) console.log('Events ready')
  }, [isEventsTrackingReady])

  // NOTE: Do not send the userId for AOL users. AOL considers that private information.
  const userId = embeddingOrgId ? undefined : currentUser.authenticatedUser?.id

  const isTivityUser =
    decodedExternalToken?.iss === 'silversneakers' ||
    decodedExternalToken?.iss === 'silversneakers-aetna' ||
    decodedExternalToken?.iss === 'silversneakers-fitness'
  const tivityUserId = isTivityUser && decodedExternalToken.sub ? decodedExternalToken.sub : undefined
  const tivityClientId = isTivityUser && decodedExternalToken.clientId ? decodedExternalToken.clientId : undefined

  const embeddedPartnerUserId = tivityUserId
  const embeddedPartnerClientId = tivityClientId

  function trackMeetingLaunchEvent(): void {
    if (!lobbySession) return

    eventProducer?.meetingLaunchEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      slotId: lobbySession.id, // not timekitSlotId! this is just for compatibility, sessionId is preferred
      meetingLaunchUrl: lobbySession.launchMeetingUrl,
      pageUrl: window.location.href,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackLobbyPresenceEvent(): void {
    if (!lobbySession) return

    eventProducer?.lobbyPresenceEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      pageUrl: window.location.href,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackLobbySessionEvent(start: Date): void {
    if (!lobbySession) return

    eventProducer?.lobbySessionEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      pageUrl: window.location.href,
      userId: currentUser.authenticatedUser?.id,
      start,
      end: new Date(),
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackSocialChatMessageEvent(): void {
    if (!lobbySession) return

    eventProducer?.socialChatMessageEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      slotId: lobbySession.id, // not timekitSlotId! this is just for compatibility, sessionId is preferred
      pageUrl: window.location.href,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackSocialReactionEvent(reaction: string): void {
    if (!lobbySession) return

    eventProducer?.socialReactionEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      slotId: lobbySession.id, // not timekitSlotId! this is just for compatibility, sessionId is preferred
      pageUrl: window.location.href,
      reaction,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackWatchEvent(contentType: WatchContentType, durationInSeconds: number = 30): void {
    if (!lobbySession || !lobbySession.class) return

    eventProducer?.watchEvent({
      classId: lobbySession.class?.id,
      sessionId: lobbySession.id,
      slotId: lobbySession.id, // not timekitSlotId! this is just for compatibility, sessionId is preferred
      pageUrl: window.location.href,
      contentType,
      contentUrl: lobbySession.stream?.playbackUrl,
      durationInSeconds,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackClickEvent(targetElement: LoungeUIElementName): void {
    if (!lobbySession) return

    eventProducer?.clickEvent({
      uiElementName: targetElement,
      classId: lobbySession.class?.id,
      sessionId: lobbySession?.id,
      slotId: lobbySession?.id, // not timekitSlotId! this is just for compatibility, sessionId is preferred
      pageUrl: window.location.href,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackPageViewEvent(): void {
    eventProducer?.pageViewEvent({
      pageUrl: window.location.pathname,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  function trackAppStartEvent(): void {
    eventProducer?.appStartEvent({ appName: AppName.Lobby, appVersion: publicRuntimeConfig.environment })
  }

  function trackRecommendationClickEvent(targetClassId: string, targetSlotId: string, targetPageUrl: string): void {
    if (!lobbySession) return

    eventProducer?.recommendationClickEvent({
      sourceClassId: lobbySession.class?.id,
      sourceSlotId: lobbySession?.id,
      sourcePageUrl: window.location.href,
      targetClassId,
      targetSlotId,
      targetPageUrl,
      userId,
      embeddedPartnerUserId,
      embeddedPartnerClientId,
    })
  }

  return (
    <EventsTrackerContext.Provider
      value={{
        isEventsTrackingReady,
        trackAppStartEvent,
        trackClickEvent,
        trackLobbyPresenceEvent,
        trackLobbySessionEvent,
        trackMeetingLaunchEvent,
        trackPageViewEvent,
        trackSocialChatMessageEvent,
        trackSocialReactionEvent,
        trackWatchEvent,
        trackRecommendationClickEvent,
      }}
    >
      {children}
    </EventsTrackerContext.Provider>
  )
}

export function useEventsTracker(): EventsTrackerContext {
  return useContext(EventsTrackerContext)
}
