import React from 'react'

export interface Timings {
  aboutToClose: boolean
  aboutToStart: boolean
  hasClosed: boolean
  hasEnded: boolean
  inProgress: boolean
  ready: boolean
  tooEarly: boolean
  tooLate: boolean
}

type StateReducer = React.Reducer<Timings, Partial<Timings>>
type ContextValue = [React.ReducerState<StateReducer>, React.Dispatch<React.ReducerAction<StateReducer>>]

const defaultState: Timings = {
  aboutToClose: false,
  aboutToStart: null,
  hasClosed: null,
  hasEnded: null,
  inProgress: null,
  ready: false,
  tooEarly: true,
  tooLate: null,
}

const stateManager = (prevState: Timings, updatePayload: Partial<Timings>): Timings => {
  return {
    ...prevState,
    ...updatePayload,
  }
}

export const TimingsContext = React.createContext<ContextValue>([
  defaultState,
  (payload) => stateManager(defaultState, payload),
])

export const TimingsContextProvider: React.FunctionComponent<{
  value?: Partial<Timings>
}> = ({ value: injectedValue, children }) => {
  const initialState: Timings = {
    ...defaultState,
    ...injectedValue,
  }

  return (
    <TimingsContext.Provider value={React.useReducer(stateManager, initialState)}>{children}</TimingsContext.Provider>
  )
}

export const useTimings = (): ContextValue => React.useContext(TimingsContext)

export const getTimings = (): Timings => {
  const [state] = useTimings()
  return state
}
