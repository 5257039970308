import { useTranslation } from 'next-i18next'
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { ConnectionHandler } from '~/components/socket'
import { LobbySession } from '~/shared/api-generated-types'
import { getConnection } from '~/store/socket'
import { AppContext, useAppContext } from '~/store/state'
import {
  ChatMessage,
  ExternalUserUpdateStatus,
  Member,
  MessageTypes,
  SessionState,
  SocketHandlers,
  StaticMessageTypes,
} from '~/utils/chat'

type ChatContextType = {
  disableJoin: boolean
  hasStaticMessages: StaticMessageTypes[]
  members: Member[]
  messages: ChatMessage[]
  prompts: string[]
  scrollToBottom: number
  hiddenMessageIds: string[]
  externalUserUpdateStatus: ExternalUserUpdateStatus
  banUser?: (userId: string, name: string) => void
  unBanUser?: (userId: string, name: string) => void
  hideMessage?: (messageId: string) => void
  incrementScrollBottomCounter?: () => void
  sendMessage?: (content: string, type: MessageTypes) => void
  setMessages?: Dispatch<SetStateAction<ChatMessage[]>>
  setHasStaticMessages?: Dispatch<SetStateAction<StaticMessageTypes[]>>
  externalUserUpdate?: (assignedName: string) => void
  showTypingMessage: string | null
}

const chatContextDefaultValues: ChatContextType = {
  disableJoin: false,
  hasStaticMessages: [],
  members: [],
  messages: [],
  prompts: [],
  scrollToBottom: 0,
  hiddenMessageIds: [],
  externalUserUpdateStatus: undefined,
  showTypingMessage: null,
}

const ChatContext = createContext<ChatContextType>(chatContextDefaultValues)

type Props = {
  children: ReactNode | ReactNode[]
  lobbySession: LobbySession | undefined
}

export function ChatProvider({ children, lobbySession }: Props): JSX.Element {
  const { t } = useTranslation('lobby')
  const { state: appState } = useAppContext()
  const { connection, ready } = getConnection()
  const [hasStaticMessages, setHasStaticMessages] = useState<StaticMessageTypes[]>(
    chatContextDefaultValues.hasStaticMessages,
  )
  const [disableJoin, setDisableJoin] = useState<boolean>(chatContextDefaultValues.disableJoin)
  const [messages, setMessages] = useState<ChatMessage[]>(chatContextDefaultValues.messages)
  const [prompts, setPrompts] = useState<string[]>([])
  const [members, setMembers] = useState<Member[]>(chatContextDefaultValues.members)
  const [scrollToBottom, setScrollToBottom] = useState<number>(chatContextDefaultValues.scrollToBottom)
  const [hiddenMessageIds, setHiddenMessageIds] = useState<string[]>(chatContextDefaultValues.hiddenMessageIds)
  const [externalUserUpdateStatus, setExternalUserUpdateStatus] = useState<ExternalUserUpdateStatus>(
    chatContextDefaultValues.externalUserUpdateStatus,
  )

  const [showTypingMessage, setShowTypingState] = useState<string | null>(null)

  const banUser = useCallback(
    (userId, name) => {
      if (!connection || !ready) return
      const confirmed = window.confirm(t('confirmBanUser', { name, userId }))
      if (confirmed) {
        connection?.emit('banUser', { userId })
      }
    },
    [connection, ready],
  )

  const unBanUser = useCallback(
    (userId, name) => {
      if (!connection || !ready) return
      const confirmed = window.confirm(t('confirmUnbanUser', { name, userId }))
      if (confirmed) {
        connection.emit('unBanUser', { userId })
      }
    },
    [connection, ready],
  )

  const hideMessage = useCallback(
    (messageId: string) => {
      if (!connection || !ready) return
      const confirmed = window.confirm(t('confirmHideMessage'))
      if (confirmed) {
        connection.emit('hideMessage', { messageId })
      }
    },
    [connection, ready],
  )

  const sendMessage = useCallback(
    (content: string, type: MessageTypes) => {
      if (!connection || !ready) return
      connection?.emit('message', {
        content,
        thumbnail64: '',
        type,
        embeddingId: appState.embeddingOrgId,
      })
    },
    [connection, ready],
  )

  const externalUserUpdate = useCallback(
    (assignedName: string) => {
      if (!connection || !ready) return
      setExternalUserUpdateStatus('ASSIGN_NAME_IN_PROGRESS')
      connection?.emit('externalUserUpdateRequest', {
        assignedName,
      })
    },
    [connection, ready],
  )

  function incrementScrollBottomCounter(): void {
    setScrollToBottom((scrollToBottom) => ++scrollToBottom)
  }

  function setDisableJoinState(data: SessionState): void {
    setDisableJoin(data.disableJoin)
  }

  useEffect(() => {
    if (typeof window === 'undefined' || !connection || !ready) return
    const socketHandlers = SocketHandlers(
      incrementScrollBottomCounter,
      setHiddenMessageIds,
      setMessages,
      setPrompts,
      setMembers,
      setExternalUserUpdateStatus,
      setShowTypingState,
    )
    connection.emit('bootstrapSessionState')
    connection.emit('plsBootstrapChat', {
      embeddingId: appState.embeddingOrgId,
    })
    connection
      .on('chatBootstrap', socketHandlers.onInit)
      .on('chatPrompts', socketHandlers.onPrompts)
      .on('messageHidden', socketHandlers.onHideMessage)
      .on('newMessage', socketHandlers.onNewMessage)
      .on('roomMembersUpdate', socketHandlers.onRoomMembersUpdate)
      .on('sessionStateBootstrap', setDisableJoinState)
      .on('sessionStateUpdate', setDisableJoinState)
      .on('userBanned', socketHandlers.onUserBanned)
      .on('userJoined', socketHandlers.onUserJoined)
      .on('userLeft', socketHandlers.onUserLeft)
      .on('userUnBanned', socketHandlers.onUserUnBanned)
      .on('externalUserUpdateResponse', socketHandlers.onExternalUserUpdate)
      .on('showBotTyping', socketHandlers.onShowBotTyping)
      .on('hideBotTyping', socketHandlers.onHideBotTyping)

    return () => {
      // Important: unsub from all listeners here
      connection
        .off('chatBootstrap', socketHandlers.onInit)
        .off('chatPrompts', socketHandlers.onPrompts)
        .off('newMessage', socketHandlers.onNewMessage)
        .off('onMessageHidden', socketHandlers.onHideMessage)
        .off('onUserBanned', socketHandlers.onUserBanned)
        .off('roomMembersUpdate', socketHandlers.onRoomMembersUpdate)
        .off('sessionStateBootstrap', setDisableJoinState)
        .off('sessionStateUpdate', setDisableJoinState)
        .off('userJoined', socketHandlers.onUserJoined)
        .off('userLeft', socketHandlers.onUserLeft)
        .off('userUnBanned', socketHandlers.onUserUnBanned)
        .off('externalUserUpdateResponse', socketHandlers.onExternalUserUpdate)
        .off('showBotTyping', socketHandlers.onShowBotTyping)
        .off('hideBotTyping', socketHandlers.onHideBotTyping)
    }
  }, [connection, ready])

  /**
   * For seamless migration, we want something deterministic for the roomId. For
   * old/existing sessions, we've been using timekitSlotId (sometimes
   * incorrectly refered to as slot id here 😞), so we want to continue using
   * timekitSlotId for sessions that have timekitSlotId so that existing rooms
   * will continue to work.
   *
   * New sessions do not have timekitSlotId. So for those ones, we want to use
   * the sessionId (which is the same as slotId, but not timekitSlotId).
   */
  const roomId = lobbySession?.deprecatedTimekitSlotId || lobbySession?.id

  const classId = lobbySession?.class?.id

  const classTitle = lobbySession?.class?.title

  // console.log('Chat, roomId=', roomId, lobbySession)

  return (
    <ChatContext.Provider
      value={{
        disableJoin,
        hasStaticMessages,
        hiddenMessageIds,
        members,
        messages,
        prompts,
        scrollToBottom,
        externalUserUpdateStatus,
        banUser,
        hideMessage,
        incrementScrollBottomCounter,
        sendMessage,
        setHasStaticMessages,
        setMessages,
        unBanUser,
        externalUserUpdate,
        showTypingMessage,
      }}
    >
      <AppContext.Consumer>
        {({ dispatch }) => (
          <ConnectionHandler dispatch={dispatch} roomId={roomId} classId={classId} classTitle={classTitle}>
            {children}
          </ConnectionHandler>
        )}
      </AppContext.Consumer>
    </ChatContext.Provider>
  )
}

export function useChat(): ChatContextType {
  return useContext(ChatContext)
}
