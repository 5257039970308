import Loader from '../widgets/Loader'
import * as styles from './styles'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

interface Props {
  children?: string | JSX.Element
  circle?: boolean
  disabled?: boolean
  flex?: boolean
  follow?: boolean
  fullWidth?: boolean
  grey?: boolean
  darkGrey?: boolean
  href?: string
  icon?: string
  link?: boolean
  loading?: boolean
  outlined?: boolean
  primary?: boolean
  rounded?: boolean
  secondary?: boolean
  secondaryAlt?: boolean
  small?: boolean
  standardSmall?: boolean
  tiny?: boolean
  success?: boolean
  tertiary?: boolean
  tertiarySolid?: boolean
  secondaryBorder?: boolean
  secondaryBand?: boolean
  videoModalClose?: boolean
  dialog?: boolean
  textOnly?: boolean
  white?: boolean
  whiteActive?: boolean // TODO. button states.
  onClick?: (event?) => void
}

export default function Button(props: Props): JSX.Element {
  const {
    children,
    circle,
    disabled,
    flex,
    follow,
    fullWidth,
    grey,
    darkGrey,
    href,
    icon,
    link,
    primary,
    rounded,
    secondary,
    secondaryAlt,
    small,
    standardSmall,
    tiny,
    success,
    tertiary,
    tertiarySolid,
    secondaryBorder,
    secondaryBand,
    videoModalClose,
    dialog,
    textOnly,
    white,
    whiteActive,
    onClick,
  } = props

  const buttonClasses = cx({
    button_wrapper: true,
    circle,
    disabled,
    flex,
    follow,
    fullWidth,
    grey,
    darkGrey,
    link,
    primary,
    rounded,
    secondary,
    secondaryAlt,
    small,
    standardSmall,
    tiny,
    success,
    tertiary,
    tertiarySolid,
    secondaryBorder,
    secondaryBand,
    videoModalClose,
    dialog,
    textOnly,
    white,
    whiteActive,
  })

  const handleClick = (e): void => {
    if (!disabled && !href) {
      onClick && onClick(e)
    }
    if (!disabled && href) {
      window.open(href, '_self')
    }
  }

  return (
    <span onClick={handleClick} className={buttonClasses}>
      {!props.loading && (
        <>
          {icon}
          {children}
        </>
      )}
      {props.loading && (
        <>
          <span className={styles.hidden_text}>
            {icon}
            {children}
          </span>
          <span className={styles.loader_wrapper}>
            <Loader loading={true} />
          </span>
        </>
      )}
    </span>
  )
}
