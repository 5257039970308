import { css } from 'linaria';
import { ReactNode } from 'react';
interface DialogProps {
  customClass?: string;
  children?: ReactNode;
  heading?: String;
  onClose?: any;
  fullScreen?: boolean;
}
export default function Dialog({
  customClass,
  children,
  heading,
  onClose,
  fullScreen
}: DialogProps): JSX.Element {
  return <div className={modal_container}>
      <div className={`${fullScreen ? modal_content_fullscreen : modal_content} ${customClass ? customClass : ''}`} sx={{
      variant: 'styles.dialog.container'
    }}>
        <div className={modal_close_container}>
          {onClose ? <button onClick={onClose} className={modal_close_button}>
              <img src="/icons/close.svg" />
            </button> : null}
        </div>
        {heading && <h2>{heading}</h2>}
        {children}
      </div>
    </div>;
}
const modal_container = "m1hwd0r1";
const modal_content = "mevzv4j";
const modal_content_fullscreen = "moqzm84";
const modal_close_container = "m1nkea9d";
const modal_close_button = "m1f4y37t";

require("../../../.linaria-cache/components/widgets/Dialog/index.linaria.module.css");