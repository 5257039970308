import { css, Global, SerializedStyles } from '@emotion/react';
interface Props {
  embeddingOrgId?: string;
} // TODO: This component can be enhanced in future to replace global.css completely

export function GlobalStyles({
  embeddingOrgId
}: Props): JSX.Element {
  let fontImports: SerializedStyles;

  switch (embeddingOrgId) {
    case 'aol':
      fontImports = css`
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
        html,
        body {
          font-family: 'Open Sans', sans-serif;
        }
      `;
      break;

    default:
      // GSU fonts.
      fontImports = css`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
        html,
        body {
          font-family: 'Inter', sans-serif;
        }
      `;
      break;
  }

  return <Global styles={fontImports} />;
}