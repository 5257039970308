import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import HtmlVideoPlayer from '~/components/v2/html-video-player'
import Dialog from '~/components/widgets/Dialog'
import { useDialog, useVideoPlayer } from '~/contexts'
import { VideoAsset } from '~/shared/api-generated-types'
import * as styles from './styles'

interface Props {
  videoAsset: VideoAsset
}

export default function HelpDialog({ videoAsset }: Props): JSX.Element {
  const { t } = useTranslation('lobby')
  const { toggleHelpDialog } = useDialog()
  const { videoPlayer } = useVideoPlayer()
  useEffect(() => {
    if (videoPlayer) videoPlayer.pause()
  }, [])
  return (
    <Dialog customClass={styles.customClass} onClose={toggleHelpDialog}>
      <div style={{ position: 'relative' }}>
        <HtmlVideoPlayer
          url={videoAsset.hls}
          options={{
            fluid: true,
            autoplay: true,
            responsive: true,
            controls: true,
            html5: {
              vhs: { withCredentials: false },
            },
            controlBar: {
              playToggle: true,
              volumePanel: true,
              seekToLive: false,
              progressControl: true,
              durationDisplay: false,
              pictureInPictureToggle: false,
            },
          }}
        />
      </div>
      <div className={styles.container}>
        <h1 className={styles.heading}>{t('howGetSetUpWorks')}</h1>
        <h3 className={styles.subheading}>{t('howGetSetUpWorksDescription')}</h3>
        <div className={styles.button} onClick={toggleHelpDialog}>
          {t('close')}
        </div>
      </div>
    </Dialog>
  )
}
