import React, { Dispatch } from 'react'

export type EmbeddedListingPage = 'fitness' | 'learn'

export type State = {
  featureTogglesReady: boolean
  isJoinDisabled: boolean
  recordingID: string
  sessionStateReady: boolean
  /**
   * The id of the organisation that is embedding this content in their site.
   */
  embeddingOrgId?: string
  /**
   * The id of the device that is passed in from an embedded partner.
   */
  embeddedDeviceId?: string
  /**
   * The param used to identify the listing page from which the current session has been launched, this is used to writting conditional logic based on the page type i.e. Learn or Fitness currently.
   */
  embeddedListingPage?: EmbeddedListingPage
  /**
   * Flag that indicates whether mandatory authentication popup is disabled for few use cases like lobby embedded inside mobile app WebView.
   */
  disableAuthPrompt: boolean
  /**
   * Flag that indicates whether an embedded customer has disabled chat in their iframe.
   */
  disableChat: boolean
  /**
   * Flag that indicates whether an embedded customer has disabled the help link in their iframe.
   */
  disableHelp: boolean
  isInstantPlayback: boolean
  embeddedLogoUrl?: string
  embeddedAnalyticsInfo?: Record<string, string>
  /**
   * Indicates weather this lobby was opened by following a link form a zendesk chat ticket.
   * Used to determine if we should pay attention to GSU cookies or not.
   */
  useGsuAuth: boolean
  /**
   * Flag that indicates whether an embedded customer has provided their real name for chat or not.
   */
  isExternalUserAssignedName?: boolean
  /**
   * Flag for indicating whether the feedback has been already submitted or not
   */
  isFeedbackSubmitted: boolean
  /**
   * Controls the display of overlay on the Sidebar
   */
  sideBarOverlayContent?: 'CLASS_NOTES' | 'FEEDBACK'
}

export type Action =
  | { type: 'SET_JOIN_DISABLED'; payload: boolean }
  | { type: 'SET_SESSION_STATE_READY'; payload: boolean }
  | { type: 'SET_INSTANT_PLAYBACK'; payload: boolean }
  | { type: 'SET_EXTERNAL_USER_ASSIGNED_NAME'; payload: boolean }
  | { type: 'SET_SHOW_SIDEBAR_OVERLAY'; payload: { sideBarOverlayContent: State['sideBarOverlayContent'] } }
  | { type: 'SET_FEEDBACK_SUBMITTED'; payload: boolean }
  | { type: 'SET_EMBEDDED_LISTING_PAGE'; payload: EmbeddedListingPage }

export type SessionState = {
  sessionId: string
  disableJoin: boolean
}

export const initialState: State = {
  featureTogglesReady: false,
  isJoinDisabled: false,
  recordingID: '',
  sessionStateReady: false,
  embeddingOrgId: undefined,
  embeddedDeviceId: undefined,
  embeddedListingPage: undefined,
  disableAuthPrompt: false,
  disableChat: false,
  isInstantPlayback: false,
  embeddedLogoUrl: undefined,
  disableHelp: false,
  embeddedAnalyticsInfo: undefined,
  useGsuAuth: false,
  isExternalUserAssignedName: undefined,
  isFeedbackSubmitted: false,
  sideBarOverlayContent: undefined,
}

export const AppContext = React.createContext<{ state: State; dispatch: Dispatch<Action> }>(null)

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_JOIN_DISABLED':
      return {
        ...state,
        isJoinDisabled: action.payload,
      }
    case 'SET_SESSION_STATE_READY':
      return {
        ...state,
        sessionStateReady: action.payload,
      }
    case 'SET_INSTANT_PLAYBACK':
      return {
        ...state,
        isInstantPlayback: action.payload,
      }
    case 'SET_EXTERNAL_USER_ASSIGNED_NAME':
      return {
        ...state,
        isExternalUserAssignedName: action.payload,
      }
    case 'SET_FEEDBACK_SUBMITTED':
      return {
        ...state,
        isFeedbackSubmitted: action.payload,
      }
    case 'SET_EMBEDDED_LISTING_PAGE':
      return {
        ...state,
        embeddedListingPage: action.payload,
      }

    case 'SET_SHOW_SIDEBAR_OVERLAY':
      return {
        ...state,
        sideBarOverlayContent: action.payload.sideBarOverlayContent,
      }

    default:
      return state
  }
}

export const useAppContext = (): { state: State; dispatch: React.Dispatch<Action> } => React.useContext(AppContext)
