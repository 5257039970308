import { darken } from '@theme-ui/color'
import type { Theme } from '@theme-ui/core'

const buttonBorderRadius = '54px'

const defaultTheme: Theme = {
  colors: {
    primary: '#181b56',
    secondary: '#ff4600',
    tertiary: '#e2cda8',
    black80: '#2d2d32',
    blue: '#2c318a',
    darkgrey: '#4a4a68',
    grey: '#757582',
    grey10: '#f7f7fa',
    orange: '#db3c00',
    primary20: '#e0e9ff',
    primary70: '#b2c8ff',
    primary80: '#2c318a',
    secondary20: '#feddd1',
    secondary70: '#ff7845',
    tertiary20: '#f9f5ef',
    white: 'white',
  },
  buttons: {
    primary: {
      borderRadius: buttonBorderRadius,
      backgroundColor: 'primary80',
      ':disabled': {
        backgroundColor: 'primary20',
      },
      ':hover': {
        backgroundColor: darken('primary80', 0.1),
      },
    },
    primaryOutline: {
      borderRadius: buttonBorderRadius,
      backgroundColor: 'transparent',
      color: 'primary',
      border: '2px solid',
      borderColor: 'primary',
    },
    secondary: {
      borderRadius: buttonBorderRadius,
      backgroundColor: 'secondary',
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: darken('secondary', 0.1),
      },
    },
    tertiary: {
      color: 'white',
      borderRadius: buttonBorderRadius,
      borderStyle: 'solid',
      borderColor: 'white',
      borderWidth: '1px',
      fontWeight: 700,
      fontSize: '16px',
      transition: `background 200ms ease-out, color 200ms ease-out`,
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: darken('primary80', 0.1),
      },
    },
    greyOutline: {
      borderRadius: buttonBorderRadius,
      backgroundColor: 'transparent',
      color: 'grey',
      border: '1px solid grey',
    },
    clearMuted: {
      borderRadius: buttonBorderRadius,
      backgroundColor: 'transparent',
      color: 'primary',
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: darken('primary', 0.1),
      },
    },
    slideToggle: {
      backgroundColor: 'primary80',
      borderRadius: '0%',
      ':hover': {
        backgroundColor: darken('primary80', 0.1),
      },
    },
    chatInput: {
      close: {
        color: 'primary',
        background: 'primary20',
      },
      smiley: {
        color: 'primary',
        background: 'primary20',
      },
      send: {
        color: 'white',
        background: 'orange',
      },
    },
  },
  text: {
    primary: {
      color: 'primary',
    },
  },
  styles: {
    chat: {
      message: {
        header: {
          background: 'primary',
        },
        self: {
          background: 'blue',
        },
        other: {
          background: 'grey10',
        },
        welcome: {
          background: 'tertiary20',
        },
      },
    },
    dialog: { container: { borderRadius: '1rem' } },
    message: {
      container: {
        background: 'tertiary20',
        borderRadius: '1rem',
      },
      title: {
        color: 'black80',
      },
      status: {
        finished: {
          color: 'grey',
        },
        private: {
          color: 'secondary',
        },
        startingSoon: {
          color: 'secondary',
        },
      },
    },
    sidebar: {
      header: {
        root: {
          background: 'primary',
        },
        counter: {
          background: 'primary80',
        },
        settings: {
          color: 'white',
        },
        participants: {
          'img:first-child': {
            color: 'primary',
          },
          live: {
            outlineColor: 'primary',
          },
        },
      },
      tabs: {
        container: {
          background: 'primary',
        },
        item: {
          "&[data-active='true']": {
            backgroundColor: 'primary',
          },
          "&[data-active='false']": {
            backgroundColor: 'white',
            color: 'white',
          },
        },
      },
      toggle: {
        borderRadius: '32px 0 0 32px',
      },
    },
    ratings: {
      active: {
        backgroundColor: 'primary',
        color: 'white',
      },
      passive: {
        backgroundColor: 'white',
        border: '3px solid',
        borderColor: 'primary',
        color: 'primary',
      },
    },
  },
}

export default defaultTheme
