import { useEffect, useRef, useState } from 'react'
import { getConfig } from '~/config/config'
import { useAppContext } from '~/store/state'
const { publicRuntimeConfig } = getConfig()

interface TokenState {
  encodedToken?: string
  decodedToken?: any
  externalUserId?: string
}

const minTokenRecheckTimeInMilliSeconds = 30 * 1000

export function useExternalToken(): TokenState {
  const { state } = useAppContext()
  const tokenRecheckTimerRef = useRef(null)
  const tokenHardExpiryTimerRef = useRef(null)
  const [tokenState, setTokenState] = useState<TokenState>({})
  const embeddingOrgId = state.embeddingOrgId

  const fetchToken = (token: string): Promise<any> => {
    if (!token) throw new Error('Error fetching decoded token')
    return fetch(`${publicRuntimeConfig.orchestrationService}/user/external-user-info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Token not validated by API.')
        }
        return res
      })
      .then((res) => res.json())
  }

  const receiveToken = (event: MessageEvent<any>): void => {
    //TODO: also check the message origin
    if (event.data?.gsuTokenFromHost) {
      const encodedToken = `${embeddingOrgId}:${event.data?.gsuTokenFromHost}`
      fetchToken(encodedToken)
        .then((apiResponse) => {
          // Set a timeout here based on the tokens exp value and trigger a "get new token" when it expires.
          if (typeof apiResponse?.token?.exp === 'number') {
            const tokenExpiryInMs = apiResponse.token.exp * 1000 // Token expiry values are in seconds since epoch. We convert this to ms to match JS date handling.
            console.log('Token expires: ' + tokenExpiryInMs)

            // Figure out when to refresh the token.
            // We will take the expiry time and check again one minute before the current token expires.
            // The minimum refresh time we support is one minute, and shorter than that and we will default back to one minute.
            let { tokenRecheckTime, tokenHardExpiryTime } = getTokenExpiryDetails(tokenExpiryInMs)

            clearTimeout(tokenRecheckTimerRef.current)
            clearTimeout(tokenHardExpiryTimerRef.current)
            tokenRecheckTimerRef.current = setTimeout(() => {
              // Token IS ABOUT TO expire, try getting a new token.
              requestToken()
            }, tokenRecheckTime)
            tokenHardExpiryTimerRef.current = setTimeout(() => {
              // Token HAS expired, dump auth and log the user out.
              setTokenState({ encodedToken: null, externalUserId: null, decodedToken: null })
            }, tokenHardExpiryTime)

            const externalUserId = apiResponse.token?.sub ? `${embeddingOrgId}:${apiResponse.token?.sub}` : undefined
            // TODO: get the Tivity Client Id from the token and set it in the token state.
            // Just add a decoded token: any to the token state.
            setTokenState({ encodedToken, externalUserId, decodedToken: apiResponse.token })
          } else {
            throw new Error('Token not valid')
          }
        })
        .catch((error) => {
          // We couldn't get a decoded token, just give up.
          console.error(error)
          console.log('Clearing auth state because of bad token')
          clearTimeout(tokenRecheckTimerRef.current)
          setTokenState({ encodedToken: null, externalUserId: null, decodedToken: null })
        })
    }
  }

  const requestToken = (): void => {
    const messageFromIframe = { gsuTokenRequest: true }
    //TODO: use a better message destination
    window.parent.postMessage(messageFromIframe, '*')
  }

  useEffect(() => {
    // TODO: some hosting sites might not have authed users, they wouldn't use chat or tokens.
    // Don't ask for tokens from hosting sites that can't provide them.
    // This shouldn't have any problems if some sites don't respond,
    // but it's nice to not do things if we don't need to.
    if (embeddingOrgId) {
      // Add an event listener for receiving tokens from the page embedding this
      // and then send the request for a token to the parent page.
      window.addEventListener('message', receiveToken)
      requestToken()
      return () => {
        window.removeEventListener('message', receiveToken)
        clearTimeout(tokenRecheckTimerRef.current)
      }
    }
  }, [])

  return {
    externalUserId: tokenState.externalUserId,
    encodedToken: tokenState.encodedToken,
    decodedToken: tokenState.decodedToken,
  }
}

/**
 * `This is only exported for testing. Do not use this.`
 * This will convert the token expiry datetime into timespans to check the token again.
 * It has a floor on the minimum recheck and expiry time to stop misconfigured hosting pages from DOSing us with one second tokens.
 * @param tokenExpiry The token expiry in milliseconds from epoch.
 * @returns A timespan to check the token and a timespan to expire the token.
 */
export function getTokenExpiryDetails(tokenExpiry: number): { tokenRecheckTime: number; tokenHardExpiryTime: number } {
  const now = new Date().getTime()
  let tokenHardExpiryTime = tokenExpiry - now

  if (tokenHardExpiryTime < minTokenRecheckTimeInMilliSeconds * 2) {
    tokenHardExpiryTime = minTokenRecheckTimeInMilliSeconds * 2
  }
  let tokenRecheckTime = tokenHardExpiryTime - minTokenRecheckTimeInMilliSeconds // Hard expiry time minus 30s.
  if (tokenRecheckTime < 0) {
    tokenRecheckTime = minTokenRecheckTimeInMilliSeconds
  }
  return { tokenRecheckTime, tokenHardExpiryTime }
}
