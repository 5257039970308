import { css } from 'linaria';
export const customClass = "c1ajm33o";
export const container = "cjall7x";
export const buttonContainer = "b1p0jcn2";
export const imageContainer = "i1y4hc1s";
export const heading = "hc2e64m";
export const subheading = "sxooyms";
export const separator = "svibfiu";
export const orText = "o1w2vq6j";
export const button = "b1rmabv9";

require("../../../../.linaria-cache/components/v2/dialogs/Auth/styles.linaria.module.css");