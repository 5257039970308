import { createContext, Dispatch, ReactNode, useContext, useEffect, useReducer } from 'react'
import { isChatModerationAfterHours, unixTime } from '~/libs/dayjs'
import { LobbySession } from '~/shared/api-generated-types'

// Interval for running background timer
const interval = 1000
const startingSoonBeforeSeconds = 900 // 15 minutes

// Class Actions and States
export enum ClassActions {
  NOT_STARTED = 'NOT_STARTED',
  STARTING_SOON = 'STARTING_SOON',
  STARTED = 'STARTED',
  PLAYBACK_ENDED = 'PLAYBACK_ENDED',
  ENDED = 'ENDED',
}

interface ClassStates {
  classNotStarted: boolean
  classStartingSoon: boolean
  classStarted: boolean
  classEnded: boolean
  playbackEnded: boolean
}

const classStateDefaultValues: ClassStates = {
  classNotStarted: false,
  classStartingSoon: false,
  classStarted: false,
  classEnded: false,
  playbackEnded: false,
}

// Timer Actions and States
export enum TimerActions {
  INCREAMENT_EVERY_30_SECONDS,
  INCREAMENT_MINUTES,
  INCREAMENT_SECONDS,
  SET_STATE_AFTER_HOURS,
}

interface TimerStates {
  isChatModerationAfterHours: boolean
  every30Seconds: number
  minutes: number
  seconds: number
}

const timerStateDefaultValues: TimerStates = {
  isChatModerationAfterHours: isChatModerationAfterHours(),
  every30Seconds: 0,
  minutes: 0,
  seconds: 0,
}

interface ContextType extends ClassStates, TimerStates {
  setClassState: Dispatch<ClassActions>
  setTimerState: Dispatch<{ action: TimerActions; nextValue?: boolean }>
}

const TimerContext = createContext<ContextType>({
  ...classStateDefaultValues,
  ...timerStateDefaultValues,
  setClassState: () => {},
  setTimerState: () => {},
})

type Props = {
  children: ReactNode
  lobbySession: LobbySession | undefined
}

// Reducer function for background timer
function timerReducer(state: TimerStates, payload: { action: TimerActions; nextValue?: boolean }): TimerStates {
  switch (payload.action) {
    case TimerActions.INCREAMENT_EVERY_30_SECONDS:
      return { ...state, every30Seconds: ++state.seconds }

    case TimerActions.INCREAMENT_MINUTES:
      return { ...state, minutes: ++state.minutes }

    case TimerActions.INCREAMENT_SECONDS:
      return { ...state, seconds: ++state.seconds }

    case TimerActions.SET_STATE_AFTER_HOURS:
      return { ...state, isChatModerationAfterHours: payload.nextValue }

    default:
      return state
  }
}

// Reducer function for class timer states
function classStatesReducer(state: ClassStates, action: ClassActions): ClassStates {
  switch (action) {
    case ClassActions.NOT_STARTED:
      return { ...state, classNotStarted: true }

    case ClassActions.STARTING_SOON:
      return { ...state, classNotStarted: true, classStartingSoon: true }

    case ClassActions.STARTED:
      return { ...state, classStarted: true }

    case ClassActions.PLAYBACK_ENDED:
      return { ...state, playbackEnded: true }

    case ClassActions.ENDED:
      return { ...state, classEnded: true }

    default:
      return state
  }
}

export function TimerProvider({ children, lobbySession }: Props): JSX.Element {
  const [classState, setClassState] = useReducer(classStatesReducer, classStateDefaultValues)
  const [timerState, setTimerState] = useReducer(timerReducer, timerStateDefaultValues)

  useEffect(() => {
    const classStartTime = unixTime(lobbySession?.startTimeUTC)
    const classEndTime = unixTime(lobbySession?.endTimeUTC)
    setTimeout(() => {
      const currentTime = unixTime()
      if (currentTime < classStartTime) {
        setClassState(ClassActions.NOT_STARTED)
        if (currentTime > classStartTime - startingSoonBeforeSeconds) {
          setClassState(ClassActions.STARTING_SOON)
        }
      } else if (currentTime >= classStartTime && currentTime <= classEndTime) {
        setClassState(ClassActions.STARTED)
      } else if (currentTime > classEndTime) {
        setClassState(ClassActions.ENDED)
      }

      // Timer next second
      setTimerState({ action: TimerActions.INCREAMENT_SECONDS })

      // Timer next 30 seconds
      if (timerState.seconds % 30 === 0) setTimerState({ action: TimerActions.INCREAMENT_EVERY_30_SECONDS })

      // Timer next minute
      if (timerState.seconds % 60 === 0) {
        setTimerState({ action: TimerActions.INCREAMENT_MINUTES })
        // Set after hours for chat moderation window
        setTimerState({ action: TimerActions.SET_STATE_AFTER_HOURS, nextValue: isChatModerationAfterHours() })
      }
    }, interval)
  }, [timerState.seconds])

  return (
    <TimerContext.Provider value={{ ...classState, ...timerState, setClassState, setTimerState }}>
      {children}
    </TimerContext.Provider>
  )
}

export function useTimer(): ContextType {
  return useContext(TimerContext)
}
