import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useApp } from '~/contexts/app';
import Loader from '~/components/widgets/Loader';
import { getConfig } from '~/config/config';
import { routerToFacebookLocale } from '~/libs/facebook';
import { css } from 'linaria';
const {
  publicRuntimeConfig: config
} = getConfig();
declare const window: Window & typeof globalThis & {
  FB: any;
  fbAsyncInit: any;
};
interface AuthResponse {
  authResponse: {
    accessToken: string;
    data_access_expiration_time: number;
    expiresIn: number;
    graphDomain: string;
    signedRequest: string;
    userID: string;
  };
}
export default function AuthFacebook(): JSX.Element {
  const {
    fbSdkLoaded,
    setFbSdkLoaded
  } = useApp();
  const router = useRouter();
  const fbLocale = routerToFacebookLocale(router.locale);

  function onStatusChange(response: AuthResponse): void {
    if (response && response.authResponse && response.authResponse.accessToken) window.open(`${config.getsetup_io_url}/auth/callback?#access_token=${response.authResponse.accessToken}&returnTo=${encodeURIComponent(window.location.href)}`, '_self');
  } // Load Facebook SDK for Facebook Users


  useEffect(() => {
    if (fbSdkLoaded) {
      // OnInit
      try {
        if (!window.fbAsyncInit) {
          window.fbAsyncInit = function () {
            window.FB.init({
              appId: config.facebook.appID,
              cookie: true,
              xfbml: true,
              version: 'v12.0'
            });
            window.FB.Event.subscribe('auth.statusChange', onStatusChange);
          };
        } else {
          window.FB.init({
            appId: config.facebook.appID,
            cookie: true,
            xfbml: true,
            version: 'v12.0'
          });
          window.FB.Event.subscribe('auth.statusChange', onStatusChange);
        }
      } catch (error) {}
    } else {
      // Load SDK
      ;

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
          return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${fbLocale}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');

      setFbSdkLoaded();
    } // OnUnMount


    return () => {
      try {
        window.FB.Event.unsubscribe('auth.statusChange');
      } catch (error) {}
    };
  }, [fbSdkLoaded]);
  return fbSdkLoaded ? <div className={container}>
      <div className="fb-login-button" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="false" data-use-continue-as="true"></div>
    </div> : <Loader loading={true} />;
}
const container = "c1fhi0na";

require("../../../.linaria-cache/components/v2/facebook-login/index.linaria.module.css");