import { createContext, ReactNode, useContext, useReducer } from 'react'

enum Actions {
  SET_FB_SDK_LOADED,
  SET_STREAM_STATUS_LIVE,
  SET_STREAM_STATUS_PRIVATE,
  SET_STREAM_STATUS_RECORDED,
  SET_STREAM_STATUS_WAITING,
  SET_STREAM_STATUS_RECORDED_REPLAY,
  SET_STREAM_STATUS_VOD,
}

export enum StreamStatus {
  LIVE = 'LIVE',
  PRIVATE = 'PRIVATE',
  RECORDED = 'RECORDED',
  WAITING = 'WAITING',
  RECORDED_REPLAY = 'RECORDED_REPLAY',
  VOD = 'VOD',
}

type AppContext = {
  fbSdkLoaded: boolean
  streamStatus: StreamStatus
  setFbSdkLoaded?: () => void
  setStreamStatusLive?: () => void
  setStreamStatusPrivate?: () => void
  setStreamStatusRecorded?: () => void
  setStreamStatusWaiting?: () => void
  setStreamStatusRecordedReplay?: () => void
  setStreamStatusVOD?: () => void
}

const appContextDefaultValues: AppContext = {
  fbSdkLoaded: false,
  streamStatus: StreamStatus.WAITING,
}

const AppContext = createContext<AppContext>(appContextDefaultValues)

type Props = {
  children: ReactNode
}

// Reducer function for AppState
function appStatesReducer(appState: AppContext, action: Actions): AppContext {
  switch (action) {
    case Actions.SET_FB_SDK_LOADED:
      return { ...appState, fbSdkLoaded: true }

    case Actions.SET_STREAM_STATUS_LIVE:
      return { ...appState, streamStatus: StreamStatus.LIVE }

    case Actions.SET_STREAM_STATUS_PRIVATE:
      return { ...appState, streamStatus: StreamStatus.PRIVATE }

    case Actions.SET_STREAM_STATUS_RECORDED:
      return { ...appState, streamStatus: StreamStatus.RECORDED }

    case Actions.SET_STREAM_STATUS_WAITING:
      return { ...appState, streamStatus: StreamStatus.WAITING }

    case Actions.SET_STREAM_STATUS_RECORDED_REPLAY:
      return { ...appState, streamStatus: StreamStatus.RECORDED_REPLAY }

    case Actions.SET_STREAM_STATUS_VOD:
      return { ...appState, streamStatus: StreamStatus.VOD }

    default:
      return appState
  }
}

export function AppProvider({ children }: Props): JSX.Element {
  const [appState, setAppState] = useReducer(appStatesReducer, appContextDefaultValues)

  function setFbSdkLoaded(): void {
    setAppState(Actions.SET_FB_SDK_LOADED)
  }

  function setStreamStatusLive(): void {
    setAppState(Actions.SET_STREAM_STATUS_LIVE)
  }

  function setStreamStatusPrivate(): void {
    setAppState(Actions.SET_STREAM_STATUS_PRIVATE)
  }

  function setStreamStatusRecorded(): void {
    setAppState(Actions.SET_STREAM_STATUS_RECORDED)
  }

  function setStreamStatusWaiting(): void {
    setAppState(Actions.SET_STREAM_STATUS_WAITING)
  }

  function setStreamStatusRecordedReplay(): void {
    setAppState(Actions.SET_STREAM_STATUS_RECORDED_REPLAY)
  }

  function setStreamStatusVOD(): void {
    setAppState(Actions.SET_STREAM_STATUS_VOD)
  }

  return (
    <AppContext.Provider
      value={{
        ...appState,
        setFbSdkLoaded,
        setStreamStatusLive,
        setStreamStatusPrivate,
        setStreamStatusRecorded,
        setStreamStatusWaiting,
        setStreamStatusRecordedReplay,
        setStreamStatusVOD,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useApp(): AppContext {
  return useContext(AppContext)
}
