import { WebPlatform } from '@getsetup-io/event-producer'
import { getConfig } from '~/config/config'

const { publicRuntimeConfig } = getConfig()
const webPlatform = WebPlatform({ cookieDomain: publicRuntimeConfig.events.cookieDomain })

export async function getDeviceId(): Promise<string> {
  const deviceId = await webPlatform.getDeviceId()
  return deviceId
}
