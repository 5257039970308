import { css } from 'linaria';
export const disabled = "d1c4bi8u";
export const root = "r1yb8win";
export const xs = "x3zct7m";
export const sm = "su1qmiw";
export const md = "mivcioy";
export const lg = "ljmbwlf";
export const xl = "x1uoyzyh";
export const primary = "p3wqa4z";
export const secondary = "szr0z7a";
export const tertiary = "t1p1zgnu";
export const secondaryMuted = "sasl2es";
export const white = "wo87v4g";
export const glass = "giq7dqw";
export const smoke = "s1bpvkpi";
export const clear = "cwi8f7r";
export const clearMuted = "c13pjrbw";
export const accent = "a11xqwev";
export const primaryOutline = "p1v7fe32";
export const secondaryOutline = "s1m23p39";
export const whiteOutline = "w7nurhf";
export const greyOutline = "g1csokwy";
export const stretch = "same8i4";
export const iconOnly = "i1uh0jw5";
export const sharpCorners = "s1lg65h2";
export const textOnly = "t16lb5r5";
export const hideTextOnSmallScreens = "h1hpv2ss";
export const loading = "lq9a53t";

require("../../../.linaria-cache/components/v2/button/style.linaria.module.css");