import { createContext, ReactNode, useContext, useReducer } from 'react'
import { VideoJsPlayer } from '~/libs/videojs'

enum Actions {
  HIDE_VIDEO_PLAYER_CONTROLS,
  SET_VIDEO_PLAYER_MUTE,
  SET_VIDEO_PLAYER_UNMUTE,
  SET_VIDEO_PLAYER,
  SHOW_VIDEO_PLAYER_CONTROLS,
  TOGGLE_VIDEO_PLAYER_FULLSCREEN,
}

export enum PlayerStates {
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  STOPPED = 'STOPPED',
}

export enum PlayerVolume {
  MUTE = 'MUTE',
  UNMUTE = 'UNMUTE',
}

interface Payload {
  videoPlayer?: VideoJsPlayer
}

interface ReducerActions {
  action: Actions
  payload?: Payload
}

type ContextType = {
  fullScreen: boolean
  playerState: PlayerStates
  playerVolume: PlayerVolume
  showControls: boolean
  videoPlayer?: VideoJsPlayer
  hideVideoPlayerControls?: () => void
  setVideoPlayer?: (videoJsPlayer: VideoJsPlayer) => void
  setVideoPlayerMute?: () => void
  setVideoPlayerUnmute?: () => void
  showVideoPlayerControls?: () => void
  toggleVideoPlayerFullscreen?: () => void
}

const contextDefaultValues: ContextType = {
  fullScreen: false,
  playerState: PlayerStates.STOPPED,
  playerVolume: PlayerVolume.UNMUTE,
  showControls: true,
}

const VideoPlayerContext = createContext<ContextType>(contextDefaultValues)

type Props = {
  children: ReactNode
}

// Reducer function for ContextType
function contextReducer(state: ContextType, { action, payload }: ReducerActions): ContextType {
  switch (action) {
    case Actions.SET_VIDEO_PLAYER_MUTE:
      return { ...state, playerVolume: PlayerVolume.MUTE }

    case Actions.SET_VIDEO_PLAYER_UNMUTE:
      return { ...state, playerVolume: PlayerVolume.UNMUTE }

    case Actions.SHOW_VIDEO_PLAYER_CONTROLS:
      return { ...state, showControls: true }

    case Actions.HIDE_VIDEO_PLAYER_CONTROLS:
      return { ...state, showControls: false }

    case Actions.TOGGLE_VIDEO_PLAYER_FULLSCREEN:
      return { ...state, fullScreen: !state.fullScreen }

    case Actions.SET_VIDEO_PLAYER:
      return { ...state, videoPlayer: payload.videoPlayer }

    default:
      return state
  }
}

export function VideoPlayerProvider({ children }: Props): JSX.Element {
  const [state, dispatch] = useReducer(contextReducer, contextDefaultValues)

  function setVideoPlayerMute(): void {
    dispatch({ action: Actions.SET_VIDEO_PLAYER_MUTE })
  }

  function setVideoPlayerUnmute(): void {
    dispatch({ action: Actions.SET_VIDEO_PLAYER_UNMUTE })
  }

  function showVideoPlayerControls(): void {
    dispatch({ action: Actions.SHOW_VIDEO_PLAYER_CONTROLS })
  }

  function hideVideoPlayerControls(): void {
    dispatch({ action: Actions.HIDE_VIDEO_PLAYER_CONTROLS })
  }

  function toggleVideoPlayerFullscreen(): void {
    dispatch({ action: Actions.TOGGLE_VIDEO_PLAYER_FULLSCREEN })
  }

  function setVideoPlayer(videoPlayer: VideoJsPlayer | undefined): void {
    dispatch({ action: Actions.SET_VIDEO_PLAYER, payload: { videoPlayer } })
  }

  return (
    <VideoPlayerContext.Provider
      value={{
        ...state,
        hideVideoPlayerControls,
        setVideoPlayer,
        setVideoPlayerMute,
        setVideoPlayerUnmute,
        showVideoPlayerControls,
        toggleVideoPlayerFullscreen,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}

export function useVideoPlayer(): ContextType {
  return useContext(VideoPlayerContext)
}
