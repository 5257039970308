import { css } from 'linaria';
export const wrapper = "w96metw";
export const container = "cxi3q1m";
export const heading = "h1dqmg74";
export const title = "t1e4an11";
export const buttonContainer = "b1k1y4m5";
export const subheading = "s1igg5nd";
export const separator = "sx11yx5";
export const orText = "o1orc5cp";

require("../../../../.linaria-cache/components/v2/dialogs/AlreadyStarted/styles.linaria.module.css");