import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from '~/libs/videojs'

interface HtmlVideoPlayerProps {
  url: string
  options: VideoJsPlayerOptions
}

export default function HtmlVideoPlayer({ url, options }: HtmlVideoPlayerProps): JSX.Element {
  const router = useRouter()
  const videoRef = useRef(null)
  useEffect(() => {
    const videoElement = videoRef.current
    //TODO: removing videojs after fixing format for static video files.
    const player = videojs(
      videoElement,
      {
        ...options,
        language: router.locale,
      },
      () => {
        console.log('html player is ready')
      },
    ) as VideoJsPlayer
    player.src(url)
  }, [url])
  return <video ref={videoRef} controls autoPlay className="video-js vjs-default-skin" playsInline={true} />
}
