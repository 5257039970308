import Cookies from 'js-cookie'
import React from 'react'

interface DecodedToken {
  /**
   * Issued At.
   */
  iat: number
  /**
   * Expires.
   */
  exp: number
  /**
   * Subject, I.E. user id.
   */
  sub: string
  /**
   * Issuer.
   */
  iss: string
  /**
   * Audience.
   */
  aud: 'getsetup'
  /**
   * Anything else that might be attached to a token. For example Tivity's clientId.
   */
  [x: string]: any
}

interface AuthState {
  encodedGsuToken: string
  encodedExternalToken: string | null
  decodedExternalToken: DecodedToken | null
  externalUserId: string
  isAuthed: boolean
}

type StateReducer = React.Reducer<AuthState, Partial<AuthState>>
type ContextValue = [React.ReducerState<StateReducer>, React.Dispatch<React.ReducerAction<StateReducer>>]

const defaultState: AuthState = {
  encodedGsuToken: null,
  encodedExternalToken: null,
  decodedExternalToken: null,
  externalUserId: null,
  isAuthed: false,
}

const stateManager = (prevState: AuthState, updatePayload: Partial<AuthState>): AuthState => {
  let isAuthed = false
  if (updatePayload.externalUserId || updatePayload.encodedGsuToken || prevState.encodedGsuToken) {
    isAuthed = true
  }
  updatePayload.isAuthed = isAuthed
  return {
    ...prevState,
    ...updatePayload,
  }
}

export const AuthContext = React.createContext<ContextValue>([
  defaultState,
  (payload) => stateManager(defaultState, payload),
])

export const AuthContextProvider: React.FunctionComponent<{
  value?: Partial<AuthState>
}> = ({ value: injectedValue, children }) => {
  const cookieState: Partial<AuthState> = {}

  let tokenFromCookies = Cookies.get('auth._token.local') || Cookies.get('auth._token.facebook')
  if (tokenFromCookies) {
    cookieState.encodedGsuToken = tokenFromCookies
    cookieState.isAuthed = true
  }
  const initialState: AuthState = {
    ...defaultState,
    ...cookieState,
    ...injectedValue,
  }

  return <AuthContext.Provider value={React.useReducer(stateManager, initialState)}>{children}</AuthContext.Provider>
}

export const useAuth = (): ContextValue => React.useContext(AuthContext)
