import { AppName, EventProducer, WebPlatform } from '@getsetup-io/event-producer'
import { AppStartEvent, ContentType } from '@getsetup-io/event-producer/dist/schema/types'
import { LoungeUIElementName } from '@getsetup-io/event-producer/dist/schema/types/ui-element-names'
import { getConfig } from '~/config/config'

const { publicRuntimeConfig: config } = getConfig()

const eventsEnv = config.environment === 'production' ? 'production' : 'development'

function isClientSide(): boolean {
  return typeof window !== 'undefined'
}

const platform = WebPlatform({
  cookieDomain: config.events.cookieDomain,
})
export const eventProducer = isClientSide() ? EventProducer(platform, eventsEnv) : undefined

// Reusing types from events-producer schema
export { AppName, ContentType as WatchContentType }
export { LoungeUIElementName }
export type { AppStartEvent }
