import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import AlreadyStarted from '~/components/v2/dialogs/AlreadyStarted'
import AuthDialog from '~/components/v2/dialogs/Auth'
import EarlyDialog from '~/components/v2/dialogs/Early'
import HelpDialog from '~/components/v2/dialogs/Help'
import SettingsDialog from '~/components/v2/dialogs/Settings'
import { CurrentUser, GetsetupInfo, LobbySession } from '~/shared/api-generated-types'

enum Actions {
  HIDE_ALREADY_STARTED_DIALOG,
  TOGGLE_ALREADY_STARTED_DIALOG,
  TOGGLE_AUTH_DIALOG,
  TOGGLE_EARLY_DIALOG,
  TOGGLE_HELP_DIALOG,
  TOGGLE_SETTINGS_DIALOG,
}

type ContextType = {
  showAlreadyStartedDialog: boolean
  showAuthDialog: boolean
  showEarlyDialog: boolean
  showHelpDialog: boolean
  showSettingsDialog: boolean
  hideAlreadyStartedDialog?: () => void
  toggleAlreadyStartedDialog?: () => void
  toggleAuthDialog?: () => void
  toggleEarlyDialog?: () => void
  toggleHelpDialog?: () => void
  toggleSettingsDialog?: () => void
}

const contextDefaultValues: ContextType = {
  showAlreadyStartedDialog: false,
  showAuthDialog: false,
  showEarlyDialog: false,
  showHelpDialog: false,
  showSettingsDialog: false,
}

const DialogContext = createContext<Partial<ContextType>>(contextDefaultValues)

type Props = {
  children: ReactNode
  currentUser: CurrentUser
  getsetupInfo: GetsetupInfo
  isAuthSuccess?: boolean
  lobbySession: LobbySession
}

// Reducer function for AppState
function dialogReducer(state: ContextType, action: Actions): ContextType {
  switch (action) {
    case Actions.HIDE_ALREADY_STARTED_DIALOG:
      return { ...state, showAlreadyStartedDialog: false }

    case Actions.TOGGLE_ALREADY_STARTED_DIALOG:
      return { ...state, showAlreadyStartedDialog: !state.showAlreadyStartedDialog }

    case Actions.TOGGLE_AUTH_DIALOG:
      return { ...state, showAuthDialog: !state.showAuthDialog }

    case Actions.TOGGLE_EARLY_DIALOG:
      return { ...state, showEarlyDialog: !state.showEarlyDialog }

    case Actions.TOGGLE_HELP_DIALOG:
      return { ...state, showHelpDialog: !state.showHelpDialog }

    case Actions.TOGGLE_SETTINGS_DIALOG:
      return { ...state, showSettingsDialog: !state.showSettingsDialog }

    default:
      return state
  }
}

export function DialogProvider({
  children,
  currentUser,
  getsetupInfo,
  isAuthSuccess,
  lobbySession,
}: Props): JSX.Element {
  const { t } = useTranslation('lobby')
  const [state, dispatch] = useReducer(dialogReducer, contextDefaultValues)

  function hideAlreadyStartedDialog(): void {
    dispatch(Actions.HIDE_ALREADY_STARTED_DIALOG)
  }

  function toggleAlreadyStartedDialog(): void {
    dispatch(Actions.TOGGLE_ALREADY_STARTED_DIALOG)
  }

  function toggleAuthDialog(): void {
    dispatch(Actions.TOGGLE_AUTH_DIALOG)
  }

  function toggleEarlyDialog(): void {
    dispatch(Actions.TOGGLE_EARLY_DIALOG)
  }

  function toggleHelpDialog(): void {
    dispatch(Actions.TOGGLE_HELP_DIALOG)
  }

  function toggleSettingsDialog(): void {
    dispatch(Actions.TOGGLE_SETTINGS_DIALOG)
  }

  // Handle auth success
  useEffect(() => {
    if (!isAuthSuccess || !currentUser.authenticatedUser?.firstName) return
    toast.success(t('loggedInAsUser', { name: currentUser.authenticatedUser.firstName }), { duration: 5000 })
  }, [isAuthSuccess])

  return (
    <DialogContext.Provider
      value={{
        hideAlreadyStartedDialog,
        toggleAlreadyStartedDialog,
        toggleAuthDialog,
        toggleEarlyDialog,
        toggleHelpDialog,
        toggleSettingsDialog,
      }}
    >
      {state.showAlreadyStartedDialog && <AlreadyStarted title={lobbySession.title} />}
      {state.showAuthDialog && <AuthDialog />}
      {state.showEarlyDialog && <EarlyDialog />}
      {state.showHelpDialog && <HelpDialog videoAsset={getsetupInfo.videoGuideWelcome} />}
      {state.showSettingsDialog && <SettingsDialog lobbySession={lobbySession} />}
      <Toaster />
      {children}
    </DialogContext.Provider>
  )
}

export function useDialog(): Partial<ContextType> {
  return useContext(DialogContext)
}
