import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/hi'
import 'dayjs/locale/zh-cn'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useRouter } from 'next/router'
import { TFunction } from 'next-i18next'
import { useMemo } from 'react'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(isBetween)

export default dayjs

// After Hours Start time
export const chatModerationTimezone = 'US/Eastern'
const chatModerationStartTime = dayjs().tz(chatModerationTimezone).hour(8).minute(0).second(0).utc()
const chatModerationEndTime = dayjs().tz(chatModerationTimezone).hour(23).minute(0).second(0).utc()

const DayjsLocale: Record<string, string> = {
  'en-US': 'en',
  'hi-IN': 'hi',
  'zh-CN': 'zh-cn',
  'es-US': 'es',
}

export function useRouterLocaleForDayjs(): string {
  const router = useRouter()
  const routerLocale = router.locale ?? 'en-US'
  const dayjsLocale = DayjsLocale[routerLocale] ?? 'en'
  return useMemo(() => dayjs.locale(dayjsLocale), [dayjsLocale])
}

export function classStartsIn(timestamp: string): string {
  return dayjs().to(dayjs(timestamp))
}

export function classDate(startTime: string): string {
  return dayjs(startTime).format('ddd DD MMM')
}

export function classTime(startTime: string, endTime: string): string {
  return `${dayjs(startTime).format('hh:mma')} - ${dayjs(endTime).format('hh:mma')}`
}

export function cardClassTime(timestamp: Date): string {
  return dayjs(timestamp).format('DD MMM hh:mma')
}

export function messageTime(timestamp: number): string {
  const unixTime = String(timestamp).length > 10 ? timestamp / 1000 : timestamp
  return dayjs.unix(unixTime).format('hh:mma')
}

export function unixTime(timestamp?: string): number {
  return timestamp ? dayjs(timestamp).unix() : dayjs().unix()
}

export function toDate(timestamp: string): Date {
  return dayjs(timestamp).toDate()
}

export function isChatModerationAfterHours(date?: dayjs.Dayjs): boolean {
  const currentDate = date ? date.tz(chatModerationTimezone) : dayjs().tz(chatModerationTimezone)
  return !currentDate.isBetween(chatModerationStartTime, chatModerationEndTime, 'second', '[]')
}

export function secondsToDuration(seconds: number, t: TFunction): string {
  if (seconds < 60) return t('durationFormatS', { seconds })
  const duration = dayjs.duration(seconds, 'seconds')
  if (seconds < 3600) return t('durationFormatM', { minutes: duration.format('m') })
  return t('durationFormatHM', { hours: duration.format('H'), minutes: duration.format('m') })
}
