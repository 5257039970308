import React from 'react'
import { Socket as IOSocket } from 'socket.io-client'

interface IO {
  connection: null | IOSocket
  error: boolean
  ready: boolean
}

type StateReducer = React.Reducer<IO, Partial<IO>>
type ContextValue = [React.ReducerState<StateReducer>, React.Dispatch<React.ReducerAction<StateReducer>>]

const defaultState: IO = {
  connection: null,
  error: false,
  ready: false,
}

const stateManager = (prevState: IO, updatePayload: Partial<IO>): IO => {
  return {
    ...prevState,
    ...updatePayload,
  }
}

export const IOContext = React.createContext<ContextValue>([
  defaultState,
  (payload) => stateManager(defaultState, payload),
])

export const IOContextProvider: React.FunctionComponent<{
  value?: Partial<IO>
}> = ({ value: injectedValue, children }) => {
  const initialState: IO = {
    ...defaultState,
    ...injectedValue,
  }

  return <IOContext.Provider value={React.useReducer(stateManager, initialState)}>{children}</IOContext.Provider>
}

export const useIO = (): ContextValue => React.useContext(IOContext)

export const getConnection = (): {
  connection: IOSocket
  error: boolean
  ready: boolean
} => {
  const [state] = useIO()
  return {
    connection: state.connection,
    error: state.error,
    ready: state.ready,
  }
}
