import { css } from 'linaria';
export const customClass = "cjtzqaq";
export const container = "c1l906dq";
export const button = "brwpokf";
export const imageContainer = "i13yixy7";
export const heading = "h1tfi4ui";
export const subheading = "s115t8l7";
export const separator = "sibgzww";
export const orText = "ob88hjv";

require("../../../../.linaria-cache/components/v2/dialogs/Help/styles.linaria.module.css");