import { ThemeProvider } from '@theme-ui/core';
import '../styles/globals.css';
import '../styles/icomoon.css';
import '../styles/video.css';
import Cookies from 'js-cookie';
import { appWithTranslation } from 'next-i18next';
import { withUrqlClient } from 'next-urql';
import { AppProps as BaseAppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useReducer } from 'react';
import { ExternalAuthHandler } from '~/components/ExternalAuthHandler';
import { GlobalStyles } from '~/components/global/Styles';
import { getConfig } from '~/config/config';
import { FeatureToggleProvider } from '~/contexts';
import { googleTagManager } from '~/libs/googleTagManager';
import { availableThemes } from '~/themes';
import { firstStringVal } from '~/utils/queryString';
import SparseLayout from '../layouts/Sparse';
import { AuthContextProvider } from '../store/auth';
import { IOContextProvider } from '../store/socket';
import { AppContext, State, initialState, reducer } from '../store/state';
import { TimingsContextProvider } from '../store/timings';
import PageWithLayoutType from '../types/pageWithLayout';
const {
  publicRuntimeConfig
} = getConfig();
type AppProps = BaseAppProps & {
  Component: PageWithLayoutType;
};

function App({
  Component,
  pageProps
}: AppProps): JSX.Element {
  const router = useRouter();
  let embeddingOrgId: string;

  if (typeof router.query['embedding-org-id'] === 'string') {
    embeddingOrgId = router.query['embedding-org-id'];
  }

  let disableAuthPrompt = false;

  if (typeof router.query['disable-auth-prompt'] === 'string') {
    disableAuthPrompt = router.query['disable-auth-prompt'] === 'true';
  }

  let disableChat = false;

  if (typeof router.query['disable-chat'] === 'string') {
    disableChat = router.query['disable-chat'] === 'true';
  }

  let disableHelp = false;

  if (typeof router.query['disable-help'] === 'string') {
    disableHelp = router.query['disable-help'] === 'true';
  }

  let embeddedLogoUrl: string;

  if (typeof router.query['theme-options'] === 'string') {
    try {
      const themeOptionsEncoded = (router.query['theme-options'] as string);
      const themeOptions = JSON.parse(atob(decodeURIComponent(themeOptionsEncoded)));
      embeddedLogoUrl = themeOptions.logoUrl;
    } catch (error) {
      // Log the error so devs can see what is going on, but don't break the page.
      console.error("Decoding the theme didn't work for some reason");
      console.error(error);
    }
  }

  let embeddedAnalyticsInfo: Record<string, string>;

  if (typeof router.query['analytics-info'] === 'string') {
    try {
      const analyticsInfoEncoded = (router.query['analytics-info'] as string);
      embeddedAnalyticsInfo = JSON.parse(atob(decodeURIComponent(analyticsInfoEncoded)));
    } catch (error) {
      // Log the error so devs can see what is going on, but don't break the page.
      console.error("Decoding the analytics info didn't work for some reason");
      console.error(error);
    }
  }

  const embeddedDeviceId = firstStringVal(router.query['device-id']);
  const useGsuAuth = firstStringVal(router.query['use-gsu-auth']) === 'true';
  const startingState: State = { ...initialState,
    embeddingOrgId,
    embeddedDeviceId,
    disableAuthPrompt,
    disableChat,
    disableHelp,
    embeddedLogoUrl,
    embeddedAnalyticsInfo,
    useGsuAuth
  };
  const theme = availableThemes[embeddingOrgId?.toLowerCase()] ?? availableThemes['default'];
  const Layout = Component.layout || SparseLayout;
  const [state, dispatch] = useReducer(reducer, startingState);
  const isScrollable = router.pathname === '/session/[session_id]/feedback';
  useEffect(() => {
    if (embeddingOrgId || !router.locale) return;
    Cookies.set('NEXT_LOCALE', router.locale, {
      expires: 365,
      domain: publicRuntimeConfig.website.cookieDomain
    });
  }, []); // Init GTM

  useEffect(() => {
    // No third party tracking for the embedded product.
    if (embeddingOrgId) return;
    googleTagManager.initSDK();
  }, []);
  return <FeatureToggleProvider embeddingOrgId={embeddingOrgId}>
      <AppContext.Provider value={{
      state,
      dispatch
    }}>
        <AuthContextProvider>
          <ExternalAuthHandler>
            <TimingsContextProvider>
              <IOContextProvider>
                <ThemeProvider theme={theme}>
                  <GlobalStyles embeddingOrgId={embeddingOrgId} />
                  <Layout isScrollable={isScrollable}>
                    <Component {...pageProps} />
                  </Layout>
                </ThemeProvider>
              </IOContextProvider>
            </TimingsContextProvider>
          </ExternalAuthHandler>
        </AuthContextProvider>
      </AppContext.Provider>
    </FeatureToggleProvider>;
}

export default withUrqlClient(() => ({
  fetch,
  fetchOptions: {
    // it's 'same-origin' by default which won't work since we're doing CORS
    credentials: 'include',
    headers: {
      'apollographql-client-name': 'lobby',
      'apollographql-client-version': publicRuntimeConfig.environment // TODO: get git sha?

    }
  },
  url: publicRuntimeConfig.api
}))(appWithTranslation(App));