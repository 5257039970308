import { useTranslation } from 'next-i18next'
import { useMediaQuery } from 'usehooks-ts'
import Button from '~/components/v2/button'
import Dialog from '~/components/widgets/Dialog'
import { useDialog, useEventsTracker, useFeatureToggle, useVideoPlayer } from '~/contexts'
import { LoungeUIElementName } from '~/modules/events'
import { useAppContext } from '~/store/state'
import * as styles from './styles'

interface Props {
  title: string
}

export default function AlreadyStartedDialog({ title }: Props): JSX.Element {
  const showAltLiveLabels = useFeatureToggle('showAltLiveLabels')
  const { t } = useTranslation('lobby')
  const { dispatch } = useAppContext()
  const { toggleAlreadyStartedDialog } = useDialog()
  const { trackClickEvent } = useEventsTracker()
  const { videoPlayer } = useVideoPlayer()
  const isTablet = useMediaQuery('only screen and (min-width: 600px)')
  const isDesktop = useMediaQuery('only screen and (min-width: 980px)')
  const buttonSize = isDesktop ? 'md' : isTablet ? 'sm' : 'sm'

  return (
    <Dialog customClass={styles.wrapper} onClose={toggleAlreadyStartedDialog}>
      <div className={styles.container}>
        <div className={styles.heading}>{t('classAlreadyStarted')}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.buttonContainer}>
          <Button
            theme="primaryOutline"
            label={t('watchFromStart')}
            size={buttonSize}
            onClick={() => {
              dispatch({ type: 'SET_INSTANT_PLAYBACK', payload: true })
              toggleAlreadyStartedDialog()
              trackClickEvent(LoungeUIElementName.LOUNGE_BUTTON_IN_PROGRESS_WATCH_FROM_START)
              videoPlayer?.muted(false)
              videoPlayer?.play()
            }}
          />
          <Button
            theme="primary"
            label={showAltLiveLabels ? t('watchNow') : t('watchLive')}
            size={buttonSize}
            onClick={() => {
              toggleAlreadyStartedDialog()
              trackClickEvent(LoungeUIElementName.LOUNGE_BUTTON_IN_PROGRESS_WATCH_LIVE)
              videoPlayer?.muted(false)
              videoPlayer?.play()
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}
