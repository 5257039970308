import { TFunction } from 'next-i18next'
import * as s from 'superstruct'
import { BadgeType } from '~/components/v2/badge'
import * as guideMessages from '~/fixtures/messages/guide'
import { CurrentUser, GetsetupInfo, Guide, Sponsor } from '~/shared/api-generated-types'

export enum StaticMessageTypes {
  ANNOUNCEMENT_LIVESESSION_STARTING = 'ANNOUNCEMENT_LIVESESSION_STARTING',
  COMMUNITY_LED_CLASS = 'COMMUNITY_LED_CLASS',
  ENCORE_SESSION = 'ENCORE_SESSION',
  GUIDE_CHAT_CLOSING_SOON = 'GUIDE_CHAT_CLOSING_SOON',
  GUIDE_POST_CLASS = 'GUIDE_POST_CLASS',
  PARTNER_MESSAGE = 'PARTNER_MESSAGE',
  PARTNER_MESSAGE_ENDED = 'PARTNER_MESSAGE_ENDED',
  WELCOME_MESSAGE = 'WELCOME_MESSAGE',
  CHAT_MODERATION_AFTER_HOURS_MESSAGE = 'CHAT_MODERATION_AFTER_HOURS_MESSAGE',
  GETSETUP_COMMUNITY = 'GETSETUP_COMMUNITY',
  SPONSORED_SESSION_LIVE = 'SPONSORED_SESSION_LIVE',
  SPONSORED_SESSION_COMPLETED = 'SPONSORED_SESSION_COMPLETED',
  FEEDBACK_MESSAGE = 'FEEDBACK_MESSAGE',
  EMBEDDED_FEEDBACK_MESSAGE = 'EMBEDDED_FEEDBACK_MESSAGE',
  AFFILIATE_MARKETING_MESSAGE = 'AFFILIATE_MARKETING_MESSAGE',
  BOT_TYPING = 'BOT_TYPING',
}

const messageBase = {
  userId: 'system',
  isStaticMessage: true,
  timestamp: new Date().getTime(),
}

export type SessionState = {
  sessionId: string
  disableJoin: boolean
}

export enum MessageTypes {
  ANNOUNCEMENT = 'announcement',
  BROADCAST = 'broadcast',
  COMMUNITY_LED_CLASS = 'community-led-class',
  ENCORE_SESSION = 'encore-session',
  SPONSORED_SESSION_LIVE = 'sponsored-session-live',
  SPONSORED_SESSION_COMPLETED = 'sponsored-session-completed',
  GUIDE = 'guide',
  GUIDE_ALT = 'guide_alt',
  IMAGE = 'image',
  LEARNER_EVENT = 'learner_event',
  PARTNER = 'partner',
  SYSTEM = 'system',
  USER = 'user',
  VIDEO = 'video',
  WELCOME = 'welcome',
  WELCOME_REPLAY = 'welcome-replay',
  CHAT_MODERATION_AFTER_HOURS = 'chat-moderation-after-hours',
  GETSETUP_COMMUNITY_HEADER = 'GETSETUP_COMMUNITY_HEADER',
  FEEDBACK = 'feedback',
  EMBEDDED_FEEDBACK = 'embedded-feedback',
  AFFILIATE_MARKETING = 'affiliate-marketing',
}

export const emojiPrefix = 'emoji-'

export enum EMOTICONS {
  CLAP = 'emoji-clap',
  HIGHFIVE = 'emoji-highfive',
  LAUGH = 'emoji-laugh',
  LIKE = 'emoji-like',
  LOVE = 'emoji-love',
  WAVE = 'emoji-wave',
  WOW = 'emoji-wow',
}

export interface Emoticon {
  icon: string
  type: EMOTICONS
}

const ExternalUserUpdateStatus = s.enums([
  'ASSIGN_NAME_IN_PROGRESS',
  'ASSIGN_NAME_SUCCESS',
  'PROFANITY_CHECK_FAILED',
  'VALIDATION_ERROR',
])
export type ExternalUserUpdateStatus = s.Infer<typeof ExternalUserUpdateStatus>

const ExternalUserUpdateResponse = s.object({
  success: s.boolean(),
  error: s.optional(s.object({ code: ExternalUserUpdateStatus })),
})
type ExternalUserUpdateResponse = s.Infer<typeof ExternalUserUpdateResponse>

export function getIconByType(type: EMOTICONS): string {
  switch (type) {
    case EMOTICONS.CLAP:
      return EMOTICONS.CLAP

    case EMOTICONS.HIGHFIVE:
      return EMOTICONS.HIGHFIVE

    case EMOTICONS.LAUGH:
      return EMOTICONS.LAUGH

    case EMOTICONS.LIKE:
      return EMOTICONS.LIKE

    case EMOTICONS.LOVE:
      return EMOTICONS.LOVE

    case EMOTICONS.WOW:
      return EMOTICONS.WOW

    case EMOTICONS.WAVE:
      return EMOTICONS.WAVE
  }
}

export const reactions: Emoticon[] = [
  { icon: getIconByType(EMOTICONS.LIKE), type: EMOTICONS.LIKE },
  { icon: getIconByType(EMOTICONS.LAUGH), type: EMOTICONS.LAUGH },
  { icon: getIconByType(EMOTICONS.WOW), type: EMOTICONS.WOW },
  { icon: getIconByType(EMOTICONS.LOVE), type: EMOTICONS.LOVE },
  { icon: getIconByType(EMOTICONS.CLAP), type: EMOTICONS.CLAP },
  { icon: getIconByType(EMOTICONS.WAVE), type: EMOTICONS.WAVE },
]

interface MessageHiddenEvent {
  messageId: string
}

interface UserBannedEvent {
  userId: string
}

export type VideoAsset = {
  id: string
  title?: string
  duration?: string
  hls: string
  dash?: string
  thumbnail?: string
  fallbackThumbnail: string
}

export interface ChatMessage {
  badgeType: BadgeType
  content: string
  footerContent?: string
  heading?: string
  hidden?: boolean
  hideFooter?: boolean
  hideHeader?: boolean
  id: string
  imageUrl?: string
  isStaticMessage?: boolean
  link?: string
  name?: string // Display name
  recipientName?: string
  room?: string
  thumbnail64?: string
  timeAgo?: string
  timestamp: number
  type: MessageTypes
  userId: string
  videoAsset?: VideoAsset
  videos?: VideoAsset[]
  sponsor?: Sponsor
  launchMeeting?: () => void
}

export interface Member {
  room: string
  userId: string
  name: string
  firstJoined: number
  connected: boolean
  banned: boolean
}

export function formatName(name: string | null): string {
  if (!name) return ''
  const trimmedName = name.trim()
  const parts = trimmedName.split(' ')
  if (parts.length === 1) return parts[0]

  if (parts.length > 1) {
    // This is a dirty hack to enable us to show the whole, auto generated, name for AOL users. E.g: Learner 583023
    const lastPartOfName = parts[parts.length - 1]
    const isNumber = !isNaN(parseInt(lastPartOfName))
    if (isNumber) return trimmedName

    return `${parts[0]} ${lastPartOfName.substring(0, 1).toUpperCase()}`
  }
}

export function getBadgeMessage(badgeType: BadgeType): ChatMessage {
  switch (badgeType) {
    case 'COMMUNITY':
      return <ChatMessage>{
        ...messageBase,
        badgeType,
        content: '',
        id: 'community-badge-info',
        type: MessageTypes.COMMUNITY_LED_CLASS,
        userId: 'system',
      }

    case 'ENCORE':
      return <ChatMessage>{
        ...messageBase,
        badgeType,
        content: '',
        id: 'encore-badge-info',
        type: MessageTypes.ENCORE_SESSION,
        userId: 'system',
      }
  }
}

export function getLiveSponsorMessage(sponsor: Sponsor): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    sponsor: sponsor,
    content: '',
    id: 'encore-badge-info',
    type: MessageTypes.SPONSORED_SESSION_LIVE,
    userId: 'system',
  }
}
export function getCompletedSponsorMessage(sponsor: Sponsor): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    sponsor: sponsor,
    content: '',
    id: 'encore-badge-info',
    type: MessageTypes.SPONSORED_SESSION_COMPLETED,
    userId: 'system',
  }
}
export function getCommunityHeaderMessage(): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    id: 'gsu-community-header',
    isStaticMessage: true,
    type: MessageTypes.GETSETUP_COMMUNITY_HEADER,
  }
}

export function getFeedbackMessage(): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    id: 'feedback',
    isStaticMessage: true,
    type: MessageTypes.FEEDBACK,
  }
}

export function getEmbeddedFeedbackMessage(): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    id: 'embedded-feedback',
    isStaticMessage: true,
    type: MessageTypes.EMBEDDED_FEEDBACK,
  }
}

export function getAffiliateMarketingMessage(): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    id: 'affiliate-marketing',
    isStaticMessage: true,
    type: MessageTypes.AFFILIATE_MARKETING,
  }
}

export function getBotTypingMessage(username: string): ChatMessage {
  return <ChatMessage>{
    ...messageBase,
    id: 'ai-response',
    type: MessageTypes.BROADCAST,
    name: username,
    content: 'Typing...',
  }
}

const tivityWelcomeContent = `Welcome, we're glad you're here!<br />
        Reminder: Class participation is subject to GetSetUp's
        <a href="https://www.getsetup.com/policies/terms-of-use" target="_blank">Terms of Service</a> and
        <a href="https://www.getsetup.com/policies/privacy-policy" target="_blank"> Privacy Policy</a>.`

export function getStaticMessage(
  t: TFunction,
  currentUser: CurrentUser,
  getsetupInfo: GetsetupInfo,
  guide: Guide,
  type: MessageTypes,
  isJoinDisabled?: boolean,
  launchMeeting?: () => void,
  embeddingOrgId?: string,
): ChatMessage {
  const isTivityEmbed =
    embeddingOrgId === 'silversneakers' ||
    embeddingOrgId === 'silversneakers-aetna' ||
    embeddingOrgId === 'silversneakers-fitness'

  switch (type) {
    case MessageTypes.GUIDE:
      return <ChatMessage>{
        ...messageBase,
        id: 'guidePostClassMsg',
        isStaticMessage: true,
        type: 'guide',
        ...guideMessages.guidePostClassThanks(t),
        name: formatName(guide?.name),
        imageUrl: guide?.imageUrl,
        recipientName: currentUser?.authenticatedUser?.firstName,
      }
    case MessageTypes.WELCOME:
      // This logic might seem a bit backwards,
      // but this way we are not doing look ups that we don't need to.
      // Assume we are embedded, and are in side a Tivity embed.
      let liveWelcomeMessage = { content: tivityWelcomeContent }

      if (embeddingOrgId && !isTivityEmbed) {
        // We are embedded, but not in a Tivity Embed.
        liveWelcomeMessage = guideMessages.embeddedWelcomeMessage(t)
      } else if (!embeddingOrgId) {
        // Not embedded. - replayWelcomeMessage for EP-1357
        liveWelcomeMessage = guideMessages.replayWelcomeMessage(t)
      }

      return <ChatMessage>{
        ...messageBase,
        id: 'welcomeMessage',
        isStaticMessage: true,
        type: 'welcome',
        ...liveWelcomeMessage,
        name: formatName(guide?.name),
        imageUrl: guide?.imageUrl,
        recipientName: currentUser?.authenticatedUser?.firstName,
        launchMeeting,
      }

    case MessageTypes.WELCOME_REPLAY:
      // This logic might seem a bit backwards,
      // but this way we are not doing look ups that we don't need to.
      // Assume we are embedded, and are in side a Tivity embed.
      let replayWelcomeMessage = { content: tivityWelcomeContent }

      if (embeddingOrgId && !isTivityEmbed) {
        // We are embedded, but not in a Tivity Embed.
        replayWelcomeMessage = guideMessages.embeddedWelcomeMessage(t)
      } else if (!embeddingOrgId) {
        // Not embedded.
        replayWelcomeMessage = guideMessages.replayWelcomeMessage(t)
      }

      return <ChatMessage>{
        ...messageBase,
        id: 'welcomeMessageReplay',
        isStaticMessage: true,
        type: 'welcome-replay',
        ...replayWelcomeMessage,
        name: formatName(guide?.name),
        imageUrl: guide?.imageUrl,
        recipientName: currentUser?.authenticatedUser?.firstName,
      }

    case MessageTypes.CHAT_MODERATION_AFTER_HOURS:
      // This logic might seem a bit backwards,
      // but this way we are not doing look ups that we don't need to.
      // Assume we are embedded, and are in side a Tivity embed.
      let afterHoursMessage = { content: tivityWelcomeContent }

      if (!isTivityEmbed) {
        // Not in a Tivity Embed.
        afterHoursMessage = guideMessages.embeddedChatModerationAfterHoursMessage(t)
      }

      return <ChatMessage>{
        ...messageBase,
        id: 'chat-moderation-after-hours',
        isStaticMessage: true,
        type: 'chat-moderation-after-hours',
        ...afterHoursMessage,
        name: formatName(guide?.name),
        imageUrl: guide?.imageUrl,
        recipientName: currentUser?.authenticatedUser?.firstName,
      }
  }
}

interface SocketHandlerFunctions {
  onHideMessage(event: MessageHiddenEvent): void
  onInit(messages: ChatMessage[]): void
  onPrompts(prompts: string[]): void
  onNewMessage(message: ChatMessage): void
  onUserBanned(event: UserBannedEvent): void
  onUserUnBanned(event: UserBannedEvent): void
  onUserJoined(): void
  onUserLeft(): void
  onRoomMembersUpdate(data: { members: Member[] }): void
  onExternalUserUpdate(event: ExternalUserUpdateResponse): void
  onShowBotTyping(data: { username: string }): void
  onHideBotTyping(): void
}

function handleBanUnbanUsers(
  event: UserBannedEvent,
  setMembers: React.Dispatch<React.SetStateAction<Member[]>>,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
  isBanned = true,
): void {
  // Change user status to banned
  setMembers((previous) => {
    return previous.map((member) => {
      if (member.userId !== event.userId) return member
      return {
        ...member,
        banned: isBanned,
      }
    })
  })

  // Hide messages for the Banned user
  setMessages((previous) => {
    return previous.map((message: ChatMessage): ChatMessage => {
      if (message.userId !== event.userId) return message
      return {
        ...message,
        hidden: isBanned,
      }
    })
  })
}

export function SocketHandlers(
  incrementScrollBottomCounter: () => void,
  setHiddenMessageIds: React.Dispatch<React.SetStateAction<string[]>>,
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
  setPrompts: React.Dispatch<React.SetStateAction<string[]>>,
  setMembers: React.Dispatch<React.SetStateAction<Member[]>>,
  setExternalUserUpdateStatus: React.Dispatch<React.SetStateAction<ExternalUserUpdateStatus>>,
  setShowTypingMessage: React.Dispatch<React.SetStateAction<string>>,
): SocketHandlerFunctions {
  function onInit(messages: ChatMessage[]): void {
    setMessages((previous) => [...messages, ...previous])
    if (incrementScrollBottomCounter) incrementScrollBottomCounter()
  }

  function onPrompts(prompts: string[]): void {
    setPrompts(prompts)
  }

  function onNewMessage(message: ChatMessage): void {
    setMessages((previous) => [...previous, message])
    if (incrementScrollBottomCounter) incrementScrollBottomCounter()
  }

  function onHideMessage(event: MessageHiddenEvent): void {
    setHiddenMessageIds((previous) => [...previous, event.messageId])
  }

  function onUserJoined(): void {
    console.log('User joined')
  }

  function onUserBanned(event: UserBannedEvent): void {
    handleBanUnbanUsers(event, setMembers, setMessages)
  }

  function onUserUnBanned(event: UserBannedEvent): void {
    handleBanUnbanUsers(event, setMembers, setMessages, false)
  }

  function onUserLeft(): void {
    console.log('User left')
  }

  function onRoomMembersUpdate(data: { members: Member[] }): void {
    setMembers(data.members)
  }

  function onExternalUserUpdate(event: ExternalUserUpdateResponse): void {
    if (event.success) {
      setExternalUserUpdateStatus('ASSIGN_NAME_SUCCESS')
    } else if (event.error) {
      switch (event.error.code) {
        case 'PROFANITY_CHECK_FAILED':
          setExternalUserUpdateStatus('PROFANITY_CHECK_FAILED')
          break

        case 'VALIDATION_ERROR':
          setExternalUserUpdateStatus('VALIDATION_ERROR')
          break
      }
    }
  }

  function onShowBotTyping(data: { username: string }): void {
    setShowTypingMessage(data.username)
  }

  function onHideBotTyping(): void {
    setShowTypingMessage(null)
  }

  return {
    onInit,
    onPrompts,
    onHideMessage,
    onNewMessage,
    onUserBanned,
    onUserJoined,
    onUserLeft,
    onRoomMembersUpdate,
    onUserUnBanned,
    onExternalUserUpdate,
    onShowBotTyping,
    onHideBotTyping,
  }
}
