import { getConfig } from '~/config/config'

declare const window: Window &
  typeof globalThis & {
    dataLayer: any
  }

// Config
const {
  publicRuntimeConfig: { googleTagManager: GTM },
} = getConfig()

class GoogleTagManager {
  initSDK(): void {
    try {
      if (typeof window === 'undefined' || !GTM || !GTM.ID) return
      ;(function (w: any, d: any, s: any, l: any, i: any) {
        w[l] = w[l] || []
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
      })(window, document, 'script', 'dataLayer', GTM.ID)
    } catch (error) {
      console.error('loadGoogleTagManager', error)
    }
  }
}

export const googleTagManager = new GoogleTagManager()
