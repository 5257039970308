import { css } from 'linaria';
export const button_wrapper = "bz0xsh";
export const fullWidth = "f51vjq4";
export const flex = "f15f0svh";
export const standardSmall = "sjj9dtm";
export const small = "sx7zpnb";
export const tiny = "t1cv6mcp";
export const primary = "p1sd9pgm";
export const secondary = "s1byydxi";
export const secondaryAlt = "szwjcnp";
export const tertiarySolid = "tq5p7mb";
export const tertiary = "t1lgpllq";
export const success = "s1sg7zsi";
export const white = "w1kilc2j";
export const whiteActive = "wu54sdw";
export const rounded = "r1d9g3bx";
export const textOnly = "t18qfvjl";
export const circle = "c25xvpf";
export const grey = "gvlhky8";
export const darkGrey = "dt71onv";
export const link = "l1j0s4ah";
export const disabled = "d1fqudyx";
export const hidden_text = "hor4h3m";
export const loader_wrapper = "l1wrm3uj";
export const follow = "fc546iz";
export const dialog = "d2zvhmn";
export const videoModalClose = "v1xy0x81";
export const secondaryBorder = "s106t44v";
export const secondaryBand = "s1dn9z07";

require("../../.linaria-cache/components/buttons/styles.linaria.module.css");