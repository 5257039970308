import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql'
export type Maybe<T> = T | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } &
  { [P in K]-?: NonNullable<T[P]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Timestamp in ISO 8601 format in UTC timezone */
  UTCTimestamp: string
}

export type AccessMessageContent = {
  __typename: 'AccessMessageContent'
  backgroundColor?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  primaryAction?: Maybe<AccessMessagePrimaryAction>
  title?: Maybe<Scalars['String']>
}

export type AccessMessagePrimaryAction = DialogAction | UrlAction

export type AccessMessageSection = PageSection & {
  __typename: 'AccessMessageSection'
  content: AccessMessageContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type AolCategoryPillsSection = PageSection & {
  __typename: 'AolCategoryPillsSection'
  id: Scalars['ID']
  navigationActions: Array<AolCategoryPillsSectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type AolCategoryPillsSectionNavigationAction = {
  __typename: 'AolCategoryPillsSectionNavigationAction'
  iconUrl: Scalars['String']
  label: Scalars['String']
  sectionIdAnchorTarget: Scalars['String']
}

export type AolHubHeroContent = {
  __typename: 'AolHubHeroContent'
  description: Scalars['String']
  heading: AolHubHeroContentHeading
  session?: Maybe<SessionCard>
}

export type AolHubHeroContentHeading = {
  __typename: 'AolHubHeroContentHeading'
  imageUrl: Scalars['String']
  text: Scalars['String']
}

export type AolHubHeroSection = PageSection & {
  __typename: 'AolHubHeroSection'
  content: AolHubHeroContent
  id: Scalars['ID']
  navigationActions: Array<SectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type AppInfo = {
  __typename: 'AppInfo'
  appName: Scalars['String']
  currentVersion: Scalars['String']
  mustUpgrade: Scalars['Boolean']
}

export type AttendedSessionsPage = {
  __typename: 'AttendedSessionsPage'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  metaInfo: MetaInfo
  onLoadAction?: Maybe<OnLoadAction>
  sections: Array<
    | AccessMessageSection
    | AolCategoryPillsSection
    | AolHubHeroSection
    | AttendedSessionsPageSection
    | BookMultipleSessionsSection
    | CtaPageSection
    | CategoryClassesSection
    | CategoryIndexPageSection
    | CategoryInfoSection
    | CategorySessionsSection
    | ClassSummaryPageSection
    | CommunityBannersSection
    | CompanyInfoPageSection
    | ContentPageSection
    | EmbedCategoryPillsSection
    | EmbedHubHeroSection
    | EmbedLiveAndUpcomingSection
    | EmbedTrendingSection
    | FeaturedClassesSection
    | FeaturedSessionsSection
    | GuideClassPageSection
    | GuideClassesPageSection
    | GuideIndexInfoPageSection
    | GuideInfoPageSection
    | GuidePageSection
    | GuideSessionsPageSection
    | HelpInfoPageSection
    | HeroInfoSection
    | ImagePageSection
    | LiveAndUpcomingClassesPageSection
    | LiveGuidesSection
    | LiveNowPageSection
    | MicroLearningShortsSection
    | MostPopularPageSection
    | NextSessionSection
    | PartnerInfoPageSection
    | PartnershipBannerSection
    | PopularCategoriesSection
    | PopularSeriesSection
    | PricingPageSection
    | ReviewPageSection
    | SchedulePageSection
    | SearchPageSection
    | SectionGroupPageSection
    | SeriesClassesPageSection
    | SeriesClassesRowSection
    | SeriesIndexInfoPageSection
    | SeriesInfoPageSection
    | SeriesPageSection
    | SessionNotesDetailsPageSection
    | SessionNotesInfoPageSection
    | SimilarClassesPageSection
    | SnippetPageSection
    | SponsorInfoPageSection
    | StartingSoonPageSection
    | TagsPageSection
    | TestimonialsPageSection
    | UpcomingPageSection
    | WalkthroughPageSection
  >
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type AttendedSessionsPageInput = {
  itemsPerPage?: Maybe<Scalars['Int']>
  nextPageToken?: Maybe<Scalars['String']>
}

export type AttendedSessionsPageSection = PageSection &
  PaginatedList & {
    __typename: 'AttendedSessionsPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type AuthenticatedUser = {
  __typename: 'AuthenticatedUser'
  authenticationExpiration?: Maybe<Scalars['UTCTimestamp']>
  email?: Maybe<Scalars['String']>
  entitlements?: Maybe<PaginatedEntitlements>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isBanned: Scalars['Boolean']
  isVerified: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  legacyGuideId?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  scopes: Array<Scalars['String']>
}

export type AuthenticatedUserEntitlementsArgs = {
  limit?: Maybe<Scalars['Int']>
  nextPageCursor?: Maybe<Scalars['String']>
}

export type BanUserInput = {
  notes?: Maybe<Scalars['String']>
  reason: DemeritReason
  recordedBy: Scalars['String']
  userId: Scalars['String']
}

export type BanUserOutput = {
  __typename: 'BanUserOutput'
  success: Scalars['Boolean']
}

/** Community Banners */
export type BannerImageUrl = {
  __typename: 'BannerImageUrl'
  bannerWithoutText?: Maybe<Scalars['String']>
  large: Scalars['String']
  small: Scalars['String']
}

export type BookMultipleSessionsSection = PageSection & {
  __typename: 'BookMultipleSessionsSection'
  id: Scalars['ID']
  items: Array<SessionCard>
  subtitle: Scalars['String']
  title: Scalars['String']
}

export type BookSessionCardAction = CardAction & {
  __typename: 'BookSessionCardAction'
  label: Scalars['String']
  sessionId: Scalars['String']
}

export type CtaPageSection = PageSection & {
  __typename: 'CTAPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  label?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type CallRequiredActionResult = {
  __typename: 'CallRequiredActionResult'
  resultDialog?: Maybe<Dialog>
}

export type Callout = {
  __typename: 'Callout'
  content?: Maybe<Array<RichText>>
  icon?: Maybe<Scalars['String']>
}

export type CancelSessionBookingCardAction = CardAction & {
  __typename: 'CancelSessionBookingCardAction'
  label: Scalars['String']
  sessionId: Scalars['String']
}

export type CancelSessionBookingResult = {
  __typename: 'CancelSessionBookingResult'
  message?: Maybe<Scalars['String']>
  sessionId: Scalars['String']
  success: Scalars['Boolean']
}

/** Cards - Represent slots, classes or similar */
export type Card = {
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type CardAction = {
  label: Scalars['String']
}

export type Category = {
  __typename: 'Category'
  id: Scalars['ID']
  slug: Scalars['String']
  title: Scalars['String']
}

export type CategoryCard = Card & {
  __typename: 'CategoryCard'
  backgroundColor?: Maybe<Scalars['String']>
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  numberOfClasses?: Maybe<Scalars['Int']>
  slug: Scalars['String']
  title: Scalars['String']
}

export type CategoryClassesSection = PageSection & {
  __typename: 'CategoryClassesSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<SessionCard>
  pageInfo: PageInfo
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CategoryIndexPageSection = PageSection & {
  __typename: 'CategoryIndexPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<CategoryCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CategoryInfoContent = {
  __typename: 'CategoryInfoContent'
  backgroundColor?: Maybe<Scalars['String']>
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  slug: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type CategoryInfoSection = PageSection & {
  __typename: 'CategoryInfoSection'
  catalogueLanguage: Scalars['String']
  content: CategoryInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CategoryRecommendation = {
  __typename: 'CategoryRecommendation'
  id: Scalars['String']
  name: Scalars['String']
}

export type CategoryRecommendationRequest = {
  numberOfRecommendations?: Maybe<Scalars['Int']>
}

export type CategoryRecommendations = {
  __typename: 'CategoryRecommendations'
  categories: Array<CategoryRecommendation>
}

export type CategorySessionsSection = PageSection & {
  __typename: 'CategorySessionsSection'
  displayAllItems?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  items: Array<SessionCard>
  navigationActions: Array<SectionNavigationAction>
  pageInfo: PageInfo
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Class = {
  __typename: 'Class'
  bannerUrl?: Maybe<Scalars['String']>
  cardImageUrl?: Maybe<Scalars['String']>
  /** @deprecated Use `cardImageUrl` instead */
  cardUrl?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Category>>
  /** @deprecated Use `categories` instead */
  categoryId?: Maybe<Scalars['String']>
  descriptionHtml?: Maybe<Scalars['String']>
  durationInMinutes?: Maybe<Scalars['Int']>
  /** @deprecated Use `durationInMinutes` instead */
  durationText?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  /** @deprecated Use `labels` instead */
  labelIds?: Maybe<Array<Scalars['Int']>>
  labels?: Maybe<Array<Scalars['String']>>
  requirementsHtml?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  sponsor?: Maybe<Sponsor>
  taglineHtml?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
  videos: Array<Video>
}

export type ClassCard = Card & {
  __typename: 'ClassCard'
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  navigationAction?: Maybe<
    | HeroInfoNavigationAction
    | NavigateBackButtonAction
    | NavigateToAttendedSessionDetailAction
    | NavigateToClassDetailAction
    | NavigateToGuideAction
    | NavigateToSeriesAction
    | PartnerInfoNavigationAction
  >
  primaryAction?: Maybe<
    | BookSessionCardAction
    | CancelSessionBookingCardAction
    | GetAccessSessionCardAction
    | JoinBookedSessionCardAction
    | JoinLiveSessionCardAction
    | MustAuthenticateUserCardAction
    | ViewClassDetailCardAction
  >
  secondaryAction?: Maybe<
    | BookSessionCardAction
    | CancelSessionBookingCardAction
    | GetAccessSessionCardAction
    | JoinBookedSessionCardAction
    | JoinLiveSessionCardAction
    | MustAuthenticateUserCardAction
    | ViewClassDetailCardAction
  >
  slug: Scalars['String']
  title: Scalars['String']
  upcomingSession?: Maybe<SessionCard>
}

export type ClassDetailsAccessDisplay = LockedAccessDisplay | UnLockedAccessDisplay

export type ClassSchema = SchemaBase & {
  __typename: 'ClassSchema'
  context: Scalars['String']
  description?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  name: Scalars['String']
  provider?: Maybe<SchemaProvider>
  type: Scalars['String']
  url: Scalars['String']
}

export type ClassSummaryContent = {
  __typename: 'ClassSummaryContent'
  durationMinutes?: Maybe<Scalars['Int']>
  guideAvatarUrl?: Maybe<Scalars['String']>
  guideLocation?: Maybe<Scalars['String']>
  guideName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCommunityLedClass?: Maybe<Scalars['Boolean']>
  navigateToGuideAction?: Maybe<NavigateToGuideAction>
  primaryAction?: Maybe<SectionNavigationAction>
  rating?: Maybe<Scalars['Float']>
  reviewCount?: Maybe<Scalars['Int']>
  subtitle?: Maybe<Scalars['String']>
  tags: Array<Tag>
  title?: Maybe<Scalars['String']>
}

/** Section specific for outlining meta/summary information about a class */
export type ClassSummaryPageSection = PageSection & {
  __typename: 'ClassSummaryPageSection'
  catalogueLanguage: Scalars['String']
  content: ClassSummaryContent
  id: Scalars['ID']
  navigationActions: Array<SectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CommunityBannerCard = {
  __typename: 'CommunityBannerCard'
  bannerImageUrls: BannerImageUrl
  label: Scalars['String']
  link: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type CommunityBannersSection = PageSection & {
  __typename: 'CommunityBannersSection'
  id: Scalars['ID']
  items: Array<Maybe<CommunityBannerCard>>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type CompanyInfoBlock = {
  __typename: 'CompanyInfoBlock'
  companyStats: Array<CompanyStat>
  featuredLogos: Array<FeaturedLogo>
}

/** Company info */
export type CompanyInfoPageSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'CompanyInfoPageSection'
    id: Scalars['ID']
    items: Array<CompanyInfoBlock>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type CompanyStat = {
  __typename: 'CompanyStat'
  icon?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type ComplimentaryProduct = ProductBase & {
  __typename: 'ComplimentaryProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

/** Other page sections */
export type ContentPageSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'ContentPageSection'
    catalogueLanguage: Scalars['String']
    content: TextContent
    id: Scalars['ID']
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type CreateFeedbackInput = {
  embeddingOrgId?: Maybe<Scalars['String']>
  embeddingOrgUserId?: Maybe<Scalars['String']>
  generalNotes?: Maybe<Scalars['String']>
  guideNotes?: Maybe<Scalars['String']>
  guideRating: Scalars['Int']
  sessionId: Scalars['String']
  sessionNotes?: Maybe<Scalars['String']>
  sessionRating: Scalars['Int']
}

export type CreateFeedbackResult = {
  __typename: 'CreateFeedbackResult'
  feedback: Feedback
}

export type CurrentUser = {
  __typename: 'CurrentUser'
  authenticatedUser?: Maybe<AuthenticatedUser>
  /**
   * The country code detected by the GraphQL API server based on the request (usually its IP address),
   * which is not necessarily the same as the `countryCode` of the user (if logged in).
   */
  detectedCountryCode?: Maybe<Scalars['String']>
  /** The dialing code for the detectedCountryCode (country level extension for the phone numbers) */
  detectedCountryDialingCode?: Maybe<Scalars['String']>
  /** The IP address of the client detected by the GraphQL API server. */
  detectedIPAddress?: Maybe<Scalars['String']>
  deviceId?: Maybe<Scalars['String']>
}

export enum DemeritReason {
  AudioVideoAbuse = 'audio_video_abuse',
  ChatAbuse = 'chat_abuse',
  Other = 'other',
}

export type Dialog = {
  __typename: 'Dialog'
  callout?: Maybe<Callout>
  closable: Scalars['Boolean']
  hasActionDivider: Scalars['Boolean']
  hasHelpText: Scalars['Boolean']
  id: Scalars['ID']
  lottieJsonUrl?: Maybe<Scalars['String']>
  partnerLogoUrl?: Maybe<Scalars['String']>
  primaryAction?: Maybe<DialogActions>
  secondaryAction?: Maybe<DialogActions>
  supplementaryText?: Maybe<Scalars['String']>
  title: Scalars['String']
  videoPosterUrl?: Maybe<Scalars['String']>
  videoUrl?: Maybe<Scalars['String']>
}

export type DialogAction = {
  __typename: 'DialogAction'
  /** This represents a KnownDialogID enum value from CPA */
  dialogId: Scalars['String']
  label: Scalars['String']
}

export type DialogActions =
  | DialogConfirmationAction
  | DialogDismissAction
  | DialogFacebookConnectAction
  | DialogPageAction
  | DialogUrlAction

export type DialogConfirmationAction = {
  __typename: 'DialogConfirmationAction'
  label: Scalars['String']
  /** This represents a KnownActions enum value from CPA */
  requiredAction: Scalars['String']
}

export type DialogDismissAction = {
  __typename: 'DialogDismissAction'
  label: Scalars['String']
}

export type DialogFacebookConnectAction = {
  __typename: 'DialogFacebookConnectAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type DialogPageAction = {
  __typename: 'DialogPageAction'
  label: Scalars['String']
  targetPageId: Scalars['String']
}

export type DialogUrlAction = {
  __typename: 'DialogUrlAction'
  configUrlsSuffix?: Maybe<Scalars['String']>
  label: Scalars['String']
  url: Scalars['String']
}

export type DisplayPrice = {
  __typename: 'DisplayPrice'
  amount: Scalars['Float']
  currencyCode: Scalars['String']
  currencySymbol: Scalars['String']
}

export enum DisplayPriceDecorationType {
  Hidden = 'hidden',
  StrikeThrough = 'strike_through',
}

export type EmbedCategoryPillsSection = PageSection & {
  __typename: 'EmbedCategoryPillsSection'
  id: Scalars['ID']
  navigationActions: Array<EmbedCategoryPillsSectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type EmbedCategoryPillsSectionNavigationAction = {
  __typename: 'EmbedCategoryPillsSectionNavigationAction'
  iconUrl: Scalars['String']
  label: Scalars['String']
  sectionIdAnchorTarget: Scalars['String']
}

export type EmbedHubHeroContent = {
  __typename: 'EmbedHubHeroContent'
  description: Scalars['String']
  heading: EmbedHubHeroContentHeading
  session?: Maybe<SessionCard>
}

export type EmbedHubHeroContentHeading = {
  __typename: 'EmbedHubHeroContentHeading'
  imageUrl: Scalars['String']
  text: Scalars['String']
}

export type EmbedHubHeroSection = PageSection & {
  __typename: 'EmbedHubHeroSection'
  content: EmbedHubHeroContent
  id: Scalars['ID']
  navigationActions: Array<SectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type EmbedLiveAndUpcomingSection = PageSection & {
  __typename: 'EmbedLiveAndUpcomingSection'
  id: Scalars['ID']
  items: Array<SessionCard>
  pageInfo: PageInfo
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type EmbedTrendingSection = PageSection & {
  __typename: 'EmbedTrendingSection'
  id: Scalars['ID']
  items: Array<SessionCard>
  pageInfo: PageInfo
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Entitlement = {
  __typename: 'Entitlement'
  activeWithinValidityPeriod: Scalars['Boolean']
  createdAt: Scalars['UTCTimestamp']
  externalReferenceId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  notes?: Maybe<Scalars['String']>
  price?: Maybe<NonRecurringPrice | RecurringPrice>
  product:
    | ComplimentaryProduct
    | GrandfatheredProduct
    | GuideProduct
    | PartnershipProduct
    | RecurringSubscriptionProduct
    | ScholarshipProduct
    | SingleSessionProduct
    | StaffProduct
    | TrialProduct
  userBillingCountryCode?: Maybe<Scalars['String']>
  validFrom: Scalars['UTCTimestamp']
  validTo: Scalars['UTCTimestamp']
}

export type EntitlementCard = {
  __typename: 'EntitlementCard'
  description: Scalars['String']
  entitlementId: Scalars['String']
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  primaryAction?: Maybe<DialogActions>
  title: Scalars['String']
  validity?: Maybe<Scalars['String']>
}

export type EntitlementsPage = {
  __typename: 'EntitlementsPage'
  currentEntitlementsSection?: Maybe<EntitlementsSection>
  previousEntitlementsSection?: Maybe<EntitlementsSection>
}

export type EntitlementsSection = PaginatedList & {
  __typename: 'EntitlementsSection'
  items?: Maybe<Array<EntitlementCard>>
  pageInfo: PageInfo
}

export type EntitlementsSectionInput = {
  limit?: Maybe<Scalars['Int']>
  nextPageCursor?: Maybe<Scalars['String']>
}

export type FacetCount = {
  __typename: 'FacetCount'
  active: Scalars['Boolean']
  count: Scalars['Int']
  name: Scalars['String']
}

export type FacetCounts = {
  __typename: 'FacetCounts'
  categories: Array<FacetCount>
  guides: Array<FacetCount>
}

export type FacetInfo = {
  __typename: 'FacetInfo'
  facetsAvailable: FacetCounts
  facetsUsed: Array<Scalars['String']>
}

export type FacetedList = {
  facetInfo?: Maybe<FacetInfo>
}

export type Feature = {
  __typename: 'Feature'
  enabled: Scalars['Boolean']
  id: Scalars['String']
}

export type FeaturedClassesSection = PageSection & {
  __typename: 'FeaturedClassesSection'
  id: Scalars['ID']
  items: Array<ClassCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type FeaturedLogo = {
  __typename: 'FeaturedLogo'
  imageUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type FeaturedSessionsSection = PageSection & {
  __typename: 'FeaturedSessionsSection'
  id: Scalars['ID']
  items: Array<SessionCard>
  navigationActions: Array<SectionNavigationAction>
  pageInfo: PageInfo
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Feedback = {
  __typename: 'Feedback'
  classId: Scalars['String']
  embeddingOrgId?: Maybe<Scalars['String']>
  generalNotes?: Maybe<Scalars['String']>
  guideNotes?: Maybe<Scalars['String']>
  guideRating: Scalars['Int']
  id: Scalars['String']
  sessionId: Scalars['String']
  sessionNotes?: Maybe<Scalars['String']>
  sessionRating: Scalars['Int']
  sessionScheduledStartTime: Scalars['UTCTimestamp']
  userId: Scalars['String']
}

export type FeedbackInput = {
  embeddingOrgUserId?: Maybe<Scalars['String']>
  sessionId: Scalars['String']
}

export type FilterVideoInput = {
  classId: Scalars['ID']
  videoType?: Maybe<VideoType>
}

export type GetAccessSessionCardAction = CardAction & {
  __typename: 'GetAccessSessionCardAction'
  /** This represents a KnownDialogID enum value from CPA */
  dialogType?: Maybe<Scalars['String']>
  label: Scalars['String']
  sessionId: Scalars['String']
}

export type GetVideoInput = {
  videoId: Scalars['ID']
}

export type GetsetupInfo = {
  __typename: 'GetsetupInfo'
  customerSupportContactNumber: Scalars['String']
  videoGuideWelcome?: Maybe<VideoAsset>
  videoLoungeTour?: Maybe<VideoAsset>
}

export type GrandfatheredProduct = ProductBase & {
  __typename: 'GrandfatheredProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type Guide = {
  __typename: 'Guide'
  askMeAbout?: Maybe<Array<Scalars['String']>>
  facebookUrl?: Maybe<Scalars['String']>
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  isActive?: Maybe<Scalars['Boolean']>
  isFeatured: Scalars['Boolean']
  linkedInUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  tagline?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  twitterUrl?: Maybe<Scalars['String']>
}

export type GuideCard = Card & {
  __typename: 'GuideCard'
  active?: Maybe<Scalars['Boolean']>
  classCount: Scalars['Int']
  countryCode?: Maybe<Scalars['String']>
  countryFlagImageUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  navigationAction?: Maybe<
    | HeroInfoNavigationAction
    | NavigateBackButtonAction
    | NavigateToAttendedSessionDetailAction
    | NavigateToClassDetailAction
    | NavigateToGuideAction
    | NavigateToSeriesAction
    | PartnerInfoNavigationAction
  >
  skills: Array<Maybe<Scalars['String']>>
  slug: Scalars['String']
  title: Scalars['String']
}

export type GuideClassContent = {
  __typename: 'GuideClassContent'
  active?: Maybe<Scalars['Boolean']>
  askMeAbout: Array<Maybe<Scalars['String']>>
  avatarUrl?: Maybe<Scalars['String']>
  facebookUrl?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  linkedInUrl?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  tagline: Scalars['String']
  title?: Maybe<Scalars['String']>
  twitterUrl?: Maybe<Scalars['String']>
}

export type GuideClassPageSection = PageSection & {
  __typename: 'GuideClassPageSection'
  catalogueLanguage: Scalars['String']
  content: GuideClassContent
  id: Scalars['ID']
  navigationActions: Array<SectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GuideClassesPageSection = PageSection & {
  __typename: 'GuideClassesPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<ClassCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GuideIndexInfoContent = {
  __typename: 'GuideIndexInfoContent'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
}

export type GuideIndexInfoPageSection = PageSection & {
  __typename: 'GuideIndexInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: GuideIndexInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GuideIndexPage = {
  __typename: 'GuideIndexPage'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  metaInfo: MetaInfo
  onLoadAction?: Maybe<OnLoadAction>
  sections: Array<
    | AccessMessageSection
    | AolCategoryPillsSection
    | AolHubHeroSection
    | AttendedSessionsPageSection
    | BookMultipleSessionsSection
    | CtaPageSection
    | CategoryClassesSection
    | CategoryIndexPageSection
    | CategoryInfoSection
    | CategorySessionsSection
    | ClassSummaryPageSection
    | CommunityBannersSection
    | CompanyInfoPageSection
    | ContentPageSection
    | EmbedCategoryPillsSection
    | EmbedHubHeroSection
    | EmbedLiveAndUpcomingSection
    | EmbedTrendingSection
    | FeaturedClassesSection
    | FeaturedSessionsSection
    | GuideClassPageSection
    | GuideClassesPageSection
    | GuideIndexInfoPageSection
    | GuideInfoPageSection
    | GuidePageSection
    | GuideSessionsPageSection
    | HelpInfoPageSection
    | HeroInfoSection
    | ImagePageSection
    | LiveAndUpcomingClassesPageSection
    | LiveGuidesSection
    | LiveNowPageSection
    | MicroLearningShortsSection
    | MostPopularPageSection
    | NextSessionSection
    | PartnerInfoPageSection
    | PartnershipBannerSection
    | PopularCategoriesSection
    | PopularSeriesSection
    | PricingPageSection
    | ReviewPageSection
    | SchedulePageSection
    | SearchPageSection
    | SectionGroupPageSection
    | SeriesClassesPageSection
    | SeriesClassesRowSection
    | SeriesIndexInfoPageSection
    | SeriesInfoPageSection
    | SeriesPageSection
    | SessionNotesDetailsPageSection
    | SessionNotesInfoPageSection
    | SimilarClassesPageSection
    | SnippetPageSection
    | SponsorInfoPageSection
    | StartingSoonPageSection
    | TagsPageSection
    | TestimonialsPageSection
    | UpcomingPageSection
    | WalkthroughPageSection
  >
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type GuideInfoContent = {
  __typename: 'GuideInfoContent'
  description?: Maybe<Scalars['String']>
  hasSessions?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
}

export type GuideInfoPageSection = PageSection & {
  __typename: 'GuideInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: GuideInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GuidePageSection = PageSection & {
  __typename: 'GuidePageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<GuideCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type GuideProduct = ProductBase & {
  __typename: 'GuideProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type GuideSessionsPageSection = PageSection & {
  __typename: 'GuideSessionsPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<SessionCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HelpInfo = {
  __typename: 'HelpInfo'
  primaryAction?: Maybe<UrlAction>
  secondaryAction?: Maybe<UrlAction>
  title: Scalars['String']
}

export type HelpInfoPageSection = PageSection & {
  __typename: 'HelpInfoPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<HelpInfo>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HeroInfoContent = {
  __typename: 'HeroInfoContent'
  description?: Maybe<Scalars['String']>
  navigationActions: Array<HeroInfoNavigationAction>
  primaryAction?: Maybe<HeroInfoPrimaryAction>
  showVideo: Scalars['Boolean']
  title?: Maybe<Scalars['String']>
  userStats?: Maybe<UserStats>
  video?: Maybe<HeroInfoContentVideo>
}

export type HeroInfoContentVideo = {
  __typename: 'HeroInfoContentVideo'
  posterUrl?: Maybe<Scalars['String']>
  srcUrl: Scalars['String']
}

export type HeroInfoNavigationAction = NavigationAction & {
  __typename: 'HeroInfoNavigationAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type HeroInfoPrimaryAction = {
  __typename: 'HeroInfoPrimaryAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type HeroInfoSection = PageSection & {
  __typename: 'HeroInfoSection'
  content: HeroInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type HomeSchema = SchemaBase & {
  __typename: 'HomeSchema'
  context: Scalars['String']
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
  potentialAction?: Maybe<PotentialActionSchema>
  sameAs?: Maybe<Array<Scalars['String']>>
  type: Scalars['String']
  url: Scalars['String']
}

export type Image = {
  __typename: 'Image'
  altText?: Maybe<Scalars['String']>
  imageUrl: Scalars['String']
}

export type ImagePageSection = PageSection & {
  __typename: 'ImagePageSection'
  accessDisplay: ClassDetailsAccessDisplay
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  isCommunityLedClass?: Maybe<Scalars['Boolean']>
  items: Array<Image>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type JoinBookedSessionCardAction = CardAction & {
  __typename: 'JoinBookedSessionCardAction'
  label: Scalars['String']
  sessionId: Scalars['String']
  url: Scalars['String']
}

export type JoinLiveSessionCardAction = CardAction & {
  __typename: 'JoinLiveSessionCardAction'
  label: Scalars['String']
  sessionId: Scalars['String']
  url: Scalars['String']
}

export type LiveAndUpcomingClassesPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'LiveAndUpcomingClassesPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type LiveGuidesSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'LiveGuidesSection'
    id: Scalars['ID']
    items: Array<GuideCard>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

/** Page sections for snapshots of filtered card content, primarily for home/landing pages */
export type LiveNowPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'LiveNowPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type LiveStream = {
  __typename: 'LiveStream'
  /** RTMP endpoint for streaming to. Available only to authorised users. */
  ingestServer?: Maybe<Scalars['String']>
  /** The publicly accessible HLS master manifest stream URL */
  playbackUrl: Scalars['String']
  /** Stream authentication key for RTMP endpoint. Available only to authorised users. */
  streamKey?: Maybe<Scalars['String']>
}

export enum LivenessType {
  Live = 'Live',
  Replay = 'Replay',
  Unknown = 'Unknown',
  Vod = 'VOD',
}

export type LobbySession = {
  __typename: 'LobbySession'
  /** @deprecated Will be removed once GetSetUp VOD is released */
  aolVodVideoDurationInSecondsHack?: Maybe<Scalars['Int']>
  attachments: Array<SessionNotesAttachment>
  authenticatedUserIsGuide: Scalars['Boolean']
  /**
   * Optionally dictate the lobby back button navigation behaviour.
   *
   * Purpose:
   * For the AOL lounge experience (within an iframe) we need to take
   * users back to a defined page, as we completely loose any historic
   * navigation context. In the case of AOL, they will be category hub pages
   * associated with the session of the lounge.
   */
  backButtonNavigationAction?: Maybe<NavigateBackButtonAction>
  canHaveStream: Scalars['Boolean']
  cardImageUrl?: Maybe<Scalars['String']>
  class?: Maybe<Class>
  classCategories: Array<Scalars['String']>
  /** @deprecated Use `class.id` instead */
  deprecatedTimekitProjectId?: Maybe<Scalars['String']>
  /** @deprecated Use `id` instead */
  deprecatedTimekitSlotId?: Maybe<Scalars['String']>
  durationInMinutes?: Maybe<Scalars['Int']>
  endTimeUTC?: Maybe<Scalars['UTCTimestamp']>
  guide?: Maybe<Guide>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  /** @deprecated Will be removed once GetSetUp VOD is released */
  isAolVodClassHack?: Maybe<Scalars['Boolean']>
  isCommunityLedClass: Scalars['Boolean']
  isEncore: Scalars['Boolean']
  isMeetingProtected: Scalars['Boolean']
  /**
   * The preferred URL for launching the meeting for this particular class Slot.
   *
   * This is generally a Zoom join URL, optionally with some security token embedded.
   *
   * Only available when authenticated, entitled access, and when the authenticated
   * user is verified and not banned.
   */
  launchMeetingUrl?: Maybe<Scalars['String']>
  livenessType: LivenessType
  nonStreamableReason?: Maybe<NonStreamableReason>
  notesHtml?: Maybe<Scalars['String']>
  recording?: Maybe<Recording>
  sponsor?: Maybe<Sponsor>
  startTimeUTC: Scalars['UTCTimestamp']
  stream?: Maybe<LiveStream>
  title: Scalars['String']
  topic?: Maybe<Scalars['String']>
  userHasEntitlement: Scalars['Boolean']
  zoomMeetingId?: Maybe<Scalars['String']>
}

export type LobbySessionInput = {
  deprecatedTimekitSlotId?: Maybe<Scalars['ID']>
  sessionId?: Maybe<Scalars['ID']>
}

export enum LobbyType {
  V1 = 'v1',
  V2 = 'v2',
}

export type LocationBase = {
  __typename: 'LocationBase'
  type: Scalars['String']
  url: Scalars['String']
}

export type LockedAccessDisplay = {
  __typename: 'LockedAccessDisplay'
  iconUrl: Scalars['String']
}

export type MetaInfo = {
  __typename: 'MetaInfo'
  description: Scalars['String']
  image?: Maybe<Scalars['String']>
  jsonLd?: Maybe<MetaInfoSchema>
  robots?: Maybe<Scalars['String']>
  title: Scalars['String']
  url: Scalars['String']
}

export type MetaInfoSchema = ClassSchema | HomeSchema | SessionSchema

export type MicroLearningShortsSection = PageSection & {
  __typename: 'MicroLearningShortsSection'
  id: Scalars['ID']
  items: Array<Maybe<MicroShortCard>>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type MicroShortCard = {
  __typename: 'MicroShortCard'
  link: Scalars['String']
}

export type MostPopularPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'MostPopularPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type MustAuthenticateUserCardAction = CardAction & {
  __typename: 'MustAuthenticateUserCardAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type Mutation = {
  __typename: 'Mutation'
  banUser: BanUserOutput
  callRequiredAction?: Maybe<CallRequiredActionResult>
  cancelMultipleSessionBookings: Array<CancelSessionBookingResult>
  cancelSessionBooking: CancelSessionBookingResult
  createFeedback: CreateFeedbackResult
  createMultipleSessionBooking?: Maybe<Scalars['Boolean']>
  createSessionBooking?: Maybe<Scalars['Boolean']>
  recommendationFeedback: Scalars['Boolean']
  setPartnerCookieByCode: SetPartnerCookieByCodeOutput
  unbanUser: UnbanUserOutput
  validateAutoLoginLink: ValidateAutoLoginLinkOutput
}

export type MutationBanUserArgs = {
  input: BanUserInput
}

export type MutationCallRequiredActionArgs = {
  action: Scalars['String']
}

export type MutationCancelMultipleSessionBookingsArgs = {
  sessionIds: Array<Scalars['String']>
}

export type MutationCancelSessionBookingArgs = {
  sessionId: Scalars['String']
}

export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput
}

export type MutationCreateMultipleSessionBookingArgs = {
  sessionIds: Array<Scalars['String']>
  timezone: Scalars['String']
  timezoneOffset: Scalars['Int']
}

export type MutationCreateSessionBookingArgs = {
  sessionId: Scalars['String']
  timezone: Scalars['String']
  timezoneOffset: Scalars['Int']
}

export type MutationRecommendationFeedbackArgs = {
  feedback: Scalars['String']
}

export type MutationSetPartnerCookieByCodeArgs = {
  code: Scalars['String']
}

export type MutationUnbanUserArgs = {
  input: UnbanUserInput
}

export type MutationValidateAutoLoginLinkArgs = {
  input: ValidateAutoLoginLinkInput
}

export type NavigateBackButtonAction = NavigationAction & {
  __typename: 'NavigateBackButtonAction'
  url: Scalars['String']
}

export type NavigateToAttendedSessionDetailAction = NavigationAction & {
  __typename: 'NavigateToAttendedSessionDetailAction'
  sessionId: Scalars['String']
  url: Scalars['String']
}

export type NavigateToClassDetailAction = NavigationAction & {
  __typename: 'NavigateToClassDetailAction'
  classId: Scalars['String']
  classSlug?: Maybe<Scalars['String']>
  url: Scalars['String']
}

export type NavigateToGuideAction = NavigationAction & {
  __typename: 'NavigateToGuideAction'
  guideId: Scalars['String']
  guideSlug: Scalars['String']
  url: Scalars['String']
}

export type NavigateToSeriesAction = NavigationAction & {
  __typename: 'NavigateToSeriesAction'
  seriesId: Scalars['String']
  seriesSlug: Scalars['String']
  url: Scalars['String']
}

export type NavigationAction = {
  url: Scalars['String']
}

/** Next session for a class */
export type NextSessionSection = PageSection & {
  __typename: 'NextSessionSection'
  classId: Scalars['String']
  id: Scalars['ID']
  items: Array<SessionCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type NonRecurringPrice = Price & {
  __typename: 'NonRecurringPrice'
  currencyCode: Scalars['String']
  id: Scalars['ID']
  productId: Scalars['ID']
  unitAmountCents: Scalars['Int']
}

export enum NonStreamableReason {
  Privacy = 'PRIVACY',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN',
}

export type OnLoadAction = Dialog

export enum OriginalVideoSource {
  Other = 'Other',
  Studio = 'Studio',
  Zoom = 'Zoom',
}

/** Page type definitions */
export type Page = {
  __typename: 'Page'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  metaInfo: MetaInfo
  onLoadAction?: Maybe<OnLoadAction>
  sections: Array<
    | AccessMessageSection
    | AolCategoryPillsSection
    | AolHubHeroSection
    | AttendedSessionsPageSection
    | BookMultipleSessionsSection
    | CtaPageSection
    | CategoryClassesSection
    | CategoryIndexPageSection
    | CategoryInfoSection
    | CategorySessionsSection
    | ClassSummaryPageSection
    | CommunityBannersSection
    | CompanyInfoPageSection
    | ContentPageSection
    | EmbedCategoryPillsSection
    | EmbedHubHeroSection
    | EmbedLiveAndUpcomingSection
    | EmbedTrendingSection
    | FeaturedClassesSection
    | FeaturedSessionsSection
    | GuideClassPageSection
    | GuideClassesPageSection
    | GuideIndexInfoPageSection
    | GuideInfoPageSection
    | GuidePageSection
    | GuideSessionsPageSection
    | HelpInfoPageSection
    | HeroInfoSection
    | ImagePageSection
    | LiveAndUpcomingClassesPageSection
    | LiveGuidesSection
    | LiveNowPageSection
    | MicroLearningShortsSection
    | MostPopularPageSection
    | NextSessionSection
    | PartnerInfoPageSection
    | PartnershipBannerSection
    | PopularCategoriesSection
    | PopularSeriesSection
    | PricingPageSection
    | ReviewPageSection
    | SchedulePageSection
    | SearchPageSection
    | SectionGroupPageSection
    | SeriesClassesPageSection
    | SeriesClassesRowSection
    | SeriesIndexInfoPageSection
    | SeriesInfoPageSection
    | SeriesPageSection
    | SessionNotesDetailsPageSection
    | SessionNotesInfoPageSection
    | SimilarClassesPageSection
    | SnippetPageSection
    | SponsorInfoPageSection
    | StartingSoonPageSection
    | TagsPageSection
    | TestimonialsPageSection
    | UpcomingPageSection
    | WalkthroughPageSection
  >
  title: Scalars['String']
}

export type PageInfo = {
  __typename: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPrevPage: Scalars['Boolean']
  nextPageCursor?: Maybe<Scalars['String']>
  page?: Maybe<Scalars['Int']>
  pageItems?: Maybe<Scalars['Int']>
  prevPageCursor?: Maybe<Scalars['String']>
  totalItems?: Maybe<Scalars['Int']>
  totalItemsIsApproximate: Scalars['Boolean']
}

/** Page sections */
export type PageSection = {
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PageSectionUnion =
  | AccessMessageSection
  | AolCategoryPillsSection
  | AolHubHeroSection
  | AttendedSessionsPageSection
  | BookMultipleSessionsSection
  | CtaPageSection
  | CategoryClassesSection
  | CategoryIndexPageSection
  | CategoryInfoSection
  | CategorySessionsSection
  | ClassSummaryPageSection
  | CommunityBannersSection
  | CompanyInfoPageSection
  | ContentPageSection
  | EmbedCategoryPillsSection
  | EmbedHubHeroSection
  | EmbedLiveAndUpcomingSection
  | EmbedTrendingSection
  | FeaturedClassesSection
  | FeaturedSessionsSection
  | GuideClassPageSection
  | GuideClassesPageSection
  | GuideIndexInfoPageSection
  | GuideInfoPageSection
  | GuidePageSection
  | GuideSessionsPageSection
  | HelpInfoPageSection
  | HeroInfoSection
  | ImagePageSection
  | LiveAndUpcomingClassesPageSection
  | LiveGuidesSection
  | LiveNowPageSection
  | MicroLearningShortsSection
  | MostPopularPageSection
  | NextSessionSection
  | PartnerInfoPageSection
  | PartnershipBannerSection
  | PopularCategoriesSection
  | PopularSeriesSection
  | PricingPageSection
  | ReviewPageSection
  | SchedulePageSection
  | SearchPageSection
  | SectionGroupPageSection
  | SeriesClassesPageSection
  | SeriesClassesRowSection
  | SeriesIndexInfoPageSection
  | SeriesInfoPageSection
  | SeriesPageSection
  | SessionNotesDetailsPageSection
  | SessionNotesInfoPageSection
  | SimilarClassesPageSection
  | SnippetPageSection
  | SponsorInfoPageSection
  | StartingSoonPageSection
  | TagsPageSection
  | TestimonialsPageSection
  | UpcomingPageSection
  | WalkthroughPageSection

export type PageSectionWithNavigation = {
  id: Scalars['ID']
  navigationActions: Array<SectionNavigationAction>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PageSections = {
  __typename: 'PageSections'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  sections: Array<
    Maybe<
      | AccessMessageSection
      | AolCategoryPillsSection
      | AolHubHeroSection
      | AttendedSessionsPageSection
      | BookMultipleSessionsSection
      | CtaPageSection
      | CategoryClassesSection
      | CategoryIndexPageSection
      | CategoryInfoSection
      | CategorySessionsSection
      | ClassSummaryPageSection
      | CommunityBannersSection
      | CompanyInfoPageSection
      | ContentPageSection
      | EmbedCategoryPillsSection
      | EmbedHubHeroSection
      | EmbedLiveAndUpcomingSection
      | EmbedTrendingSection
      | FeaturedClassesSection
      | FeaturedSessionsSection
      | GuideClassPageSection
      | GuideClassesPageSection
      | GuideIndexInfoPageSection
      | GuideInfoPageSection
      | GuidePageSection
      | GuideSessionsPageSection
      | HelpInfoPageSection
      | HeroInfoSection
      | ImagePageSection
      | LiveAndUpcomingClassesPageSection
      | LiveGuidesSection
      | LiveNowPageSection
      | MicroLearningShortsSection
      | MostPopularPageSection
      | NextSessionSection
      | PartnerInfoPageSection
      | PartnershipBannerSection
      | PopularCategoriesSection
      | PopularSeriesSection
      | PricingPageSection
      | ReviewPageSection
      | SchedulePageSection
      | SearchPageSection
      | SectionGroupPageSection
      | SeriesClassesPageSection
      | SeriesClassesRowSection
      | SeriesIndexInfoPageSection
      | SeriesInfoPageSection
      | SeriesPageSection
      | SessionNotesDetailsPageSection
      | SessionNotesInfoPageSection
      | SimilarClassesPageSection
      | SnippetPageSection
      | SponsorInfoPageSection
      | StartingSoonPageSection
      | TagsPageSection
      | TestimonialsPageSection
      | UpcomingPageSection
      | WalkthroughPageSection
    >
  >
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PageUnion = Page | SchedulePage

export type PaginatedEntitlements = PaginatedList & {
  __typename: 'PaginatedEntitlements'
  items?: Maybe<Array<Entitlement>>
  pageInfo: PageInfo
}

export type PaginatedList = {
  pageInfo: PageInfo
}

export type Partner = {
  __typename: 'Partner'
  code?: Maybe<Scalars['String']>
  descriptionHtml?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  titleHtml?: Maybe<Scalars['String']>
}

export type PartnerInfoContent = {
  __typename: 'PartnerInfoContent'
  code?: Maybe<Scalars['String']>
  couponCode?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  logo?: Maybe<Scalars['String']>
  name: Scalars['String']
  navigationActions: Array<PartnerInfoNavigationAction>
  primaryAction?: Maybe<PartnerInfoPrimaryAction>
  showVideo: Scalars['Boolean']
  slug: Scalars['String']
  title?: Maybe<Scalars['String']>
  userStats?: Maybe<UserStats>
  video?: Maybe<PartnerInfoContentVideo>
}

export type PartnerInfoContentVideo = {
  __typename: 'PartnerInfoContentVideo'
  posterUrl?: Maybe<Scalars['String']>
  srcUrl: Scalars['String']
}

export type PartnerInfoNavigationAction = NavigationAction & {
  __typename: 'PartnerInfoNavigationAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type PartnerInfoPageSection = PageSection & {
  __typename: 'PartnerInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: PartnerInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PartnerInfoPrimaryAction = {
  __typename: 'PartnerInfoPrimaryAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type PartnershipBannerContent = {
  __typename: 'PartnershipBannerContent'
  description: Scalars['String']
  imageUrls: PartnershipBannerImageUrls
  label: Scalars['String']
  link: Scalars['String']
  logoUrl: Scalars['String']
  partnerName: Scalars['String']
  title: Scalars['String']
}

export type PartnershipBannerImageUrls = {
  __typename: 'PartnershipBannerImageUrls'
  desktop: Scalars['String']
  mobile: Scalars['String']
  smallTablet: Scalars['String']
  tablet: Scalars['String']
}

export type PartnershipBannerSection = PageSection & {
  __typename: 'PartnershipBannerSection'
  content: PartnershipBannerContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PartnershipProduct = ProductBase & {
  __typename: 'PartnershipProduct'
  id: Scalars['ID']
  name: Scalars['String']
  partner?: Maybe<Partner>
  /** @deprecated use `partner` instead */
  partnerId: Scalars['ID']
}

export type PopularCategoriesSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'PopularCategoriesSection'
    catalogueLanguage: Scalars['String']
    id: Scalars['ID']
    items: Array<CategoryCard>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type PopularSeriesSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'PopularSeriesSection'
    id: Scalars['ID']
    items: Array<SeriesCard>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type PotentialActionSchema = {
  __typename: 'PotentialActionSchema'
  queryInput: Scalars['String']
  target: Scalars['String']
  type: Scalars['String']
}

export type Price = {
  currencyCode: Scalars['String']
  id: Scalars['ID']
  productId: Scalars['ID']
  unitAmountCents: Scalars['Int']
}

export type PricingPageSection = PageSection & {
  __typename: 'PricingPageSection'
  id: Scalars['ID']
  items: Array<PricingPlanItem>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type PricingPlanAction = {
  __typename: 'PricingPlanAction'
  label: Scalars['String']
  target: PricingPlanActionTarget
  type: PricingPlanActionType
  url: Scalars['String']
}

export enum PricingPlanActionTarget {
  Blank = 'blank',
  Self = 'self',
}

export enum PricingPlanActionType {
  Form = 'Form',
  Link = 'Link',
  Selected = 'Selected',
  SelectedForm = 'SelectedForm',
  SelectedLink = 'SelectedLink',
}

export type PricingPlanHighlight = {
  __typename: 'PricingPlanHighlight'
  label: Scalars['String']
}

export type PricingPlanItem = {
  __typename: 'PricingPlanItem'
  features: Array<Scalars['String']>
  featuresTitle?: Maybe<Scalars['String']>
  highlighted?: Maybe<PricingPlanHighlight>
  iconUrl?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** isFree is used for the community plan. It doesn't include any pricing. */
  isFree: Scalars['Boolean']
  /**
   * isNonPlan is used for when a PricingPlanItem is to *display* as a plan, but it doesn't have pricing AND it's not free.
   * This is used for the Partner plan.
   */
  isNonPlan: Scalars['Boolean']
  pricing?: Maybe<Array<PricingPlanPricing>>
  primaryAction?: Maybe<PricingPlanAction>
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
  type?: Maybe<Scalars['String']>
}

export type PricingPlanPricing = {
  __typename: 'PricingPlanPricing'
  billingInterval?: Maybe<Scalars['String']>
  displayPrice: Scalars['String']
  gsuPriceId?: Maybe<Scalars['String']>
}

export type Product =
  | ComplimentaryProduct
  | GrandfatheredProduct
  | GuideProduct
  | PartnershipProduct
  | RecurringSubscriptionProduct
  | ScholarshipProduct
  | SingleSessionProduct
  | StaffProduct
  | TrialProduct

export type ProductBase = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type Query = {
  __typename: 'Query'
  appInfo?: Maybe<AppInfo>
  attendedSessionsPage?: Maybe<Page>
  categoryIndexPage?: Maybe<Page>
  categoryPage?: Maybe<Page>
  classDetailPage?: Maybe<Page>
  currentUser: CurrentUser
  dialog?: Maybe<Dialog>
  embedPage?: Maybe<Page>
  entitlementsPage?: Maybe<EntitlementsPage>
  features: Array<Feature>
  feedback?: Maybe<Feedback>
  getsetupInfo?: Maybe<GetsetupInfo>
  guideIndexPage?: Maybe<Page>
  guidePage?: Maybe<Page>
  homepageSections?: Maybe<PageSections>
  lobbySession?: Maybe<LobbySession>
  mySchedulePage?: Maybe<Page>
  partnerPage?: Maybe<Page>
  schedulePage?: Maybe<Page>
  searchPage?: Maybe<Page>
  seriesIndexPage?: Maybe<Page>
  seriesPage?: Maybe<Page>
  sessionLobbyPage?: Maybe<SessionLobbyPage>
  sessionNotesPage?: Maybe<Page>
  sitemapCategories: SitemapContent
  sitemapClasses: SitemapContent
  sitemapGuides: SitemapContent
  sitemapSeries: SitemapContent
  sitemapSessions: SitemapContent
  /** @deprecated Use `lobbySession` instead */
  slot?: Maybe<Slot>
  slotRecommendations: SlotRecommendations
  staticPage?: Maybe<Page>
  video?: Maybe<Video>
  videos: Array<Video>
}

export type QueryAppInfoArgs = {
  appName: Scalars['String']
  currentVersion: Scalars['String']
}

export type QueryAttendedSessionsPageArgs = {
  input: AttendedSessionsPageInput
}

export type QueryCategoryPageArgs = {
  page?: Maybe<Scalars['Int']>
  slug: Scalars['String']
}

export type QueryClassDetailPageArgs = {
  classId: Scalars['ID']
}

export type QueryDialogArgs = {
  returnTo?: Maybe<Scalars['String']>
  type: Scalars['ID']
}

export type QueryEmbedPageArgs = {
  id: Scalars['ID']
}

export type QueryEntitlementsPageArgs = {
  currentEntitlementsInput: EntitlementsSectionInput
  previousEntitlementsInput: EntitlementsSectionInput
}

export type QueryFeaturesArgs = {
  appName: Scalars['String']
  currentVersion: Scalars['String']
}

export type QueryFeedbackArgs = {
  input: FeedbackInput
}

export type QueryGuidePageArgs = {
  slug: Scalars['String']
}

export type QueryHomepageSectionsArgs = {
  id: Scalars['ID']
}

export type QueryLobbySessionArgs = {
  input: LobbySessionInput
}

export type QueryMySchedulePageArgs = {
  request: SchedulePageRequest
}

export type QueryPartnerPageArgs = {
  slug: Scalars['String']
}

export type QuerySchedulePageArgs = {
  request: SchedulePageRequest
}

export type QuerySearchPageArgs = {
  request: SearchPageRequest
}

export type QuerySeriesPageArgs = {
  slug: Scalars['String']
}

export type QuerySessionLobbyPageArgs = {
  input: SessionLobbyPageInput
}

export type QuerySessionNotesPageArgs = {
  request: SessionNotesPageRequest
}

export type QuerySlotArgs = {
  id?: Maybe<Scalars['ID']>
  timekitSlotId?: Maybe<Scalars['ID']>
}

export type QuerySlotRecommendationsArgs = {
  request: SlotRecommendationRequest
}

export type QueryStaticPageArgs = {
  id: Scalars['ID']
}

export type QueryVideoArgs = {
  input: GetVideoInput
}

export type QueryVideosArgs = {
  input: FilterVideoInput
}

export type Recording = {
  __typename: 'Recording'
  type: RecordingType
  url: Scalars['String']
}

export enum RecordingType {
  Mp4 = 'MP4',
  Unknown = 'UNKNOWN',
}

export enum RecurringInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type RecurringPrice = Price & {
  __typename: 'RecurringPrice'
  currencyCode: Scalars['String']
  id: Scalars['ID']
  interval: RecurringInterval
  intervalCount: Scalars['Int']
  productId: Scalars['ID']
  unitAmountCents: Scalars['Int']
}

export type RecurringSubscriptionProduct = ProductBase & {
  __typename: 'RecurringSubscriptionProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type Review = {
  __typename: 'Review'
  content?: Maybe<Scalars['String']>
  date?: Maybe<Scalars['UTCTimestamp']>
  id: Scalars['ID']
  rating: Scalars['Float']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ReviewPageSection = PageSection &
  PaginatedList & {
    __typename: 'ReviewPageSection'
    catalogueLanguage: Scalars['String']
    id: Scalars['ID']
    items: Array<Review>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type RichText = RichTextLinkNode | RichTextTextNode

export enum RichTextDataLinkType {
  Hyperlink = 'Hyperlink',
  Page = 'Page',
}

export enum RichTextDataType {
  Link = 'Link',
}

export type RichTextHyperlinkLinkNode = {
  __typename: 'RichTextHyperlinkLinkNode'
  linkType: RichTextDataLinkType
  type: RichTextDataType
  value: Scalars['String']
}

export type RichTextLinkNode = {
  __typename: 'RichTextLinkNode'
  data: RichTextLinkNodeDataNode
  nodeType: RichTextNodeType
  value: Scalars['String']
}

export type RichTextLinkNodeDataNode = RichTextHyperlinkLinkNode | RichTextPageLinkNode

export enum RichTextNodeType {
  Link = 'Link',
  Text = 'Text',
}

export type RichTextPageLinkNode = {
  __typename: 'RichTextPageLinkNode'
  linkType: RichTextDataLinkType
  targetPageId: Scalars['String']
  type: RichTextDataType
}

export type RichTextTextNode = {
  __typename: 'RichTextTextNode'
  nodeType: RichTextNodeType
  value: Scalars['String']
}

export type ScheduleDaySection = {
  __typename: 'ScheduleDaySection'
  date?: Maybe<Scalars['UTCTimestamp']>
  items: Array<SessionCard>
}

export type SchedulePage = {
  __typename: 'SchedulePage'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  metaInfo: MetaInfo
  onLoadAction?: Maybe<OnLoadAction>
  sections: Array<SchedulePageSection>
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

/** Search */
export type SchedulePageRequest = {
  facets?: Maybe<Array<Scalars['String']>>
  maxItemsPerSection?: Maybe<Scalars['Int']>
  page?: Maybe<Scalars['Int']>
  sessionsIntervalEnd?: Maybe<Scalars['Int']>
  sessionsIntervalStart?: Maybe<Scalars['Int']>
  startFrom?: Maybe<Scalars['UTCTimestamp']>
  timezone: Scalars['String']
}

/** Schedule */
export type SchedulePageSection = FacetedList &
  PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'SchedulePageSection'
    facetInfo?: Maybe<FacetInfo>
    id: Scalars['ID']
    items: Array<ScheduleDaySection>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type SchemaBase = {
  context: Scalars['String']
  description?: Maybe<Scalars['String']>
  name: Scalars['String']
}

export type SchemaProvider = {
  __typename: 'SchemaProvider'
  name: Scalars['String']
  sameAs?: Maybe<Array<Scalars['String']>>
  type: Scalars['String']
}

export type ScholarshipProduct = ProductBase & {
  __typename: 'ScholarshipProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type SearchClassesFacet = {
  __typename: 'SearchClassesFacet'
  count: Scalars['Int']
  facet: Scalars['String']
  name: Scalars['String']
}

export type SearchClassesFilter = {
  category?: Maybe<Scalars['String']>
}

export type SearchClassesRequest = {
  facets?: Maybe<Array<Scalars['String']>>
  filter?: Maybe<SearchClassesFilter>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  term: Scalars['String']
}

export type SearchClassesResults = {
  __typename: 'SearchClassesResults'
  classes: Array<Class>
  facets?: Maybe<Array<SearchClassesFacet>>
  hits: Scalars['Int']
}

/** Search */
export type SearchPageRequest = {
  maxItemsPerSection: Scalars['Int']
  page?: Maybe<Scalars['Int']>
  query: Scalars['String']
}

/** Search */
export type SearchPageSection = PageSection &
  PaginatedList & {
    __typename: 'SearchPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export enum SectionGroupLayoutHint {
  Stack = 'Stack',
  Tab = 'Tab',
}

export type SectionGroupPageSection = PageSection & {
  __typename: 'SectionGroupPageSection'
  id: Scalars['ID']
  layoutHint?: Maybe<SectionGroupLayoutHint>
  sections: Array<
    Maybe<
      | AccessMessageSection
      | AolCategoryPillsSection
      | AolHubHeroSection
      | AttendedSessionsPageSection
      | BookMultipleSessionsSection
      | CtaPageSection
      | CategoryClassesSection
      | CategoryIndexPageSection
      | CategoryInfoSection
      | CategorySessionsSection
      | ClassSummaryPageSection
      | CommunityBannersSection
      | CompanyInfoPageSection
      | ContentPageSection
      | EmbedCategoryPillsSection
      | EmbedHubHeroSection
      | EmbedLiveAndUpcomingSection
      | EmbedTrendingSection
      | FeaturedClassesSection
      | FeaturedSessionsSection
      | GuideClassPageSection
      | GuideClassesPageSection
      | GuideIndexInfoPageSection
      | GuideInfoPageSection
      | GuidePageSection
      | GuideSessionsPageSection
      | HelpInfoPageSection
      | HeroInfoSection
      | ImagePageSection
      | LiveAndUpcomingClassesPageSection
      | LiveGuidesSection
      | LiveNowPageSection
      | MicroLearningShortsSection
      | MostPopularPageSection
      | NextSessionSection
      | PartnerInfoPageSection
      | PartnershipBannerSection
      | PopularCategoriesSection
      | PopularSeriesSection
      | PricingPageSection
      | ReviewPageSection
      | SchedulePageSection
      | SearchPageSection
      | SectionGroupPageSection
      | SeriesClassesPageSection
      | SeriesClassesRowSection
      | SeriesIndexInfoPageSection
      | SeriesInfoPageSection
      | SeriesPageSection
      | SessionNotesDetailsPageSection
      | SessionNotesInfoPageSection
      | SimilarClassesPageSection
      | SnippetPageSection
      | SponsorInfoPageSection
      | StartingSoonPageSection
      | TagsPageSection
      | TestimonialsPageSection
      | UpcomingPageSection
      | WalkthroughPageSection
    >
  >
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SectionNavigationAction = {
  __typename: 'SectionNavigationAction'
  label: Scalars['String']
  targetPageId: Scalars['String']
  theme?: Maybe<Scalars['String']>
}

export type SeriesCard = Card & {
  __typename: 'SeriesCard'
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  navigationAction?: Maybe<
    | HeroInfoNavigationAction
    | NavigateBackButtonAction
    | NavigateToAttendedSessionDetailAction
    | NavigateToClassDetailAction
    | NavigateToGuideAction
    | NavigateToSeriesAction
    | PartnerInfoNavigationAction
  >
  numberOfClasses: Scalars['Int']
  slug: Scalars['String']
  sponsor?: Maybe<Sponsor>
  title: Scalars['String']
}

export type SeriesClassesPageSection = PageSection & {
  __typename: 'SeriesClassesPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<ClassCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SeriesClassesRowSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'SeriesClassesRowSection'
    catalogueLanguage: Scalars['String']
    id: Scalars['ID']
    items: Array<ClassCard>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type SeriesIndexInfoContent = {
  __typename: 'SeriesIndexInfoContent'
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  title?: Maybe<Scalars['String']>
}

export type SeriesIndexInfoPageSection = PageSection & {
  __typename: 'SeriesIndexInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: SeriesIndexInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SeriesIndexPage = {
  __typename: 'SeriesIndexPage'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  metaInfo: MetaInfo
  onLoadAction?: Maybe<OnLoadAction>
  sections: Array<
    | AccessMessageSection
    | AolCategoryPillsSection
    | AolHubHeroSection
    | AttendedSessionsPageSection
    | BookMultipleSessionsSection
    | CtaPageSection
    | CategoryClassesSection
    | CategoryIndexPageSection
    | CategoryInfoSection
    | CategorySessionsSection
    | ClassSummaryPageSection
    | CommunityBannersSection
    | CompanyInfoPageSection
    | ContentPageSection
    | EmbedCategoryPillsSection
    | EmbedHubHeroSection
    | EmbedLiveAndUpcomingSection
    | EmbedTrendingSection
    | FeaturedClassesSection
    | FeaturedSessionsSection
    | GuideClassPageSection
    | GuideClassesPageSection
    | GuideIndexInfoPageSection
    | GuideInfoPageSection
    | GuidePageSection
    | GuideSessionsPageSection
    | HelpInfoPageSection
    | HeroInfoSection
    | ImagePageSection
    | LiveAndUpcomingClassesPageSection
    | LiveGuidesSection
    | LiveNowPageSection
    | MicroLearningShortsSection
    | MostPopularPageSection
    | NextSessionSection
    | PartnerInfoPageSection
    | PartnershipBannerSection
    | PopularCategoriesSection
    | PopularSeriesSection
    | PricingPageSection
    | ReviewPageSection
    | SchedulePageSection
    | SearchPageSection
    | SectionGroupPageSection
    | SeriesClassesPageSection
    | SeriesClassesRowSection
    | SeriesIndexInfoPageSection
    | SeriesInfoPageSection
    | SeriesPageSection
    | SessionNotesDetailsPageSection
    | SessionNotesInfoPageSection
    | SimilarClassesPageSection
    | SnippetPageSection
    | SponsorInfoPageSection
    | StartingSoonPageSection
    | TagsPageSection
    | TestimonialsPageSection
    | UpcomingPageSection
    | WalkthroughPageSection
  >
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

export type SeriesInfoContent = {
  __typename: 'SeriesInfoContent'
  description?: Maybe<Scalars['String']>
  hasSessions?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
}

export type SeriesInfoPageSection = PageSection & {
  __typename: 'SeriesInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: SeriesInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SeriesPageSection = PageSection & {
  __typename: 'SeriesPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<SeriesCard>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SessionCard = Card & {
  __typename: 'SessionCard'
  accessDisplay: SessionCardAccessDisplay
  /** @deprecated Will be removed once GetSetUp VOD is released */
  aolVodVideoDurationInSecondsHack?: Maybe<Scalars['Int']>
  booked?: Maybe<Scalars['Boolean']>
  classId: Scalars['String']
  /** classImageUrl is a full / 1200px wide version of the imageUrl */
  classImageUrl?: Maybe<Scalars['String']>
  classIsNew?: Maybe<Scalars['Boolean']>
  classSlug?: Maybe<Scalars['String']>
  durationMinutes: Scalars['Int']
  end: Scalars['UTCTimestamp']
  guideAvatarUrl?: Maybe<Scalars['String']>
  guideByline: Scalars['String']
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  /** @deprecated Will be removed once GetSetUp VOD is released */
  isAolVodClassHack?: Maybe<Scalars['Boolean']>
  isCommunityLedClass?: Maybe<Scalars['Boolean']>
  isEncore?: Maybe<Scalars['Boolean']>
  metaInfo: SessionMetaInfo
  navigateToAttendedSessionDetailAction?: Maybe<NavigateToAttendedSessionDetailAction>
  navigateToGuideAction?: Maybe<NavigateToGuideAction>
  navigationAction?: Maybe<
    | HeroInfoNavigationAction
    | NavigateBackButtonAction
    | NavigateToAttendedSessionDetailAction
    | NavigateToClassDetailAction
    | NavigateToGuideAction
    | NavigateToSeriesAction
    | PartnerInfoNavigationAction
  >
  primaryAction?: Maybe<
    | BookSessionCardAction
    | CancelSessionBookingCardAction
    | GetAccessSessionCardAction
    | JoinBookedSessionCardAction
    | JoinLiveSessionCardAction
    | MustAuthenticateUserCardAction
    | ViewClassDetailCardAction
  >
  rating?: Maybe<Scalars['Float']>
  secondaryAction?: Maybe<
    | BookSessionCardAction
    | CancelSessionBookingCardAction
    | GetAccessSessionCardAction
    | JoinBookedSessionCardAction
    | JoinLiveSessionCardAction
    | MustAuthenticateUserCardAction
    | ViewClassDetailCardAction
  >
  sessionId: Scalars['String']
  sponsor?: Maybe<Sponsor>
  start: Scalars['UTCTimestamp']
  title: Scalars['String']
}

export type SessionCardAccessDisplay = LockedAccessDisplay | UnLockedAccessDisplay

export type SessionInfoContent = {
  __typename: 'SessionInfoContent'
  classId: Scalars['String']
  classSlug: Scalars['String']
  classTitle: Scalars['String']
  durationMinutes: Scalars['Int']
  end: Scalars['UTCTimestamp']
  guideAvatarUrl?: Maybe<Scalars['String']>
  guideByline: Scalars['String']
  guideId: Scalars['String']
  guideSlug: Scalars['String']
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  navigateToGuideAction?: Maybe<NavigateToGuideAction>
  navigationAction?: Maybe<
    | HeroInfoNavigationAction
    | NavigateBackButtonAction
    | NavigateToAttendedSessionDetailAction
    | NavigateToClassDetailAction
    | NavigateToGuideAction
    | NavigateToSeriesAction
    | PartnerInfoNavigationAction
  >
  sessionId: Scalars['String']
  start: Scalars['UTCTimestamp']
}

export type SessionLobbyPage = {
  __typename: 'SessionLobbyPage'
  onLoadAction?: Maybe<OnLoadAction>
}

/** SessionLobbyPage */
export type SessionLobbyPageInput = {
  sessionId?: Maybe<Scalars['String']>
  timekitSlotId?: Maybe<Scalars['String']>
}

export type SessionMetaInfo = {
  __typename: 'SessionMetaInfo'
  jsonLd: SessionSchema
}

export type SessionNotesAttachment = {
  __typename: 'SessionNotesAttachment'
  created: Scalars['UTCTimestamp']
  fileName: Scalars['String']
  id: Scalars['ID']
  sizeInBytes: Scalars['Int']
  updated: Scalars['UTCTimestamp']
  url: Scalars['String']
}

export type SessionNotesDetailsPageSection = PageSection & {
  __typename: 'SessionNotesDetailsPageSection'
  attachments: Array<SessionNotesAttachment>
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  notesHtml?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SessionNotesInfoPageSection = PageSection & {
  __typename: 'SessionNotesInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: SessionInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SessionNotesPageRequest = {
  sessionId: Scalars['String']
}

export type SessionSchema = SchemaBase & {
  __typename: 'SessionSchema'
  context: Scalars['String']
  description?: Maybe<Scalars['String']>
  eventAttendanceMode: Scalars['String']
  image?: Maybe<Scalars['String']>
  location: LocationBase
  name: Scalars['String']
  startDate: Scalars['String']
  type: Scalars['String']
  url: Scalars['String']
}

export type SetPartnerCookieByCodeOutput = {
  __typename: 'SetPartnerCookieByCodeOutput'
  success: Scalars['Boolean']
}

/** Classes similar to another */
export type SimilarClassesPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'SimilarClassesPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type SingleSessionProduct = ProductBase & {
  __typename: 'SingleSessionProduct'
  id: Scalars['ID']
  name: Scalars['String']
  session?: Maybe<SessionCard>
  /** @deprecated use `session` instead */
  sessionId: Scalars['ID']
}

export type SitemapContent = {
  __typename: 'SitemapContent'
  urlset: Array<Maybe<SitemapUrl>>
}

export type SitemapUrl = {
  __typename: 'SitemapUrl'
  lastmod?: Maybe<Scalars['UTCTimestamp']>
  loc: Scalars['String']
}

export type Slot = {
  __typename: 'Slot'
  /** @deprecated Will be removed once GetSetUp VOD is released */
  aolVodVideoDurationInSecondsHack?: Maybe<Scalars['Int']>
  authenticatedUserIsGuide?: Maybe<Scalars['Boolean']>
  canHaveStream: Scalars['Boolean']
  class?: Maybe<Class>
  durationInMinutes: Scalars['Int']
  endTimeUTC?: Maybe<Scalars['String']>
  /**
   * The fallback URL for launching the meeting for this particular class Slot when
   * `launchMeetingUrl` fails.  This is generally a Zoom join URL without any
   * security token embedded.
   *
   * Only available when authenticated, entitled access and when the authenticated user is verified
   * and not banned.
   */
  fallbackLaunchMeetingUrl?: Maybe<Scalars['String']>
  guide?: Maybe<Guide>
  /** @deprecated Use `guide` instead */
  guideId: Scalars['String']
  id: Scalars['ID']
  /** @deprecated Will be removed once GetSetUp VOD is released */
  isAolVodClassHack?: Maybe<Scalars['Boolean']>
  isMeetingProtected: Scalars['Boolean']
  /**
   * The preferred URL for launching the meeting for this particular class Slot.
   * This is generally a Zoom join URL with some security token embedded.
   *
   * If the meeting is protected, the client should choose the launchMeetingPrivateUrl.
   *
   * Only available when authenticated, entitled access and when the authenticated user is verified
   * and not banned.
   */
  launchMeetingPrivateUrl?: Maybe<Scalars['String']>
  /**
   * The launchMeetingPublicUrl for launching the meeting for this particular class Slot.
   * This is generally a Zoom join URL without security token.
   *
   * If the meeting is NOT protected, the client should choose the launchMeetingPublicUrlL,
   * because this is a PUBLIC webinar.
   */
  launchMeetingPublicUrl?: Maybe<Scalars['String']>
  /**
   * The preferred URL for launching the meeting for this particular class Slot.
   * This is generally a Zoom join URL with some security token embedded.
   *
   * Only available when authenticated, entitled access and when the authenticated user is verified
   * and not banned.
   * @deprecated Use `launchMeetingPrivateUrl` and `launchMeetingPublicUrl` accordingly instead
   */
  launchMeetingUrl?: Maybe<Scalars['String']>
  livenessType: LivenessType
  lobbyType: LobbyType
  nonStreamableReason?: Maybe<NonStreamableReason>
  recording?: Maybe<Recording>
  seatBooked?: Maybe<Scalars['Boolean']>
  seatCapacity: Scalars['Int']
  seatsAvailable: Scalars['Int']
  startTimeUTC: Scalars['String']
  stream?: Maybe<LiveStream>
  /** @deprecated Use `class` instead */
  timekitProjectId: Scalars['ID']
  /** @deprecated Use `id` instead */
  timekitSlotId: Scalars['ID']
  userHasEntitlement: Scalars['Boolean']
  /**
   * The Zoom meeting ID for this particular class Slot. Only available when authenticated.
   * @deprecated Use `launchMeetingPrivateUrl` and `launchMeetingPublicUrl` accordingly instead
   */
  zoomMeetingId?: Maybe<Scalars['String']>
}

export type SlotRecommendation = {
  __typename: 'SlotRecommendation'
  /** @deprecated send a recommendation event instead */
  feedback: Scalars['String']
  slot: Slot
}

export type SlotRecommendationRequest = {
  categories?: Maybe<Array<Scalars['String']>>
  embeddingOrgId?: Maybe<Scalars['String']>
  numberOfRecommendations?: Maybe<Scalars['Int']>
  startTimeFrom?: Maybe<Scalars['UTCTimestamp']>
}

export type SlotRecommendations = {
  __typename: 'SlotRecommendations'
  slots: Array<SlotRecommendation>
}

export type Snippet = {
  __typename: 'Snippet'
  content: Scalars['String']
  primaryAction?: Maybe<UrlAction>
  secondaryAction?: Maybe<UrlAction>
  title?: Maybe<Scalars['String']>
}

export type SnippetPageSection = PageSection & {
  __typename: 'SnippetPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<Snippet>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type Sponsor = {
  __typename: 'Sponsor'
  description: Scalars['String']
  endDate?: Maybe<Scalars['UTCTimestamp']>
  id: Scalars['ID']
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  startDate?: Maybe<Scalars['UTCTimestamp']>
}

/** Sponsored section */
export type SponsorInfoContent = {
  __typename: 'SponsorInfoContent'
  description: Scalars['String']
  endDate?: Maybe<Scalars['UTCTimestamp']>
  id: Scalars['ID']
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  startDate?: Maybe<Scalars['UTCTimestamp']>
}

export type SponsorInfoPageSection = PageSection & {
  __typename: 'SponsorInfoPageSection'
  catalogueLanguage: Scalars['String']
  content: SponsorInfoContent
  id: Scalars['ID']
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type StaffProduct = ProductBase & {
  __typename: 'StaffProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type StartingSoonPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'StartingSoonPageSection'
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type Tag = {
  __typename: 'Tag'
  value: Scalars['String']
}

export type TagsPageSection = PageSection & {
  __typename: 'TagsPageSection'
  catalogueLanguage: Scalars['String']
  id: Scalars['ID']
  items: Array<Tag>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type TestimonialCard = Card & {
  __typename: 'TestimonialCard'
  content: Scalars['String']
  emphasised?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  subtitle?: Maybe<Scalars['String']>
  title: Scalars['String']
}

/** Testimonials */
export type TestimonialsPageSection = PageSection &
  PageSectionWithNavigation & {
    __typename: 'TestimonialsPageSection'
    id: Scalars['ID']
    items: Array<Maybe<TestimonialCard>>
    navigationActions: Array<SectionNavigationAction>
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type TextContent = {
  __typename: 'TextContent'
  html?: Maybe<Scalars['String']>
  text: Scalars['String']
}

export type TrialProduct = ProductBase & {
  __typename: 'TrialProduct'
  id: Scalars['ID']
  name: Scalars['String']
}

export type UnLockedAccessDisplay = {
  __typename: 'UnLockedAccessDisplay'
  _?: Maybe<Scalars['Boolean']>
}

export type UnbanUserInput = {
  notes?: Maybe<Scalars['String']>
  recordedBy: Scalars['String']
  userId: Scalars['String']
}

export type UnbanUserOutput = {
  __typename: 'UnbanUserOutput'
  success: Scalars['Boolean']
}

/** Upcoming sessions for a class */
export type UpcomingPageSection = PageSection &
  PageSectionWithNavigation &
  PaginatedList & {
    __typename: 'UpcomingPageSection'
    classId: Scalars['String']
    displayPrice?: Maybe<DisplayPrice>
    displayPriceDecoration?: Maybe<DisplayPriceDecorationType>
    id: Scalars['ID']
    items: Array<SessionCard>
    navigationActions: Array<SectionNavigationAction>
    pageInfo: PageInfo
    subtitle?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

export type UrlAction = {
  __typename: 'UrlAction'
  label: Scalars['String']
  url: Scalars['String']
}

export type UserStats = {
  __typename: 'UserStats'
  activeDays: Scalars['Int']
  formattedActiveDays: Scalars['String']
  formattedTotalBookings: Scalars['String']
  formattedTotalMinutes: Scalars['String']
  isActiveUser: Scalars['Boolean']
  isNewUser: Scalars['Boolean']
  totalBookings: Scalars['Int']
  totalMinutes: Scalars['Int']
}

export type ValidateAutoLoginLinkInput = {
  code: Scalars['String']
}

export type ValidateAutoLoginLinkOutput = {
  __typename: 'ValidateAutoLoginLinkOutput'
  message: Scalars['String']
  redirectTo?: Maybe<Scalars['String']>
}

export type Video = {
  __typename: 'Video'
  id: Scalars['String']
  originalSource: OriginalVideoSource
  standard: VideoStandard
  status: VideoStatus
  streams: VideoStreams
  type: VideoType
}

export type VideoAsset = {
  __typename: 'VideoAsset'
  dash?: Maybe<Scalars['String']>
  hls?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type VideoSession = {
  __typename: 'VideoSession'
  video: Video
}

export enum VideoStandard {
  Edited = 'Edited',
  Produced = 'Produced',
  Recorded = 'Recorded',
}

export enum VideoStatus {
  Ready = 'Ready',
  Unknown = 'Unknown',
}

export type VideoStreams = {
  __typename: 'VideoStreams'
  Dash?: Maybe<Scalars['String']>
  HLS?: Maybe<Scalars['String']>
}

export enum VideoType {
  Recording = 'Recording',
  Unknown = 'Unknown',
}

export type ViewClassDetailCardAction = CardAction & {
  __typename: 'ViewClassDetailCardAction'
  classId: Scalars['String']
  classSlug?: Maybe<Scalars['String']>
  label: Scalars['String']
  url: Scalars['String']
}

export type WalkthroughCard = Card & {
  __typename: 'WalkthroughCard'
  actionLabel: Scalars['String']
  id: Scalars['ID']
  imageUrl: Scalars['String']
  subtitle: Scalars['String']
  title: Scalars['String']
}

/** Walkthrough screen */
export type WalkthroughPageSection = PageSection & {
  __typename: 'WalkthroughPageSection'
  id: Scalars['ID']
  items: Array<Maybe<WalkthroughCard>>
  subtitle?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type ResolverTypeWrapper<T> = Promise<T> | T

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>
}
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>

export type NextResolverFn<T> = () => Promise<T>

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccessMessageContent: ResolverTypeWrapper<
    Omit<AccessMessageContent, 'primaryAction'> & {
      primaryAction?: Maybe<ResolversTypes['AccessMessagePrimaryAction']>
    }
  >
  AccessMessagePrimaryAction: ResolversTypes['DialogAction'] | ResolversTypes['UrlAction']
  AccessMessageSection: ResolverTypeWrapper<AccessMessageSection>
  AolCategoryPillsSection: ResolverTypeWrapper<AolCategoryPillsSection>
  AolCategoryPillsSectionNavigationAction: ResolverTypeWrapper<AolCategoryPillsSectionNavigationAction>
  AolHubHeroContent: ResolverTypeWrapper<AolHubHeroContent>
  AolHubHeroContentHeading: ResolverTypeWrapper<AolHubHeroContentHeading>
  AolHubHeroSection: ResolverTypeWrapper<AolHubHeroSection>
  AppInfo: ResolverTypeWrapper<AppInfo>
  AttendedSessionsPage: ResolverTypeWrapper<
    Omit<AttendedSessionsPage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }
  >
  AttendedSessionsPageInput: AttendedSessionsPageInput
  AttendedSessionsPageSection: ResolverTypeWrapper<AttendedSessionsPageSection>
  AuthenticatedUser: ResolverTypeWrapper<AuthenticatedUser>
  BanUserInput: BanUserInput
  BanUserOutput: ResolverTypeWrapper<BanUserOutput>
  BannerImageUrl: ResolverTypeWrapper<BannerImageUrl>
  BookMultipleSessionsSection: ResolverTypeWrapper<BookMultipleSessionsSection>
  BookSessionCardAction: ResolverTypeWrapper<BookSessionCardAction>
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>
  CTAPageSection: ResolverTypeWrapper<CtaPageSection>
  CacheControlScope: CacheControlScope
  CallRequiredActionResult: ResolverTypeWrapper<CallRequiredActionResult>
  Callout: ResolverTypeWrapper<Omit<Callout, 'content'> & { content?: Maybe<Array<ResolversTypes['RichText']>> }>
  CancelSessionBookingCardAction: ResolverTypeWrapper<CancelSessionBookingCardAction>
  CancelSessionBookingResult: ResolverTypeWrapper<CancelSessionBookingResult>
  Card:
    | ResolversTypes['CategoryCard']
    | ResolversTypes['ClassCard']
    | ResolversTypes['GuideCard']
    | ResolversTypes['SeriesCard']
    | ResolversTypes['SessionCard']
    | ResolversTypes['TestimonialCard']
    | ResolversTypes['WalkthroughCard']
  CardAction:
    | ResolversTypes['BookSessionCardAction']
    | ResolversTypes['CancelSessionBookingCardAction']
    | ResolversTypes['GetAccessSessionCardAction']
    | ResolversTypes['JoinBookedSessionCardAction']
    | ResolversTypes['JoinLiveSessionCardAction']
    | ResolversTypes['MustAuthenticateUserCardAction']
    | ResolversTypes['ViewClassDetailCardAction']
  Category: ResolverTypeWrapper<Category>
  CategoryCard: ResolverTypeWrapper<CategoryCard>
  CategoryClassesSection: ResolverTypeWrapper<CategoryClassesSection>
  CategoryIndexPageSection: ResolverTypeWrapper<CategoryIndexPageSection>
  CategoryInfoContent: ResolverTypeWrapper<CategoryInfoContent>
  CategoryInfoSection: ResolverTypeWrapper<CategoryInfoSection>
  CategoryRecommendation: ResolverTypeWrapper<CategoryRecommendation>
  CategoryRecommendationRequest: CategoryRecommendationRequest
  CategoryRecommendations: ResolverTypeWrapper<CategoryRecommendations>
  CategorySessionsSection: ResolverTypeWrapper<CategorySessionsSection>
  Class: ResolverTypeWrapper<Class>
  ClassCard: ResolverTypeWrapper<ClassCard>
  ClassDetailsAccessDisplay: ResolversTypes['LockedAccessDisplay'] | ResolversTypes['UnLockedAccessDisplay']
  ClassSchema: ResolverTypeWrapper<ClassSchema>
  ClassSummaryContent: ResolverTypeWrapper<ClassSummaryContent>
  ClassSummaryPageSection: ResolverTypeWrapper<ClassSummaryPageSection>
  CommunityBannerCard: ResolverTypeWrapper<CommunityBannerCard>
  CommunityBannersSection: ResolverTypeWrapper<CommunityBannersSection>
  CompanyInfoBlock: ResolverTypeWrapper<CompanyInfoBlock>
  CompanyInfoPageSection: ResolverTypeWrapper<CompanyInfoPageSection>
  CompanyStat: ResolverTypeWrapper<CompanyStat>
  ComplimentaryProduct: ResolverTypeWrapper<ComplimentaryProduct>
  ContentPageSection: ResolverTypeWrapper<ContentPageSection>
  CreateFeedbackInput: CreateFeedbackInput
  CreateFeedbackResult: ResolverTypeWrapper<CreateFeedbackResult>
  CurrentUser: ResolverTypeWrapper<CurrentUser>
  DemeritReason: DemeritReason
  Dialog: ResolverTypeWrapper<
    Omit<Dialog, 'primaryAction' | 'secondaryAction'> & {
      primaryAction?: Maybe<ResolversTypes['DialogActions']>
      secondaryAction?: Maybe<ResolversTypes['DialogActions']>
    }
  >
  DialogAction: ResolverTypeWrapper<DialogAction>
  DialogActions:
    | ResolversTypes['DialogConfirmationAction']
    | ResolversTypes['DialogDismissAction']
    | ResolversTypes['DialogFacebookConnectAction']
    | ResolversTypes['DialogPageAction']
    | ResolversTypes['DialogUrlAction']
  DialogConfirmationAction: ResolverTypeWrapper<DialogConfirmationAction>
  DialogDismissAction: ResolverTypeWrapper<DialogDismissAction>
  DialogFacebookConnectAction: ResolverTypeWrapper<DialogFacebookConnectAction>
  DialogPageAction: ResolverTypeWrapper<DialogPageAction>
  DialogUrlAction: ResolverTypeWrapper<DialogUrlAction>
  DisplayPrice: ResolverTypeWrapper<DisplayPrice>
  DisplayPriceDecorationType: DisplayPriceDecorationType
  EmbedCategoryPillsSection: ResolverTypeWrapper<EmbedCategoryPillsSection>
  EmbedCategoryPillsSectionNavigationAction: ResolverTypeWrapper<EmbedCategoryPillsSectionNavigationAction>
  EmbedHubHeroContent: ResolverTypeWrapper<EmbedHubHeroContent>
  EmbedHubHeroContentHeading: ResolverTypeWrapper<EmbedHubHeroContentHeading>
  EmbedHubHeroSection: ResolverTypeWrapper<EmbedHubHeroSection>
  EmbedLiveAndUpcomingSection: ResolverTypeWrapper<EmbedLiveAndUpcomingSection>
  EmbedTrendingSection: ResolverTypeWrapper<EmbedTrendingSection>
  Entitlement: ResolverTypeWrapper<Entitlement>
  EntitlementCard: ResolverTypeWrapper<
    Omit<EntitlementCard, 'primaryAction'> & { primaryAction?: Maybe<ResolversTypes['DialogActions']> }
  >
  EntitlementsPage: ResolverTypeWrapper<EntitlementsPage>
  EntitlementsSection: ResolverTypeWrapper<EntitlementsSection>
  EntitlementsSectionInput: EntitlementsSectionInput
  FacetCount: ResolverTypeWrapper<FacetCount>
  FacetCounts: ResolverTypeWrapper<FacetCounts>
  FacetInfo: ResolverTypeWrapper<FacetInfo>
  FacetedList: ResolversTypes['SchedulePageSection']
  Feature: ResolverTypeWrapper<Feature>
  FeaturedClassesSection: ResolverTypeWrapper<FeaturedClassesSection>
  FeaturedLogo: ResolverTypeWrapper<FeaturedLogo>
  FeaturedSessionsSection: ResolverTypeWrapper<FeaturedSessionsSection>
  Feedback: ResolverTypeWrapper<Feedback>
  FeedbackInput: FeedbackInput
  FilterVideoInput: FilterVideoInput
  Float: ResolverTypeWrapper<Scalars['Float']>
  GetAccessSessionCardAction: ResolverTypeWrapper<GetAccessSessionCardAction>
  GetVideoInput: GetVideoInput
  GetsetupInfo: ResolverTypeWrapper<GetsetupInfo>
  GrandfatheredProduct: ResolverTypeWrapper<GrandfatheredProduct>
  Guide: ResolverTypeWrapper<Guide>
  GuideCard: ResolverTypeWrapper<GuideCard>
  GuideClassContent: ResolverTypeWrapper<GuideClassContent>
  GuideClassPageSection: ResolverTypeWrapper<GuideClassPageSection>
  GuideClassesPageSection: ResolverTypeWrapper<GuideClassesPageSection>
  GuideIndexInfoContent: ResolverTypeWrapper<GuideIndexInfoContent>
  GuideIndexInfoPageSection: ResolverTypeWrapper<GuideIndexInfoPageSection>
  GuideIndexPage: ResolverTypeWrapper<
    Omit<GuideIndexPage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }
  >
  GuideInfoContent: ResolverTypeWrapper<GuideInfoContent>
  GuideInfoPageSection: ResolverTypeWrapper<GuideInfoPageSection>
  GuidePageSection: ResolverTypeWrapper<GuidePageSection>
  GuideProduct: ResolverTypeWrapper<GuideProduct>
  GuideSessionsPageSection: ResolverTypeWrapper<GuideSessionsPageSection>
  HelpInfo: ResolverTypeWrapper<HelpInfo>
  HelpInfoPageSection: ResolverTypeWrapper<HelpInfoPageSection>
  HeroInfoContent: ResolverTypeWrapper<HeroInfoContent>
  HeroInfoContentVideo: ResolverTypeWrapper<HeroInfoContentVideo>
  HeroInfoNavigationAction: ResolverTypeWrapper<HeroInfoNavigationAction>
  HeroInfoPrimaryAction: ResolverTypeWrapper<HeroInfoPrimaryAction>
  HeroInfoSection: ResolverTypeWrapper<HeroInfoSection>
  HomeSchema: ResolverTypeWrapper<HomeSchema>
  ID: ResolverTypeWrapper<Scalars['ID']>
  Image: ResolverTypeWrapper<Image>
  ImagePageSection: ResolverTypeWrapper<
    Omit<ImagePageSection, 'accessDisplay'> & { accessDisplay: ResolversTypes['ClassDetailsAccessDisplay'] }
  >
  Int: ResolverTypeWrapper<Scalars['Int']>
  JoinBookedSessionCardAction: ResolverTypeWrapper<JoinBookedSessionCardAction>
  JoinLiveSessionCardAction: ResolverTypeWrapper<JoinLiveSessionCardAction>
  LiveAndUpcomingClassesPageSection: ResolverTypeWrapper<LiveAndUpcomingClassesPageSection>
  LiveGuidesSection: ResolverTypeWrapper<LiveGuidesSection>
  LiveNowPageSection: ResolverTypeWrapper<LiveNowPageSection>
  LiveStream: ResolverTypeWrapper<LiveStream>
  LivenessType: LivenessType
  LobbySession: ResolverTypeWrapper<LobbySession>
  LobbySessionInput: LobbySessionInput
  LobbyType: LobbyType
  LocationBase: ResolverTypeWrapper<LocationBase>
  LockedAccessDisplay: ResolverTypeWrapper<LockedAccessDisplay>
  MetaInfo: ResolverTypeWrapper<Omit<MetaInfo, 'jsonLd'> & { jsonLd?: Maybe<ResolversTypes['MetaInfoSchema']> }>
  MetaInfoSchema: ResolversTypes['ClassSchema'] | ResolversTypes['HomeSchema'] | ResolversTypes['SessionSchema']
  MicroLearningShortsSection: ResolverTypeWrapper<MicroLearningShortsSection>
  MicroShortCard: ResolverTypeWrapper<MicroShortCard>
  MostPopularPageSection: ResolverTypeWrapper<MostPopularPageSection>
  MustAuthenticateUserCardAction: ResolverTypeWrapper<MustAuthenticateUserCardAction>
  Mutation: ResolverTypeWrapper<{}>
  NavigateBackButtonAction: ResolverTypeWrapper<NavigateBackButtonAction>
  NavigateToAttendedSessionDetailAction: ResolverTypeWrapper<NavigateToAttendedSessionDetailAction>
  NavigateToClassDetailAction: ResolverTypeWrapper<NavigateToClassDetailAction>
  NavigateToGuideAction: ResolverTypeWrapper<NavigateToGuideAction>
  NavigateToSeriesAction: ResolverTypeWrapper<NavigateToSeriesAction>
  NavigationAction:
    | ResolversTypes['HeroInfoNavigationAction']
    | ResolversTypes['NavigateBackButtonAction']
    | ResolversTypes['NavigateToAttendedSessionDetailAction']
    | ResolversTypes['NavigateToClassDetailAction']
    | ResolversTypes['NavigateToGuideAction']
    | ResolversTypes['NavigateToSeriesAction']
    | ResolversTypes['PartnerInfoNavigationAction']
  NextSessionSection: ResolverTypeWrapper<NextSessionSection>
  NonRecurringPrice: ResolverTypeWrapper<NonRecurringPrice>
  NonStreamableReason: NonStreamableReason
  OnLoadAction: ResolversTypes['Dialog']
  OriginalVideoSource: OriginalVideoSource
  Page: ResolverTypeWrapper<Omit<Page, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }>
  PageInfo: ResolverTypeWrapper<PageInfo>
  PageSection:
    | ResolversTypes['AccessMessageSection']
    | ResolversTypes['AolCategoryPillsSection']
    | ResolversTypes['AolHubHeroSection']
    | ResolversTypes['AttendedSessionsPageSection']
    | ResolversTypes['BookMultipleSessionsSection']
    | ResolversTypes['CTAPageSection']
    | ResolversTypes['CategoryClassesSection']
    | ResolversTypes['CategoryIndexPageSection']
    | ResolversTypes['CategoryInfoSection']
    | ResolversTypes['CategorySessionsSection']
    | ResolversTypes['ClassSummaryPageSection']
    | ResolversTypes['CommunityBannersSection']
    | ResolversTypes['CompanyInfoPageSection']
    | ResolversTypes['ContentPageSection']
    | ResolversTypes['EmbedCategoryPillsSection']
    | ResolversTypes['EmbedHubHeroSection']
    | ResolversTypes['EmbedLiveAndUpcomingSection']
    | ResolversTypes['EmbedTrendingSection']
    | ResolversTypes['FeaturedClassesSection']
    | ResolversTypes['FeaturedSessionsSection']
    | ResolversTypes['GuideClassPageSection']
    | ResolversTypes['GuideClassesPageSection']
    | ResolversTypes['GuideIndexInfoPageSection']
    | ResolversTypes['GuideInfoPageSection']
    | ResolversTypes['GuidePageSection']
    | ResolversTypes['GuideSessionsPageSection']
    | ResolversTypes['HelpInfoPageSection']
    | ResolversTypes['HeroInfoSection']
    | ResolversTypes['ImagePageSection']
    | ResolversTypes['LiveAndUpcomingClassesPageSection']
    | ResolversTypes['LiveGuidesSection']
    | ResolversTypes['LiveNowPageSection']
    | ResolversTypes['MicroLearningShortsSection']
    | ResolversTypes['MostPopularPageSection']
    | ResolversTypes['NextSessionSection']
    | ResolversTypes['PartnerInfoPageSection']
    | ResolversTypes['PartnershipBannerSection']
    | ResolversTypes['PopularCategoriesSection']
    | ResolversTypes['PopularSeriesSection']
    | ResolversTypes['PricingPageSection']
    | ResolversTypes['ReviewPageSection']
    | ResolversTypes['SchedulePageSection']
    | ResolversTypes['SearchPageSection']
    | ResolversTypes['SectionGroupPageSection']
    | ResolversTypes['SeriesClassesPageSection']
    | ResolversTypes['SeriesClassesRowSection']
    | ResolversTypes['SeriesIndexInfoPageSection']
    | ResolversTypes['SeriesInfoPageSection']
    | ResolversTypes['SeriesPageSection']
    | ResolversTypes['SessionNotesDetailsPageSection']
    | ResolversTypes['SessionNotesInfoPageSection']
    | ResolversTypes['SimilarClassesPageSection']
    | ResolversTypes['SnippetPageSection']
    | ResolversTypes['SponsorInfoPageSection']
    | ResolversTypes['StartingSoonPageSection']
    | ResolversTypes['TagsPageSection']
    | ResolversTypes['TestimonialsPageSection']
    | ResolversTypes['UpcomingPageSection']
    | ResolversTypes['WalkthroughPageSection']
  PageSectionUnion:
    | ResolversTypes['AccessMessageSection']
    | ResolversTypes['AolCategoryPillsSection']
    | ResolversTypes['AolHubHeroSection']
    | ResolversTypes['AttendedSessionsPageSection']
    | ResolversTypes['BookMultipleSessionsSection']
    | ResolversTypes['CTAPageSection']
    | ResolversTypes['CategoryClassesSection']
    | ResolversTypes['CategoryIndexPageSection']
    | ResolversTypes['CategoryInfoSection']
    | ResolversTypes['CategorySessionsSection']
    | ResolversTypes['ClassSummaryPageSection']
    | ResolversTypes['CommunityBannersSection']
    | ResolversTypes['CompanyInfoPageSection']
    | ResolversTypes['ContentPageSection']
    | ResolversTypes['EmbedCategoryPillsSection']
    | ResolversTypes['EmbedHubHeroSection']
    | ResolversTypes['EmbedLiveAndUpcomingSection']
    | ResolversTypes['EmbedTrendingSection']
    | ResolversTypes['FeaturedClassesSection']
    | ResolversTypes['FeaturedSessionsSection']
    | ResolversTypes['GuideClassPageSection']
    | ResolversTypes['GuideClassesPageSection']
    | ResolversTypes['GuideIndexInfoPageSection']
    | ResolversTypes['GuideInfoPageSection']
    | ResolversTypes['GuidePageSection']
    | ResolversTypes['GuideSessionsPageSection']
    | ResolversTypes['HelpInfoPageSection']
    | ResolversTypes['HeroInfoSection']
    | ResolversTypes['ImagePageSection']
    | ResolversTypes['LiveAndUpcomingClassesPageSection']
    | ResolversTypes['LiveGuidesSection']
    | ResolversTypes['LiveNowPageSection']
    | ResolversTypes['MicroLearningShortsSection']
    | ResolversTypes['MostPopularPageSection']
    | ResolversTypes['NextSessionSection']
    | ResolversTypes['PartnerInfoPageSection']
    | ResolversTypes['PartnershipBannerSection']
    | ResolversTypes['PopularCategoriesSection']
    | ResolversTypes['PopularSeriesSection']
    | ResolversTypes['PricingPageSection']
    | ResolversTypes['ReviewPageSection']
    | ResolversTypes['SchedulePageSection']
    | ResolversTypes['SearchPageSection']
    | ResolversTypes['SectionGroupPageSection']
    | ResolversTypes['SeriesClassesPageSection']
    | ResolversTypes['SeriesClassesRowSection']
    | ResolversTypes['SeriesIndexInfoPageSection']
    | ResolversTypes['SeriesInfoPageSection']
    | ResolversTypes['SeriesPageSection']
    | ResolversTypes['SessionNotesDetailsPageSection']
    | ResolversTypes['SessionNotesInfoPageSection']
    | ResolversTypes['SimilarClassesPageSection']
    | ResolversTypes['SnippetPageSection']
    | ResolversTypes['SponsorInfoPageSection']
    | ResolversTypes['StartingSoonPageSection']
    | ResolversTypes['TagsPageSection']
    | ResolversTypes['TestimonialsPageSection']
    | ResolversTypes['UpcomingPageSection']
    | ResolversTypes['WalkthroughPageSection']
  PageSectionWithNavigation:
    | ResolversTypes['CompanyInfoPageSection']
    | ResolversTypes['ContentPageSection']
    | ResolversTypes['LiveAndUpcomingClassesPageSection']
    | ResolversTypes['LiveGuidesSection']
    | ResolversTypes['LiveNowPageSection']
    | ResolversTypes['MostPopularPageSection']
    | ResolversTypes['PopularCategoriesSection']
    | ResolversTypes['PopularSeriesSection']
    | ResolversTypes['SchedulePageSection']
    | ResolversTypes['SeriesClassesRowSection']
    | ResolversTypes['SimilarClassesPageSection']
    | ResolversTypes['StartingSoonPageSection']
    | ResolversTypes['TestimonialsPageSection']
    | ResolversTypes['UpcomingPageSection']
  PageSections: ResolverTypeWrapper<PageSections>
  PageUnion: ResolversTypes['Page'] | ResolversTypes['SchedulePage']
  PaginatedEntitlements: ResolverTypeWrapper<PaginatedEntitlements>
  PaginatedList:
    | ResolversTypes['AttendedSessionsPageSection']
    | ResolversTypes['EntitlementsSection']
    | ResolversTypes['LiveAndUpcomingClassesPageSection']
    | ResolversTypes['LiveNowPageSection']
    | ResolversTypes['MostPopularPageSection']
    | ResolversTypes['PaginatedEntitlements']
    | ResolversTypes['ReviewPageSection']
    | ResolversTypes['SchedulePageSection']
    | ResolversTypes['SearchPageSection']
    | ResolversTypes['SimilarClassesPageSection']
    | ResolversTypes['StartingSoonPageSection']
    | ResolversTypes['UpcomingPageSection']
  Partner: ResolverTypeWrapper<Partner>
  PartnerInfoContent: ResolverTypeWrapper<PartnerInfoContent>
  PartnerInfoContentVideo: ResolverTypeWrapper<PartnerInfoContentVideo>
  PartnerInfoNavigationAction: ResolverTypeWrapper<PartnerInfoNavigationAction>
  PartnerInfoPageSection: ResolverTypeWrapper<PartnerInfoPageSection>
  PartnerInfoPrimaryAction: ResolverTypeWrapper<PartnerInfoPrimaryAction>
  PartnershipBannerContent: ResolverTypeWrapper<PartnershipBannerContent>
  PartnershipBannerImageUrls: ResolverTypeWrapper<PartnershipBannerImageUrls>
  PartnershipBannerSection: ResolverTypeWrapper<PartnershipBannerSection>
  PartnershipProduct: ResolverTypeWrapper<PartnershipProduct>
  PopularCategoriesSection: ResolverTypeWrapper<PopularCategoriesSection>
  PopularSeriesSection: ResolverTypeWrapper<PopularSeriesSection>
  PotentialActionSchema: ResolverTypeWrapper<PotentialActionSchema>
  Price: ResolversTypes['NonRecurringPrice'] | ResolversTypes['RecurringPrice']
  PricingPageSection: ResolverTypeWrapper<PricingPageSection>
  PricingPlanAction: ResolverTypeWrapper<PricingPlanAction>
  PricingPlanActionTarget: PricingPlanActionTarget
  PricingPlanActionType: PricingPlanActionType
  PricingPlanHighlight: ResolverTypeWrapper<PricingPlanHighlight>
  PricingPlanItem: ResolverTypeWrapper<PricingPlanItem>
  PricingPlanPricing: ResolverTypeWrapper<PricingPlanPricing>
  Product:
    | ResolversTypes['ComplimentaryProduct']
    | ResolversTypes['GrandfatheredProduct']
    | ResolversTypes['GuideProduct']
    | ResolversTypes['PartnershipProduct']
    | ResolversTypes['RecurringSubscriptionProduct']
    | ResolversTypes['ScholarshipProduct']
    | ResolversTypes['SingleSessionProduct']
    | ResolversTypes['StaffProduct']
    | ResolversTypes['TrialProduct']
  ProductBase:
    | ResolversTypes['ComplimentaryProduct']
    | ResolversTypes['GrandfatheredProduct']
    | ResolversTypes['GuideProduct']
    | ResolversTypes['PartnershipProduct']
    | ResolversTypes['RecurringSubscriptionProduct']
    | ResolversTypes['ScholarshipProduct']
    | ResolversTypes['SingleSessionProduct']
    | ResolversTypes['StaffProduct']
    | ResolversTypes['TrialProduct']
  Query: ResolverTypeWrapper<{}>
  Recording: ResolverTypeWrapper<Recording>
  RecordingType: RecordingType
  RecurringInterval: RecurringInterval
  RecurringPrice: ResolverTypeWrapper<RecurringPrice>
  RecurringSubscriptionProduct: ResolverTypeWrapper<RecurringSubscriptionProduct>
  Review: ResolverTypeWrapper<Review>
  ReviewPageSection: ResolverTypeWrapper<ReviewPageSection>
  RichText: ResolversTypes['RichTextLinkNode'] | ResolversTypes['RichTextTextNode']
  RichTextDataLinkType: RichTextDataLinkType
  RichTextDataType: RichTextDataType
  RichTextHyperlinkLinkNode: ResolverTypeWrapper<RichTextHyperlinkLinkNode>
  RichTextLinkNode: ResolverTypeWrapper<
    Omit<RichTextLinkNode, 'data'> & { data: ResolversTypes['RichTextLinkNodeDataNode'] }
  >
  RichTextLinkNodeDataNode: ResolversTypes['RichTextHyperlinkLinkNode'] | ResolversTypes['RichTextPageLinkNode']
  RichTextNodeType: RichTextNodeType
  RichTextPageLinkNode: ResolverTypeWrapper<RichTextPageLinkNode>
  RichTextTextNode: ResolverTypeWrapper<RichTextTextNode>
  ScheduleDaySection: ResolverTypeWrapper<ScheduleDaySection>
  SchedulePage: ResolverTypeWrapper<
    Omit<SchedulePage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }
  >
  SchedulePageRequest: SchedulePageRequest
  SchedulePageSection: ResolverTypeWrapper<SchedulePageSection>
  SchemaBase: ResolversTypes['ClassSchema'] | ResolversTypes['HomeSchema'] | ResolversTypes['SessionSchema']
  SchemaProvider: ResolverTypeWrapper<SchemaProvider>
  ScholarshipProduct: ResolverTypeWrapper<ScholarshipProduct>
  SearchClassesFacet: ResolverTypeWrapper<SearchClassesFacet>
  SearchClassesFilter: SearchClassesFilter
  SearchClassesRequest: SearchClassesRequest
  SearchClassesResults: ResolverTypeWrapper<SearchClassesResults>
  SearchPageRequest: SearchPageRequest
  SearchPageSection: ResolverTypeWrapper<SearchPageSection>
  SectionGroupLayoutHint: SectionGroupLayoutHint
  SectionGroupPageSection: ResolverTypeWrapper<SectionGroupPageSection>
  SectionNavigationAction: ResolverTypeWrapper<SectionNavigationAction>
  SeriesCard: ResolverTypeWrapper<SeriesCard>
  SeriesClassesPageSection: ResolverTypeWrapper<SeriesClassesPageSection>
  SeriesClassesRowSection: ResolverTypeWrapper<SeriesClassesRowSection>
  SeriesIndexInfoContent: ResolverTypeWrapper<SeriesIndexInfoContent>
  SeriesIndexInfoPageSection: ResolverTypeWrapper<SeriesIndexInfoPageSection>
  SeriesIndexPage: ResolverTypeWrapper<
    Omit<SeriesIndexPage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }
  >
  SeriesInfoContent: ResolverTypeWrapper<SeriesInfoContent>
  SeriesInfoPageSection: ResolverTypeWrapper<SeriesInfoPageSection>
  SeriesPageSection: ResolverTypeWrapper<SeriesPageSection>
  SessionCard: ResolverTypeWrapper<
    Omit<SessionCard, 'accessDisplay'> & { accessDisplay: ResolversTypes['SessionCardAccessDisplay'] }
  >
  SessionCardAccessDisplay: ResolversTypes['LockedAccessDisplay'] | ResolversTypes['UnLockedAccessDisplay']
  SessionInfoContent: ResolverTypeWrapper<SessionInfoContent>
  SessionLobbyPage: ResolverTypeWrapper<
    Omit<SessionLobbyPage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversTypes['OnLoadAction']> }
  >
  SessionLobbyPageInput: SessionLobbyPageInput
  SessionMetaInfo: ResolverTypeWrapper<SessionMetaInfo>
  SessionNotesAttachment: ResolverTypeWrapper<SessionNotesAttachment>
  SessionNotesDetailsPageSection: ResolverTypeWrapper<SessionNotesDetailsPageSection>
  SessionNotesInfoPageSection: ResolverTypeWrapper<SessionNotesInfoPageSection>
  SessionNotesPageRequest: SessionNotesPageRequest
  SessionSchema: ResolverTypeWrapper<SessionSchema>
  SetPartnerCookieByCodeOutput: ResolverTypeWrapper<SetPartnerCookieByCodeOutput>
  SimilarClassesPageSection: ResolverTypeWrapper<SimilarClassesPageSection>
  SingleSessionProduct: ResolverTypeWrapper<SingleSessionProduct>
  SitemapContent: ResolverTypeWrapper<SitemapContent>
  SitemapUrl: ResolverTypeWrapper<SitemapUrl>
  Slot: ResolverTypeWrapper<Slot>
  SlotRecommendation: ResolverTypeWrapper<SlotRecommendation>
  SlotRecommendationRequest: SlotRecommendationRequest
  SlotRecommendations: ResolverTypeWrapper<SlotRecommendations>
  Snippet: ResolverTypeWrapper<Snippet>
  SnippetPageSection: ResolverTypeWrapper<SnippetPageSection>
  Sponsor: ResolverTypeWrapper<Sponsor>
  SponsorInfoContent: ResolverTypeWrapper<SponsorInfoContent>
  SponsorInfoPageSection: ResolverTypeWrapper<SponsorInfoPageSection>
  StaffProduct: ResolverTypeWrapper<StaffProduct>
  StartingSoonPageSection: ResolverTypeWrapper<StartingSoonPageSection>
  String: ResolverTypeWrapper<Scalars['String']>
  Tag: ResolverTypeWrapper<Tag>
  TagsPageSection: ResolverTypeWrapper<TagsPageSection>
  TestimonialCard: ResolverTypeWrapper<TestimonialCard>
  TestimonialsPageSection: ResolverTypeWrapper<TestimonialsPageSection>
  TextContent: ResolverTypeWrapper<TextContent>
  TrialProduct: ResolverTypeWrapper<TrialProduct>
  UTCTimestamp: ResolverTypeWrapper<Scalars['UTCTimestamp']>
  UnLockedAccessDisplay: ResolverTypeWrapper<UnLockedAccessDisplay>
  UnbanUserInput: UnbanUserInput
  UnbanUserOutput: ResolverTypeWrapper<UnbanUserOutput>
  UpcomingPageSection: ResolverTypeWrapper<UpcomingPageSection>
  UrlAction: ResolverTypeWrapper<UrlAction>
  UserStats: ResolverTypeWrapper<UserStats>
  ValidateAutoLoginLinkInput: ValidateAutoLoginLinkInput
  ValidateAutoLoginLinkOutput: ResolverTypeWrapper<ValidateAutoLoginLinkOutput>
  Video: ResolverTypeWrapper<Video>
  VideoAsset: ResolverTypeWrapper<VideoAsset>
  VideoSession: ResolverTypeWrapper<VideoSession>
  VideoStandard: VideoStandard
  VideoStatus: VideoStatus
  VideoStreams: ResolverTypeWrapper<VideoStreams>
  VideoType: VideoType
  ViewClassDetailCardAction: ResolverTypeWrapper<ViewClassDetailCardAction>
  WalkthroughCard: ResolverTypeWrapper<WalkthroughCard>
  WalkthroughPageSection: ResolverTypeWrapper<WalkthroughPageSection>
}

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccessMessageContent: Omit<AccessMessageContent, 'primaryAction'> & {
    primaryAction?: Maybe<ResolversParentTypes['AccessMessagePrimaryAction']>
  }
  AccessMessagePrimaryAction: ResolversParentTypes['DialogAction'] | ResolversParentTypes['UrlAction']
  AccessMessageSection: AccessMessageSection
  AolCategoryPillsSection: AolCategoryPillsSection
  AolCategoryPillsSectionNavigationAction: AolCategoryPillsSectionNavigationAction
  AolHubHeroContent: AolHubHeroContent
  AolHubHeroContentHeading: AolHubHeroContentHeading
  AolHubHeroSection: AolHubHeroSection
  AppInfo: AppInfo
  AttendedSessionsPage: Omit<AttendedSessionsPage, 'onLoadAction'> & {
    onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']>
  }
  AttendedSessionsPageInput: AttendedSessionsPageInput
  AttendedSessionsPageSection: AttendedSessionsPageSection
  AuthenticatedUser: AuthenticatedUser
  BanUserInput: BanUserInput
  BanUserOutput: BanUserOutput
  BannerImageUrl: BannerImageUrl
  BookMultipleSessionsSection: BookMultipleSessionsSection
  BookSessionCardAction: BookSessionCardAction
  Boolean: Scalars['Boolean']
  CTAPageSection: CtaPageSection
  CallRequiredActionResult: CallRequiredActionResult
  Callout: Omit<Callout, 'content'> & { content?: Maybe<Array<ResolversParentTypes['RichText']>> }
  CancelSessionBookingCardAction: CancelSessionBookingCardAction
  CancelSessionBookingResult: CancelSessionBookingResult
  Card:
    | ResolversParentTypes['CategoryCard']
    | ResolversParentTypes['ClassCard']
    | ResolversParentTypes['GuideCard']
    | ResolversParentTypes['SeriesCard']
    | ResolversParentTypes['SessionCard']
    | ResolversParentTypes['TestimonialCard']
    | ResolversParentTypes['WalkthroughCard']
  CardAction:
    | ResolversParentTypes['BookSessionCardAction']
    | ResolversParentTypes['CancelSessionBookingCardAction']
    | ResolversParentTypes['GetAccessSessionCardAction']
    | ResolversParentTypes['JoinBookedSessionCardAction']
    | ResolversParentTypes['JoinLiveSessionCardAction']
    | ResolversParentTypes['MustAuthenticateUserCardAction']
    | ResolversParentTypes['ViewClassDetailCardAction']
  Category: Category
  CategoryCard: CategoryCard
  CategoryClassesSection: CategoryClassesSection
  CategoryIndexPageSection: CategoryIndexPageSection
  CategoryInfoContent: CategoryInfoContent
  CategoryInfoSection: CategoryInfoSection
  CategoryRecommendation: CategoryRecommendation
  CategoryRecommendationRequest: CategoryRecommendationRequest
  CategoryRecommendations: CategoryRecommendations
  CategorySessionsSection: CategorySessionsSection
  Class: Class
  ClassCard: ClassCard
  ClassDetailsAccessDisplay: ResolversParentTypes['LockedAccessDisplay'] | ResolversParentTypes['UnLockedAccessDisplay']
  ClassSchema: ClassSchema
  ClassSummaryContent: ClassSummaryContent
  ClassSummaryPageSection: ClassSummaryPageSection
  CommunityBannerCard: CommunityBannerCard
  CommunityBannersSection: CommunityBannersSection
  CompanyInfoBlock: CompanyInfoBlock
  CompanyInfoPageSection: CompanyInfoPageSection
  CompanyStat: CompanyStat
  ComplimentaryProduct: ComplimentaryProduct
  ContentPageSection: ContentPageSection
  CreateFeedbackInput: CreateFeedbackInput
  CreateFeedbackResult: CreateFeedbackResult
  CurrentUser: CurrentUser
  Dialog: Omit<Dialog, 'primaryAction' | 'secondaryAction'> & {
    primaryAction?: Maybe<ResolversParentTypes['DialogActions']>
    secondaryAction?: Maybe<ResolversParentTypes['DialogActions']>
  }
  DialogAction: DialogAction
  DialogActions:
    | ResolversParentTypes['DialogConfirmationAction']
    | ResolversParentTypes['DialogDismissAction']
    | ResolversParentTypes['DialogFacebookConnectAction']
    | ResolversParentTypes['DialogPageAction']
    | ResolversParentTypes['DialogUrlAction']
  DialogConfirmationAction: DialogConfirmationAction
  DialogDismissAction: DialogDismissAction
  DialogFacebookConnectAction: DialogFacebookConnectAction
  DialogPageAction: DialogPageAction
  DialogUrlAction: DialogUrlAction
  DisplayPrice: DisplayPrice
  EmbedCategoryPillsSection: EmbedCategoryPillsSection
  EmbedCategoryPillsSectionNavigationAction: EmbedCategoryPillsSectionNavigationAction
  EmbedHubHeroContent: EmbedHubHeroContent
  EmbedHubHeroContentHeading: EmbedHubHeroContentHeading
  EmbedHubHeroSection: EmbedHubHeroSection
  EmbedLiveAndUpcomingSection: EmbedLiveAndUpcomingSection
  EmbedTrendingSection: EmbedTrendingSection
  Entitlement: Entitlement
  EntitlementCard: Omit<EntitlementCard, 'primaryAction'> & {
    primaryAction?: Maybe<ResolversParentTypes['DialogActions']>
  }
  EntitlementsPage: EntitlementsPage
  EntitlementsSection: EntitlementsSection
  EntitlementsSectionInput: EntitlementsSectionInput
  FacetCount: FacetCount
  FacetCounts: FacetCounts
  FacetInfo: FacetInfo
  FacetedList: ResolversParentTypes['SchedulePageSection']
  Feature: Feature
  FeaturedClassesSection: FeaturedClassesSection
  FeaturedLogo: FeaturedLogo
  FeaturedSessionsSection: FeaturedSessionsSection
  Feedback: Feedback
  FeedbackInput: FeedbackInput
  FilterVideoInput: FilterVideoInput
  Float: Scalars['Float']
  GetAccessSessionCardAction: GetAccessSessionCardAction
  GetVideoInput: GetVideoInput
  GetsetupInfo: GetsetupInfo
  GrandfatheredProduct: GrandfatheredProduct
  Guide: Guide
  GuideCard: GuideCard
  GuideClassContent: GuideClassContent
  GuideClassPageSection: GuideClassPageSection
  GuideClassesPageSection: GuideClassesPageSection
  GuideIndexInfoContent: GuideIndexInfoContent
  GuideIndexInfoPageSection: GuideIndexInfoPageSection
  GuideIndexPage: Omit<GuideIndexPage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']> }
  GuideInfoContent: GuideInfoContent
  GuideInfoPageSection: GuideInfoPageSection
  GuidePageSection: GuidePageSection
  GuideProduct: GuideProduct
  GuideSessionsPageSection: GuideSessionsPageSection
  HelpInfo: HelpInfo
  HelpInfoPageSection: HelpInfoPageSection
  HeroInfoContent: HeroInfoContent
  HeroInfoContentVideo: HeroInfoContentVideo
  HeroInfoNavigationAction: HeroInfoNavigationAction
  HeroInfoPrimaryAction: HeroInfoPrimaryAction
  HeroInfoSection: HeroInfoSection
  HomeSchema: HomeSchema
  ID: Scalars['ID']
  Image: Image
  ImagePageSection: Omit<ImagePageSection, 'accessDisplay'> & {
    accessDisplay: ResolversParentTypes['ClassDetailsAccessDisplay']
  }
  Int: Scalars['Int']
  JoinBookedSessionCardAction: JoinBookedSessionCardAction
  JoinLiveSessionCardAction: JoinLiveSessionCardAction
  LiveAndUpcomingClassesPageSection: LiveAndUpcomingClassesPageSection
  LiveGuidesSection: LiveGuidesSection
  LiveNowPageSection: LiveNowPageSection
  LiveStream: LiveStream
  LobbySession: LobbySession
  LobbySessionInput: LobbySessionInput
  LocationBase: LocationBase
  LockedAccessDisplay: LockedAccessDisplay
  MetaInfo: Omit<MetaInfo, 'jsonLd'> & { jsonLd?: Maybe<ResolversParentTypes['MetaInfoSchema']> }
  MetaInfoSchema:
    | ResolversParentTypes['ClassSchema']
    | ResolversParentTypes['HomeSchema']
    | ResolversParentTypes['SessionSchema']
  MicroLearningShortsSection: MicroLearningShortsSection
  MicroShortCard: MicroShortCard
  MostPopularPageSection: MostPopularPageSection
  MustAuthenticateUserCardAction: MustAuthenticateUserCardAction
  Mutation: {}
  NavigateBackButtonAction: NavigateBackButtonAction
  NavigateToAttendedSessionDetailAction: NavigateToAttendedSessionDetailAction
  NavigateToClassDetailAction: NavigateToClassDetailAction
  NavigateToGuideAction: NavigateToGuideAction
  NavigateToSeriesAction: NavigateToSeriesAction
  NavigationAction:
    | ResolversParentTypes['HeroInfoNavigationAction']
    | ResolversParentTypes['NavigateBackButtonAction']
    | ResolversParentTypes['NavigateToAttendedSessionDetailAction']
    | ResolversParentTypes['NavigateToClassDetailAction']
    | ResolversParentTypes['NavigateToGuideAction']
    | ResolversParentTypes['NavigateToSeriesAction']
    | ResolversParentTypes['PartnerInfoNavigationAction']
  NextSessionSection: NextSessionSection
  NonRecurringPrice: NonRecurringPrice
  OnLoadAction: ResolversParentTypes['Dialog']
  Page: Omit<Page, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']> }
  PageInfo: PageInfo
  PageSection:
    | ResolversParentTypes['AccessMessageSection']
    | ResolversParentTypes['AolCategoryPillsSection']
    | ResolversParentTypes['AolHubHeroSection']
    | ResolversParentTypes['AttendedSessionsPageSection']
    | ResolversParentTypes['BookMultipleSessionsSection']
    | ResolversParentTypes['CTAPageSection']
    | ResolversParentTypes['CategoryClassesSection']
    | ResolversParentTypes['CategoryIndexPageSection']
    | ResolversParentTypes['CategoryInfoSection']
    | ResolversParentTypes['CategorySessionsSection']
    | ResolversParentTypes['ClassSummaryPageSection']
    | ResolversParentTypes['CommunityBannersSection']
    | ResolversParentTypes['CompanyInfoPageSection']
    | ResolversParentTypes['ContentPageSection']
    | ResolversParentTypes['EmbedCategoryPillsSection']
    | ResolversParentTypes['EmbedHubHeroSection']
    | ResolversParentTypes['EmbedLiveAndUpcomingSection']
    | ResolversParentTypes['EmbedTrendingSection']
    | ResolversParentTypes['FeaturedClassesSection']
    | ResolversParentTypes['FeaturedSessionsSection']
    | ResolversParentTypes['GuideClassPageSection']
    | ResolversParentTypes['GuideClassesPageSection']
    | ResolversParentTypes['GuideIndexInfoPageSection']
    | ResolversParentTypes['GuideInfoPageSection']
    | ResolversParentTypes['GuidePageSection']
    | ResolversParentTypes['GuideSessionsPageSection']
    | ResolversParentTypes['HelpInfoPageSection']
    | ResolversParentTypes['HeroInfoSection']
    | ResolversParentTypes['ImagePageSection']
    | ResolversParentTypes['LiveAndUpcomingClassesPageSection']
    | ResolversParentTypes['LiveGuidesSection']
    | ResolversParentTypes['LiveNowPageSection']
    | ResolversParentTypes['MicroLearningShortsSection']
    | ResolversParentTypes['MostPopularPageSection']
    | ResolversParentTypes['NextSessionSection']
    | ResolversParentTypes['PartnerInfoPageSection']
    | ResolversParentTypes['PartnershipBannerSection']
    | ResolversParentTypes['PopularCategoriesSection']
    | ResolversParentTypes['PopularSeriesSection']
    | ResolversParentTypes['PricingPageSection']
    | ResolversParentTypes['ReviewPageSection']
    | ResolversParentTypes['SchedulePageSection']
    | ResolversParentTypes['SearchPageSection']
    | ResolversParentTypes['SectionGroupPageSection']
    | ResolversParentTypes['SeriesClassesPageSection']
    | ResolversParentTypes['SeriesClassesRowSection']
    | ResolversParentTypes['SeriesIndexInfoPageSection']
    | ResolversParentTypes['SeriesInfoPageSection']
    | ResolversParentTypes['SeriesPageSection']
    | ResolversParentTypes['SessionNotesDetailsPageSection']
    | ResolversParentTypes['SessionNotesInfoPageSection']
    | ResolversParentTypes['SimilarClassesPageSection']
    | ResolversParentTypes['SnippetPageSection']
    | ResolversParentTypes['SponsorInfoPageSection']
    | ResolversParentTypes['StartingSoonPageSection']
    | ResolversParentTypes['TagsPageSection']
    | ResolversParentTypes['TestimonialsPageSection']
    | ResolversParentTypes['UpcomingPageSection']
    | ResolversParentTypes['WalkthroughPageSection']
  PageSectionUnion:
    | ResolversParentTypes['AccessMessageSection']
    | ResolversParentTypes['AolCategoryPillsSection']
    | ResolversParentTypes['AolHubHeroSection']
    | ResolversParentTypes['AttendedSessionsPageSection']
    | ResolversParentTypes['BookMultipleSessionsSection']
    | ResolversParentTypes['CTAPageSection']
    | ResolversParentTypes['CategoryClassesSection']
    | ResolversParentTypes['CategoryIndexPageSection']
    | ResolversParentTypes['CategoryInfoSection']
    | ResolversParentTypes['CategorySessionsSection']
    | ResolversParentTypes['ClassSummaryPageSection']
    | ResolversParentTypes['CommunityBannersSection']
    | ResolversParentTypes['CompanyInfoPageSection']
    | ResolversParentTypes['ContentPageSection']
    | ResolversParentTypes['EmbedCategoryPillsSection']
    | ResolversParentTypes['EmbedHubHeroSection']
    | ResolversParentTypes['EmbedLiveAndUpcomingSection']
    | ResolversParentTypes['EmbedTrendingSection']
    | ResolversParentTypes['FeaturedClassesSection']
    | ResolversParentTypes['FeaturedSessionsSection']
    | ResolversParentTypes['GuideClassPageSection']
    | ResolversParentTypes['GuideClassesPageSection']
    | ResolversParentTypes['GuideIndexInfoPageSection']
    | ResolversParentTypes['GuideInfoPageSection']
    | ResolversParentTypes['GuidePageSection']
    | ResolversParentTypes['GuideSessionsPageSection']
    | ResolversParentTypes['HelpInfoPageSection']
    | ResolversParentTypes['HeroInfoSection']
    | ResolversParentTypes['ImagePageSection']
    | ResolversParentTypes['LiveAndUpcomingClassesPageSection']
    | ResolversParentTypes['LiveGuidesSection']
    | ResolversParentTypes['LiveNowPageSection']
    | ResolversParentTypes['MicroLearningShortsSection']
    | ResolversParentTypes['MostPopularPageSection']
    | ResolversParentTypes['NextSessionSection']
    | ResolversParentTypes['PartnerInfoPageSection']
    | ResolversParentTypes['PartnershipBannerSection']
    | ResolversParentTypes['PopularCategoriesSection']
    | ResolversParentTypes['PopularSeriesSection']
    | ResolversParentTypes['PricingPageSection']
    | ResolversParentTypes['ReviewPageSection']
    | ResolversParentTypes['SchedulePageSection']
    | ResolversParentTypes['SearchPageSection']
    | ResolversParentTypes['SectionGroupPageSection']
    | ResolversParentTypes['SeriesClassesPageSection']
    | ResolversParentTypes['SeriesClassesRowSection']
    | ResolversParentTypes['SeriesIndexInfoPageSection']
    | ResolversParentTypes['SeriesInfoPageSection']
    | ResolversParentTypes['SeriesPageSection']
    | ResolversParentTypes['SessionNotesDetailsPageSection']
    | ResolversParentTypes['SessionNotesInfoPageSection']
    | ResolversParentTypes['SimilarClassesPageSection']
    | ResolversParentTypes['SnippetPageSection']
    | ResolversParentTypes['SponsorInfoPageSection']
    | ResolversParentTypes['StartingSoonPageSection']
    | ResolversParentTypes['TagsPageSection']
    | ResolversParentTypes['TestimonialsPageSection']
    | ResolversParentTypes['UpcomingPageSection']
    | ResolversParentTypes['WalkthroughPageSection']
  PageSectionWithNavigation:
    | ResolversParentTypes['CompanyInfoPageSection']
    | ResolversParentTypes['ContentPageSection']
    | ResolversParentTypes['LiveAndUpcomingClassesPageSection']
    | ResolversParentTypes['LiveGuidesSection']
    | ResolversParentTypes['LiveNowPageSection']
    | ResolversParentTypes['MostPopularPageSection']
    | ResolversParentTypes['PopularCategoriesSection']
    | ResolversParentTypes['PopularSeriesSection']
    | ResolversParentTypes['SchedulePageSection']
    | ResolversParentTypes['SeriesClassesRowSection']
    | ResolversParentTypes['SimilarClassesPageSection']
    | ResolversParentTypes['StartingSoonPageSection']
    | ResolversParentTypes['TestimonialsPageSection']
    | ResolversParentTypes['UpcomingPageSection']
  PageSections: PageSections
  PageUnion: ResolversParentTypes['Page'] | ResolversParentTypes['SchedulePage']
  PaginatedEntitlements: PaginatedEntitlements
  PaginatedList:
    | ResolversParentTypes['AttendedSessionsPageSection']
    | ResolversParentTypes['EntitlementsSection']
    | ResolversParentTypes['LiveAndUpcomingClassesPageSection']
    | ResolversParentTypes['LiveNowPageSection']
    | ResolversParentTypes['MostPopularPageSection']
    | ResolversParentTypes['PaginatedEntitlements']
    | ResolversParentTypes['ReviewPageSection']
    | ResolversParentTypes['SchedulePageSection']
    | ResolversParentTypes['SearchPageSection']
    | ResolversParentTypes['SimilarClassesPageSection']
    | ResolversParentTypes['StartingSoonPageSection']
    | ResolversParentTypes['UpcomingPageSection']
  Partner: Partner
  PartnerInfoContent: PartnerInfoContent
  PartnerInfoContentVideo: PartnerInfoContentVideo
  PartnerInfoNavigationAction: PartnerInfoNavigationAction
  PartnerInfoPageSection: PartnerInfoPageSection
  PartnerInfoPrimaryAction: PartnerInfoPrimaryAction
  PartnershipBannerContent: PartnershipBannerContent
  PartnershipBannerImageUrls: PartnershipBannerImageUrls
  PartnershipBannerSection: PartnershipBannerSection
  PartnershipProduct: PartnershipProduct
  PopularCategoriesSection: PopularCategoriesSection
  PopularSeriesSection: PopularSeriesSection
  PotentialActionSchema: PotentialActionSchema
  Price: ResolversParentTypes['NonRecurringPrice'] | ResolversParentTypes['RecurringPrice']
  PricingPageSection: PricingPageSection
  PricingPlanAction: PricingPlanAction
  PricingPlanHighlight: PricingPlanHighlight
  PricingPlanItem: PricingPlanItem
  PricingPlanPricing: PricingPlanPricing
  Product:
    | ResolversParentTypes['ComplimentaryProduct']
    | ResolversParentTypes['GrandfatheredProduct']
    | ResolversParentTypes['GuideProduct']
    | ResolversParentTypes['PartnershipProduct']
    | ResolversParentTypes['RecurringSubscriptionProduct']
    | ResolversParentTypes['ScholarshipProduct']
    | ResolversParentTypes['SingleSessionProduct']
    | ResolversParentTypes['StaffProduct']
    | ResolversParentTypes['TrialProduct']
  ProductBase:
    | ResolversParentTypes['ComplimentaryProduct']
    | ResolversParentTypes['GrandfatheredProduct']
    | ResolversParentTypes['GuideProduct']
    | ResolversParentTypes['PartnershipProduct']
    | ResolversParentTypes['RecurringSubscriptionProduct']
    | ResolversParentTypes['ScholarshipProduct']
    | ResolversParentTypes['SingleSessionProduct']
    | ResolversParentTypes['StaffProduct']
    | ResolversParentTypes['TrialProduct']
  Query: {}
  Recording: Recording
  RecurringPrice: RecurringPrice
  RecurringSubscriptionProduct: RecurringSubscriptionProduct
  Review: Review
  ReviewPageSection: ReviewPageSection
  RichText: ResolversParentTypes['RichTextLinkNode'] | ResolversParentTypes['RichTextTextNode']
  RichTextHyperlinkLinkNode: RichTextHyperlinkLinkNode
  RichTextLinkNode: Omit<RichTextLinkNode, 'data'> & { data: ResolversParentTypes['RichTextLinkNodeDataNode'] }
  RichTextLinkNodeDataNode:
    | ResolversParentTypes['RichTextHyperlinkLinkNode']
    | ResolversParentTypes['RichTextPageLinkNode']
  RichTextPageLinkNode: RichTextPageLinkNode
  RichTextTextNode: RichTextTextNode
  ScheduleDaySection: ScheduleDaySection
  SchedulePage: Omit<SchedulePage, 'onLoadAction'> & { onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']> }
  SchedulePageRequest: SchedulePageRequest
  SchedulePageSection: SchedulePageSection
  SchemaBase:
    | ResolversParentTypes['ClassSchema']
    | ResolversParentTypes['HomeSchema']
    | ResolversParentTypes['SessionSchema']
  SchemaProvider: SchemaProvider
  ScholarshipProduct: ScholarshipProduct
  SearchClassesFacet: SearchClassesFacet
  SearchClassesFilter: SearchClassesFilter
  SearchClassesRequest: SearchClassesRequest
  SearchClassesResults: SearchClassesResults
  SearchPageRequest: SearchPageRequest
  SearchPageSection: SearchPageSection
  SectionGroupPageSection: SectionGroupPageSection
  SectionNavigationAction: SectionNavigationAction
  SeriesCard: SeriesCard
  SeriesClassesPageSection: SeriesClassesPageSection
  SeriesClassesRowSection: SeriesClassesRowSection
  SeriesIndexInfoContent: SeriesIndexInfoContent
  SeriesIndexInfoPageSection: SeriesIndexInfoPageSection
  SeriesIndexPage: Omit<SeriesIndexPage, 'onLoadAction'> & {
    onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']>
  }
  SeriesInfoContent: SeriesInfoContent
  SeriesInfoPageSection: SeriesInfoPageSection
  SeriesPageSection: SeriesPageSection
  SessionCard: Omit<SessionCard, 'accessDisplay'> & { accessDisplay: ResolversParentTypes['SessionCardAccessDisplay'] }
  SessionCardAccessDisplay: ResolversParentTypes['LockedAccessDisplay'] | ResolversParentTypes['UnLockedAccessDisplay']
  SessionInfoContent: SessionInfoContent
  SessionLobbyPage: Omit<SessionLobbyPage, 'onLoadAction'> & {
    onLoadAction?: Maybe<ResolversParentTypes['OnLoadAction']>
  }
  SessionLobbyPageInput: SessionLobbyPageInput
  SessionMetaInfo: SessionMetaInfo
  SessionNotesAttachment: SessionNotesAttachment
  SessionNotesDetailsPageSection: SessionNotesDetailsPageSection
  SessionNotesInfoPageSection: SessionNotesInfoPageSection
  SessionNotesPageRequest: SessionNotesPageRequest
  SessionSchema: SessionSchema
  SetPartnerCookieByCodeOutput: SetPartnerCookieByCodeOutput
  SimilarClassesPageSection: SimilarClassesPageSection
  SingleSessionProduct: SingleSessionProduct
  SitemapContent: SitemapContent
  SitemapUrl: SitemapUrl
  Slot: Slot
  SlotRecommendation: SlotRecommendation
  SlotRecommendationRequest: SlotRecommendationRequest
  SlotRecommendations: SlotRecommendations
  Snippet: Snippet
  SnippetPageSection: SnippetPageSection
  Sponsor: Sponsor
  SponsorInfoContent: SponsorInfoContent
  SponsorInfoPageSection: SponsorInfoPageSection
  StaffProduct: StaffProduct
  StartingSoonPageSection: StartingSoonPageSection
  String: Scalars['String']
  Tag: Tag
  TagsPageSection: TagsPageSection
  TestimonialCard: TestimonialCard
  TestimonialsPageSection: TestimonialsPageSection
  TextContent: TextContent
  TrialProduct: TrialProduct
  UTCTimestamp: Scalars['UTCTimestamp']
  UnLockedAccessDisplay: UnLockedAccessDisplay
  UnbanUserInput: UnbanUserInput
  UnbanUserOutput: UnbanUserOutput
  UpcomingPageSection: UpcomingPageSection
  UrlAction: UrlAction
  UserStats: UserStats
  ValidateAutoLoginLinkInput: ValidateAutoLoginLinkInput
  ValidateAutoLoginLinkOutput: ValidateAutoLoginLinkOutput
  Video: Video
  VideoAsset: VideoAsset
  VideoSession: VideoSession
  VideoStreams: VideoStreams
  ViewClassDetailCardAction: ViewClassDetailCardAction
  WalkthroughCard: WalkthroughCard
  WalkthroughPageSection: WalkthroughPageSection
}

export type CacheControlDirectiveArgs = {
  inheritMaxAge?: Maybe<Scalars['Boolean']>
  maxAge?: Maybe<Scalars['Int']>
  scope?: Maybe<CacheControlScope>
}

export type CacheControlDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = CacheControlDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>

export type AccessMessageContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessMessageContent'] = ResolversParentTypes['AccessMessageContent'],
> = {
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['AccessMessagePrimaryAction']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AccessMessagePrimaryActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessMessagePrimaryAction'] = ResolversParentTypes['AccessMessagePrimaryAction'],
> = {
  __resolveType: TypeResolveFn<'DialogAction' | 'UrlAction', ParentType, ContextType>
}

export type AccessMessageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccessMessageSection'] = ResolversParentTypes['AccessMessageSection'],
> = {
  content?: Resolver<ResolversTypes['AccessMessageContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AolCategoryPillsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AolCategoryPillsSection'] = ResolversParentTypes['AolCategoryPillsSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<
    Array<ResolversTypes['AolCategoryPillsSectionNavigationAction']>,
    ParentType,
    ContextType
  >
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AolCategoryPillsSectionNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AolCategoryPillsSectionNavigationAction'] = ResolversParentTypes['AolCategoryPillsSectionNavigationAction'],
> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sectionIdAnchorTarget?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AolHubHeroContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AolHubHeroContent'] = ResolversParentTypes['AolHubHeroContent'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  heading?: Resolver<ResolversTypes['AolHubHeroContentHeading'], ParentType, ContextType>
  session?: Resolver<Maybe<ResolversTypes['SessionCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AolHubHeroContentHeadingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AolHubHeroContentHeading'] = ResolversParentTypes['AolHubHeroContentHeading'],
> = {
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AolHubHeroSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AolHubHeroSection'] = ResolversParentTypes['AolHubHeroSection'],
> = {
  content?: Resolver<ResolversTypes['AolHubHeroContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AppInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AppInfo'] = ResolversParentTypes['AppInfo'],
> = {
  appName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currentVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  mustUpgrade?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttendedSessionsPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttendedSessionsPage'] = ResolversParentTypes['AttendedSessionsPage'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['MetaInfo'], ParentType, ContextType>
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['PageSection']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AttendedSessionsPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AttendedSessionsPageSection'] = ResolversParentTypes['AttendedSessionsPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type AuthenticatedUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthenticatedUser'] = ResolversParentTypes['AuthenticatedUser'],
> = {
  authenticationExpiration?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  entitlements?: Resolver<
    Maybe<ResolversTypes['PaginatedEntitlements']>,
    ParentType,
    ContextType,
    RequireFields<AuthenticatedUserEntitlementsArgs, never>
  >
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isBanned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isVerified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  legacyGuideId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  scopes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BanUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BanUserOutput'] = ResolversParentTypes['BanUserOutput'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BannerImageUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BannerImageUrl'] = ResolversParentTypes['BannerImageUrl'],
> = {
  bannerWithoutText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  large?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  small?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookMultipleSessionsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookMultipleSessionsSection'] = ResolversParentTypes['BookMultipleSessionsSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type BookSessionCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BookSessionCardAction'] = ResolversParentTypes['BookSessionCardAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CtaPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CTAPageSection'] = ResolversParentTypes['CTAPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CallRequiredActionResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CallRequiredActionResult'] = ResolversParentTypes['CallRequiredActionResult'],
> = {
  resultDialog?: Resolver<Maybe<ResolversTypes['Dialog']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CalloutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Callout'] = ResolversParentTypes['Callout'],
> = {
  content?: Resolver<Maybe<Array<ResolversTypes['RichText']>>, ParentType, ContextType>
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CancelSessionBookingCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelSessionBookingCardAction'] = ResolversParentTypes['CancelSessionBookingCardAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CancelSessionBookingResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CancelSessionBookingResult'] = ResolversParentTypes['CancelSessionBookingResult'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card'],
> = {
  __resolveType: TypeResolveFn<
    'CategoryCard' | 'ClassCard' | 'GuideCard' | 'SeriesCard' | 'SessionCard' | 'TestimonialCard' | 'WalkthroughCard',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type CardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CardAction'] = ResolversParentTypes['CardAction'],
> = {
  __resolveType: TypeResolveFn<
    | 'BookSessionCardAction'
    | 'CancelSessionBookingCardAction'
    | 'GetAccessSessionCardAction'
    | 'JoinBookedSessionCardAction'
    | 'JoinLiveSessionCardAction'
    | 'MustAuthenticateUserCardAction'
    | 'ViewClassDetailCardAction',
    ParentType,
    ContextType
  >
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type CategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryCard'] = ResolversParentTypes['CategoryCard'],
> = {
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  numberOfClasses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryClassesSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryClassesSection'] = ResolversParentTypes['CategoryClassesSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryIndexPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryIndexPageSection'] = ResolversParentTypes['CategoryIndexPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['CategoryCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryInfoContent'] = ResolversParentTypes['CategoryInfoContent'],
> = {
  backgroundColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryInfoSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryInfoSection'] = ResolversParentTypes['CategoryInfoSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['CategoryInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryRecommendation'] = ResolversParentTypes['CategoryRecommendation'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategoryRecommendationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategoryRecommendations'] = ResolversParentTypes['CategoryRecommendations'],
> = {
  categories?: Resolver<Array<ResolversTypes['CategoryRecommendation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CategorySessionsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CategorySessionsSection'] = ResolversParentTypes['CategorySessionsSection'],
> = {
  displayAllItems?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Class'] = ResolversParentTypes['Class'],
> = {
  bannerUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cardImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  cardUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>
  categoryId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  descriptionHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  durationText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  labelIds?: Resolver<Maybe<Array<ResolversTypes['Int']>>, ParentType, ContextType>
  labels?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  requirementsHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sponsor?: Resolver<Maybe<ResolversTypes['Sponsor']>, ParentType, ContextType>
  taglineHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  videos?: Resolver<Array<ResolversTypes['Video']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClassCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassCard'] = ResolversParentTypes['ClassCard'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['CardAction']>, ParentType, ContextType>
  secondaryAction?: Resolver<Maybe<ResolversTypes['CardAction']>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  upcomingSession?: Resolver<Maybe<ResolversTypes['SessionCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClassDetailsAccessDisplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassDetailsAccessDisplay'] = ResolversParentTypes['ClassDetailsAccessDisplay'],
> = {
  __resolveType: TypeResolveFn<'LockedAccessDisplay' | 'UnLockedAccessDisplay', ParentType, ContextType>
}

export type ClassSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassSchema'] = ResolversParentTypes['ClassSchema'],
> = {
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  provider?: Resolver<Maybe<ResolversTypes['SchemaProvider']>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClassSummaryContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassSummaryContent'] = ResolversParentTypes['ClassSummaryContent'],
> = {
  durationMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  guideAvatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCommunityLedClass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  navigateToGuideAction?: Resolver<Maybe<ResolversTypes['NavigateToGuideAction']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  reviewCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  tags?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ClassSummaryPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClassSummaryPageSection'] = ResolversParentTypes['ClassSummaryPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['ClassSummaryContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CommunityBannerCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommunityBannerCard'] = ResolversParentTypes['CommunityBannerCard'],
> = {
  bannerImageUrls?: Resolver<ResolversTypes['BannerImageUrl'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CommunityBannersSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CommunityBannersSection'] = ResolversParentTypes['CommunityBannersSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<Maybe<ResolversTypes['CommunityBannerCard']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyInfoBlockResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyInfoBlock'] = ResolversParentTypes['CompanyInfoBlock'],
> = {
  companyStats?: Resolver<Array<ResolversTypes['CompanyStat']>, ParentType, ContextType>
  featuredLogos?: Resolver<Array<ResolversTypes['FeaturedLogo']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyInfoPageSection'] = ResolversParentTypes['CompanyInfoPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['CompanyInfoBlock']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CompanyStatResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CompanyStat'] = ResolversParentTypes['CompanyStat'],
> = {
  icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ComplimentaryProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ComplimentaryProduct'] = ResolversParentTypes['ComplimentaryProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ContentPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContentPageSection'] = ResolversParentTypes['ContentPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['TextContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CreateFeedbackResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CreateFeedbackResult'] = ResolversParentTypes['CreateFeedbackResult'],
> = {
  feedback?: Resolver<ResolversTypes['Feedback'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type CurrentUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrentUser'] = ResolversParentTypes['CurrentUser'],
> = {
  authenticatedUser?: Resolver<Maybe<ResolversTypes['AuthenticatedUser']>, ParentType, ContextType>
  detectedCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  detectedCountryDialingCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  detectedIPAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Dialog'] = ResolversParentTypes['Dialog'],
> = {
  callout?: Resolver<Maybe<ResolversTypes['Callout']>, ParentType, ContextType>
  closable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasActionDivider?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasHelpText?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  lottieJsonUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  partnerLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['DialogActions']>, ParentType, ContextType>
  secondaryAction?: Resolver<Maybe<ResolversTypes['DialogActions']>, ParentType, ContextType>
  supplementaryText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  videoPosterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogAction'] = ResolversParentTypes['DialogAction'],
> = {
  dialogId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogActionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogActions'] = ResolversParentTypes['DialogActions'],
> = {
  __resolveType: TypeResolveFn<
    | 'DialogConfirmationAction'
    | 'DialogDismissAction'
    | 'DialogFacebookConnectAction'
    | 'DialogPageAction'
    | 'DialogUrlAction',
    ParentType,
    ContextType
  >
}

export type DialogConfirmationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogConfirmationAction'] = ResolversParentTypes['DialogConfirmationAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  requiredAction?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogDismissActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogDismissAction'] = ResolversParentTypes['DialogDismissAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogFacebookConnectActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogFacebookConnectAction'] = ResolversParentTypes['DialogFacebookConnectAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogPageActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogPageAction'] = ResolversParentTypes['DialogPageAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  targetPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DialogUrlActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DialogUrlAction'] = ResolversParentTypes['DialogUrlAction'],
> = {
  configUrlsSuffix?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type DisplayPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DisplayPrice'] = ResolversParentTypes['DisplayPrice'],
> = {
  amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  currencySymbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedCategoryPillsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedCategoryPillsSection'] = ResolversParentTypes['EmbedCategoryPillsSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<
    Array<ResolversTypes['EmbedCategoryPillsSectionNavigationAction']>,
    ParentType,
    ContextType
  >
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedCategoryPillsSectionNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedCategoryPillsSectionNavigationAction'] = ResolversParentTypes['EmbedCategoryPillsSectionNavigationAction'],
> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sectionIdAnchorTarget?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedHubHeroContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedHubHeroContent'] = ResolversParentTypes['EmbedHubHeroContent'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  heading?: Resolver<ResolversTypes['EmbedHubHeroContentHeading'], ParentType, ContextType>
  session?: Resolver<Maybe<ResolversTypes['SessionCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedHubHeroContentHeadingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedHubHeroContentHeading'] = ResolversParentTypes['EmbedHubHeroContentHeading'],
> = {
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedHubHeroSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedHubHeroSection'] = ResolversParentTypes['EmbedHubHeroSection'],
> = {
  content?: Resolver<ResolversTypes['EmbedHubHeroContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedLiveAndUpcomingSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedLiveAndUpcomingSection'] = ResolversParentTypes['EmbedLiveAndUpcomingSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EmbedTrendingSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EmbedTrendingSection'] = ResolversParentTypes['EmbedTrendingSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntitlementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Entitlement'] = ResolversParentTypes['Entitlement'],
> = {
  activeWithinValidityPeriod?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  createdAt?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  externalReferenceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  price?: Resolver<Maybe<ResolversTypes['Price']>, ParentType, ContextType>
  product?: Resolver<ResolversTypes['ProductBase'], ParentType, ContextType>
  userBillingCountryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  validFrom?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  validTo?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntitlementCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementCard'] = ResolversParentTypes['EntitlementCard'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  entitlementId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['DialogActions']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  validity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntitlementsPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementsPage'] = ResolversParentTypes['EntitlementsPage'],
> = {
  currentEntitlementsSection?: Resolver<Maybe<ResolversTypes['EntitlementsSection']>, ParentType, ContextType>
  previousEntitlementsSection?: Resolver<Maybe<ResolversTypes['EntitlementsSection']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type EntitlementsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitlementsSection'] = ResolversParentTypes['EntitlementsSection'],
> = {
  items?: Resolver<Maybe<Array<ResolversTypes['EntitlementCard']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FacetCountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacetCount'] = ResolversParentTypes['FacetCount'],
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FacetCountsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacetCounts'] = ResolversParentTypes['FacetCounts'],
> = {
  categories?: Resolver<Array<ResolversTypes['FacetCount']>, ParentType, ContextType>
  guides?: Resolver<Array<ResolversTypes['FacetCount']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FacetInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacetInfo'] = ResolversParentTypes['FacetInfo'],
> = {
  facetsAvailable?: Resolver<ResolversTypes['FacetCounts'], ParentType, ContextType>
  facetsUsed?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FacetedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FacetedList'] = ResolversParentTypes['FacetedList'],
> = {
  __resolveType: TypeResolveFn<'SchedulePageSection', ParentType, ContextType>
  facetInfo?: Resolver<Maybe<ResolversTypes['FacetInfo']>, ParentType, ContextType>
}

export type FeatureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Feature'] = ResolversParentTypes['Feature'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeaturedClassesSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedClassesSection'] = ResolversParentTypes['FeaturedClassesSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ClassCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeaturedLogoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedLogo'] = ResolversParentTypes['FeaturedLogo'],
> = {
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeaturedSessionsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FeaturedSessionsSection'] = ResolversParentTypes['FeaturedSessionsSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type FeedbackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Feedback'] = ResolversParentTypes['Feedback'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  embeddingOrgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  generalNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  sessionRating?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  sessionScheduledStartTime?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetAccessSessionCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetAccessSessionCardAction'] = ResolversParentTypes['GetAccessSessionCardAction'],
> = {
  dialogType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GetsetupInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetsetupInfo'] = ResolversParentTypes['GetsetupInfo'],
> = {
  customerSupportContactNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  videoGuideWelcome?: Resolver<Maybe<ResolversTypes['VideoAsset']>, ParentType, ContextType>
  videoLoungeTour?: Resolver<Maybe<ResolversTypes['VideoAsset']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GrandfatheredProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GrandfatheredProduct'] = ResolversParentTypes['GrandfatheredProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Guide'] = ResolversParentTypes['Guide'],
> = {
  askMeAbout?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  facebookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  linkedInUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tagline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideCard'] = ResolversParentTypes['GuideCard'],
> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  classCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  countryFlagImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>
  skills?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideClassContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideClassContent'] = ResolversParentTypes['GuideClassContent'],
> = {
  active?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  askMeAbout?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>
  avatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  facebookUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  featured?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  linkedInUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tagline?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  twitterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideClassPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideClassPageSection'] = ResolversParentTypes['GuideClassPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['GuideClassContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideClassesPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideClassesPageSection'] = ResolversParentTypes['GuideClassesPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ClassCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideIndexInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideIndexInfoContent'] = ResolversParentTypes['GuideIndexInfoContent'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideIndexInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideIndexInfoPageSection'] = ResolversParentTypes['GuideIndexInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['GuideIndexInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideIndexPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideIndexPage'] = ResolversParentTypes['GuideIndexPage'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['MetaInfo'], ParentType, ContextType>
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['PageSection']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideInfoContent'] = ResolversParentTypes['GuideInfoContent'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasSessions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideInfoPageSection'] = ResolversParentTypes['GuideInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['GuideInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuidePageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuidePageSection'] = ResolversParentTypes['GuidePageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['GuideCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideProduct'] = ResolversParentTypes['GuideProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type GuideSessionsPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GuideSessionsPageSection'] = ResolversParentTypes['GuideSessionsPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelpInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpInfo'] = ResolversParentTypes['HelpInfo'],
> = {
  primaryAction?: Resolver<Maybe<ResolversTypes['UrlAction']>, ParentType, ContextType>
  secondaryAction?: Resolver<Maybe<ResolversTypes['UrlAction']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HelpInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HelpInfoPageSection'] = ResolversParentTypes['HelpInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['HelpInfo']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroInfoContent'] = ResolversParentTypes['HeroInfoContent'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['HeroInfoNavigationAction']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['HeroInfoPrimaryAction']>, ParentType, ContextType>
  showVideo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userStats?: Resolver<Maybe<ResolversTypes['UserStats']>, ParentType, ContextType>
  video?: Resolver<Maybe<ResolversTypes['HeroInfoContentVideo']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroInfoContentVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroInfoContentVideo'] = ResolversParentTypes['HeroInfoContentVideo'],
> = {
  posterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  srcUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroInfoNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroInfoNavigationAction'] = ResolversParentTypes['HeroInfoNavigationAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroInfoPrimaryActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroInfoPrimaryAction'] = ResolversParentTypes['HeroInfoPrimaryAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HeroInfoSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeroInfoSection'] = ResolversParentTypes['HeroInfoSection'],
> = {
  content?: Resolver<ResolversTypes['HeroInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type HomeSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HomeSchema'] = ResolversParentTypes['HomeSchema'],
> = {
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  potentialAction?: Resolver<Maybe<ResolversTypes['PotentialActionSchema']>, ParentType, ContextType>
  sameAs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image'],
> = {
  altText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ImagePageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImagePageSection'] = ResolversParentTypes['ImagePageSection'],
> = {
  accessDisplay?: Resolver<ResolversTypes['ClassDetailsAccessDisplay'], ParentType, ContextType>
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isCommunityLedClass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JoinBookedSessionCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinBookedSessionCardAction'] = ResolversParentTypes['JoinBookedSessionCardAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type JoinLiveSessionCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinLiveSessionCardAction'] = ResolversParentTypes['JoinLiveSessionCardAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LiveAndUpcomingClassesPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveAndUpcomingClassesPageSection'] = ResolversParentTypes['LiveAndUpcomingClassesPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LiveGuidesSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveGuidesSection'] = ResolversParentTypes['LiveGuidesSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['GuideCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LiveNowPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveNowPageSection'] = ResolversParentTypes['LiveNowPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LiveStreamResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LiveStream'] = ResolversParentTypes['LiveStream'],
> = {
  ingestServer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  playbackUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  streamKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LobbySessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LobbySession'] = ResolversParentTypes['LobbySession'],
> = {
  aolVodVideoDurationInSecondsHack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  attachments?: Resolver<Array<ResolversTypes['SessionNotesAttachment']>, ParentType, ContextType>
  authenticatedUserIsGuide?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  backButtonNavigationAction?: Resolver<Maybe<ResolversTypes['NavigateBackButtonAction']>, ParentType, ContextType>
  canHaveStream?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  cardImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  class?: Resolver<Maybe<ResolversTypes['Class']>, ParentType, ContextType>
  classCategories?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  deprecatedTimekitProjectId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  deprecatedTimekitSlotId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  durationInMinutes?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  endTimeUTC?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  guide?: Resolver<Maybe<ResolversTypes['Guide']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isAolVodClassHack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isCommunityLedClass?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isEncore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isMeetingProtected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  launchMeetingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  livenessType?: Resolver<ResolversTypes['LivenessType'], ParentType, ContextType>
  nonStreamableReason?: Resolver<Maybe<ResolversTypes['NonStreamableReason']>, ParentType, ContextType>
  notesHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  recording?: Resolver<Maybe<ResolversTypes['Recording']>, ParentType, ContextType>
  sponsor?: Resolver<Maybe<ResolversTypes['Sponsor']>, ParentType, ContextType>
  startTimeUTC?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  stream?: Resolver<Maybe<ResolversTypes['LiveStream']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  topic?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userHasEntitlement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  zoomMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LocationBaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LocationBase'] = ResolversParentTypes['LocationBase'],
> = {
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type LockedAccessDisplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LockedAccessDisplay'] = ResolversParentTypes['LockedAccessDisplay'],
> = {
  iconUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetaInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetaInfo'] = ResolversParentTypes['MetaInfo'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  jsonLd?: Resolver<Maybe<ResolversTypes['MetaInfoSchema']>, ParentType, ContextType>
  robots?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MetaInfoSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MetaInfoSchema'] = ResolversParentTypes['MetaInfoSchema'],
> = {
  __resolveType: TypeResolveFn<'ClassSchema' | 'HomeSchema' | 'SessionSchema', ParentType, ContextType>
}

export type MicroLearningShortsSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MicroLearningShortsSection'] = ResolversParentTypes['MicroLearningShortsSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<Maybe<ResolversTypes['MicroShortCard']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MicroShortCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MicroShortCard'] = ResolversParentTypes['MicroShortCard'],
> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MostPopularPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MostPopularPageSection'] = ResolversParentTypes['MostPopularPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MustAuthenticateUserCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MustAuthenticateUserCardAction'] = ResolversParentTypes['MustAuthenticateUserCardAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  banUser?: Resolver<
    ResolversTypes['BanUserOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationBanUserArgs, 'input'>
  >
  callRequiredAction?: Resolver<
    Maybe<ResolversTypes['CallRequiredActionResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationCallRequiredActionArgs, 'action'>
  >
  cancelMultipleSessionBookings?: Resolver<
    Array<ResolversTypes['CancelSessionBookingResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelMultipleSessionBookingsArgs, 'sessionIds'>
  >
  cancelSessionBooking?: Resolver<
    ResolversTypes['CancelSessionBookingResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelSessionBookingArgs, 'sessionId'>
  >
  createFeedback?: Resolver<
    ResolversTypes['CreateFeedbackResult'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateFeedbackArgs, 'input'>
  >
  createMultipleSessionBooking?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateMultipleSessionBookingArgs, 'sessionIds' | 'timezone' | 'timezoneOffset'>
  >
  createSessionBooking?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateSessionBookingArgs, 'sessionId' | 'timezone' | 'timezoneOffset'>
  >
  recommendationFeedback?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationRecommendationFeedbackArgs, 'feedback'>
  >
  setPartnerCookieByCode?: Resolver<
    ResolversTypes['SetPartnerCookieByCodeOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPartnerCookieByCodeArgs, 'code'>
  >
  unbanUser?: Resolver<
    ResolversTypes['UnbanUserOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationUnbanUserArgs, 'input'>
  >
  validateAutoLoginLink?: Resolver<
    ResolversTypes['ValidateAutoLoginLinkOutput'],
    ParentType,
    ContextType,
    RequireFields<MutationValidateAutoLoginLinkArgs, 'input'>
  >
}

export type NavigateBackButtonActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigateBackButtonAction'] = ResolversParentTypes['NavigateBackButtonAction'],
> = {
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigateToAttendedSessionDetailActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigateToAttendedSessionDetailAction'] = ResolversParentTypes['NavigateToAttendedSessionDetailAction'],
> = {
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigateToClassDetailActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigateToClassDetailAction'] = ResolversParentTypes['NavigateToClassDetailAction'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  classSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigateToGuideActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigateToGuideAction'] = ResolversParentTypes['NavigateToGuideAction'],
> = {
  guideId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guideSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigateToSeriesActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigateToSeriesAction'] = ResolversParentTypes['NavigateToSeriesAction'],
> = {
  seriesId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  seriesSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NavigationAction'] = ResolversParentTypes['NavigationAction'],
> = {
  __resolveType: TypeResolveFn<
    | 'HeroInfoNavigationAction'
    | 'NavigateBackButtonAction'
    | 'NavigateToAttendedSessionDetailAction'
    | 'NavigateToClassDetailAction'
    | 'NavigateToGuideAction'
    | 'NavigateToSeriesAction'
    | 'PartnerInfoNavigationAction',
    ParentType,
    ContextType
  >
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type NextSessionSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NextSessionSection'] = ResolversParentTypes['NextSessionSection'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type NonRecurringPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NonRecurringPrice'] = ResolversParentTypes['NonRecurringPrice'],
> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  unitAmountCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type OnLoadActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['OnLoadAction'] = ResolversParentTypes['OnLoadAction'],
> = {
  __resolveType: TypeResolveFn<'Dialog', ParentType, ContextType>
}

export type PageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Page'] = ResolversParentTypes['Page'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['MetaInfo'], ParentType, ContextType>
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['PageSection']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  hasPrevPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  nextPageCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  pageItems?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  prevPageCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  totalItems?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  totalItemsIsApproximate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageSection'] = ResolversParentTypes['PageSection'],
> = {
  __resolveType: TypeResolveFn<
    | 'AccessMessageSection'
    | 'AolCategoryPillsSection'
    | 'AolHubHeroSection'
    | 'AttendedSessionsPageSection'
    | 'BookMultipleSessionsSection'
    | 'CTAPageSection'
    | 'CategoryClassesSection'
    | 'CategoryIndexPageSection'
    | 'CategoryInfoSection'
    | 'CategorySessionsSection'
    | 'ClassSummaryPageSection'
    | 'CommunityBannersSection'
    | 'CompanyInfoPageSection'
    | 'ContentPageSection'
    | 'EmbedCategoryPillsSection'
    | 'EmbedHubHeroSection'
    | 'EmbedLiveAndUpcomingSection'
    | 'EmbedTrendingSection'
    | 'FeaturedClassesSection'
    | 'FeaturedSessionsSection'
    | 'GuideClassPageSection'
    | 'GuideClassesPageSection'
    | 'GuideIndexInfoPageSection'
    | 'GuideInfoPageSection'
    | 'GuidePageSection'
    | 'GuideSessionsPageSection'
    | 'HelpInfoPageSection'
    | 'HeroInfoSection'
    | 'ImagePageSection'
    | 'LiveAndUpcomingClassesPageSection'
    | 'LiveGuidesSection'
    | 'LiveNowPageSection'
    | 'MicroLearningShortsSection'
    | 'MostPopularPageSection'
    | 'NextSessionSection'
    | 'PartnerInfoPageSection'
    | 'PartnershipBannerSection'
    | 'PopularCategoriesSection'
    | 'PopularSeriesSection'
    | 'PricingPageSection'
    | 'ReviewPageSection'
    | 'SchedulePageSection'
    | 'SearchPageSection'
    | 'SectionGroupPageSection'
    | 'SeriesClassesPageSection'
    | 'SeriesClassesRowSection'
    | 'SeriesIndexInfoPageSection'
    | 'SeriesInfoPageSection'
    | 'SeriesPageSection'
    | 'SessionNotesDetailsPageSection'
    | 'SessionNotesInfoPageSection'
    | 'SimilarClassesPageSection'
    | 'SnippetPageSection'
    | 'SponsorInfoPageSection'
    | 'StartingSoonPageSection'
    | 'TagsPageSection'
    | 'TestimonialsPageSection'
    | 'UpcomingPageSection'
    | 'WalkthroughPageSection',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type PageSectionUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageSectionUnion'] = ResolversParentTypes['PageSectionUnion'],
> = {
  __resolveType: TypeResolveFn<
    | 'AccessMessageSection'
    | 'AolCategoryPillsSection'
    | 'AolHubHeroSection'
    | 'AttendedSessionsPageSection'
    | 'BookMultipleSessionsSection'
    | 'CTAPageSection'
    | 'CategoryClassesSection'
    | 'CategoryIndexPageSection'
    | 'CategoryInfoSection'
    | 'CategorySessionsSection'
    | 'ClassSummaryPageSection'
    | 'CommunityBannersSection'
    | 'CompanyInfoPageSection'
    | 'ContentPageSection'
    | 'EmbedCategoryPillsSection'
    | 'EmbedHubHeroSection'
    | 'EmbedLiveAndUpcomingSection'
    | 'EmbedTrendingSection'
    | 'FeaturedClassesSection'
    | 'FeaturedSessionsSection'
    | 'GuideClassPageSection'
    | 'GuideClassesPageSection'
    | 'GuideIndexInfoPageSection'
    | 'GuideInfoPageSection'
    | 'GuidePageSection'
    | 'GuideSessionsPageSection'
    | 'HelpInfoPageSection'
    | 'HeroInfoSection'
    | 'ImagePageSection'
    | 'LiveAndUpcomingClassesPageSection'
    | 'LiveGuidesSection'
    | 'LiveNowPageSection'
    | 'MicroLearningShortsSection'
    | 'MostPopularPageSection'
    | 'NextSessionSection'
    | 'PartnerInfoPageSection'
    | 'PartnershipBannerSection'
    | 'PopularCategoriesSection'
    | 'PopularSeriesSection'
    | 'PricingPageSection'
    | 'ReviewPageSection'
    | 'SchedulePageSection'
    | 'SearchPageSection'
    | 'SectionGroupPageSection'
    | 'SeriesClassesPageSection'
    | 'SeriesClassesRowSection'
    | 'SeriesIndexInfoPageSection'
    | 'SeriesInfoPageSection'
    | 'SeriesPageSection'
    | 'SessionNotesDetailsPageSection'
    | 'SessionNotesInfoPageSection'
    | 'SimilarClassesPageSection'
    | 'SnippetPageSection'
    | 'SponsorInfoPageSection'
    | 'StartingSoonPageSection'
    | 'TagsPageSection'
    | 'TestimonialsPageSection'
    | 'UpcomingPageSection'
    | 'WalkthroughPageSection',
    ParentType,
    ContextType
  >
}

export type PageSectionWithNavigationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageSectionWithNavigation'] = ResolversParentTypes['PageSectionWithNavigation'],
> = {
  __resolveType: TypeResolveFn<
    | 'CompanyInfoPageSection'
    | 'ContentPageSection'
    | 'LiveAndUpcomingClassesPageSection'
    | 'LiveGuidesSection'
    | 'LiveNowPageSection'
    | 'MostPopularPageSection'
    | 'PopularCategoriesSection'
    | 'PopularSeriesSection'
    | 'SchedulePageSection'
    | 'SeriesClassesRowSection'
    | 'SimilarClassesPageSection'
    | 'StartingSoonPageSection'
    | 'TestimonialsPageSection'
    | 'UpcomingPageSection',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
}

export type PageSectionsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageSections'] = ResolversParentTypes['PageSections'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sections?: Resolver<Array<Maybe<ResolversTypes['PageSection']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PageUnionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageUnion'] = ResolversParentTypes['PageUnion'],
> = {
  __resolveType: TypeResolveFn<'Page' | 'SchedulePage', ParentType, ContextType>
}

export type PaginatedEntitlementsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedEntitlements'] = ResolversParentTypes['PaginatedEntitlements'],
> = {
  items?: Resolver<Maybe<Array<ResolversTypes['Entitlement']>>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PaginatedListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaginatedList'] = ResolversParentTypes['PaginatedList'],
> = {
  __resolveType: TypeResolveFn<
    | 'AttendedSessionsPageSection'
    | 'EntitlementsSection'
    | 'LiveAndUpcomingClassesPageSection'
    | 'LiveNowPageSection'
    | 'MostPopularPageSection'
    | 'PaginatedEntitlements'
    | 'ReviewPageSection'
    | 'SchedulePageSection'
    | 'SearchPageSection'
    | 'SimilarClassesPageSection'
    | 'StartingSoonPageSection'
    | 'UpcomingPageSection',
    ParentType,
    ContextType
  >
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
}

export type PartnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Partner'] = ResolversParentTypes['Partner'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  descriptionHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  titleHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerInfoContent'] = ResolversParentTypes['PartnerInfoContent'],
> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  couponCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['PartnerInfoNavigationAction']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['PartnerInfoPrimaryAction']>, ParentType, ContextType>
  showVideo?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  userStats?: Resolver<Maybe<ResolversTypes['UserStats']>, ParentType, ContextType>
  video?: Resolver<Maybe<ResolversTypes['PartnerInfoContentVideo']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerInfoContentVideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerInfoContentVideo'] = ResolversParentTypes['PartnerInfoContentVideo'],
> = {
  posterUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  srcUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerInfoNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerInfoNavigationAction'] = ResolversParentTypes['PartnerInfoNavigationAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerInfoPageSection'] = ResolversParentTypes['PartnerInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['PartnerInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnerInfoPrimaryActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnerInfoPrimaryAction'] = ResolversParentTypes['PartnerInfoPrimaryAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnershipBannerContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnershipBannerContent'] = ResolversParentTypes['PartnershipBannerContent'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  imageUrls?: Resolver<ResolversTypes['PartnershipBannerImageUrls'], ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  logoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  partnerName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnershipBannerImageUrlsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnershipBannerImageUrls'] = ResolversParentTypes['PartnershipBannerImageUrls'],
> = {
  desktop?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  mobile?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  smallTablet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  tablet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnershipBannerSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnershipBannerSection'] = ResolversParentTypes['PartnershipBannerSection'],
> = {
  content?: Resolver<ResolversTypes['PartnershipBannerContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PartnershipProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PartnershipProduct'] = ResolversParentTypes['PartnershipProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  partner?: Resolver<Maybe<ResolversTypes['Partner']>, ParentType, ContextType>
  partnerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopularCategoriesSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularCategoriesSection'] = ResolversParentTypes['PopularCategoriesSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['CategoryCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PopularSeriesSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PopularSeriesSection'] = ResolversParentTypes['PopularSeriesSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SeriesCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PotentialActionSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PotentialActionSchema'] = ResolversParentTypes['PotentialActionSchema'],
> = {
  queryInput?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Price'] = ResolversParentTypes['Price'],
> = {
  __resolveType: TypeResolveFn<'NonRecurringPrice' | 'RecurringPrice', ParentType, ContextType>
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  unitAmountCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
}

export type PricingPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingPageSection'] = ResolversParentTypes['PricingPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['PricingPlanItem']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingPlanAction'] = ResolversParentTypes['PricingPlanAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  target?: Resolver<ResolversTypes['PricingPlanActionTarget'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['PricingPlanActionType'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanHighlightResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingPlanHighlight'] = ResolversParentTypes['PricingPlanHighlight'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingPlanItem'] = ResolversParentTypes['PricingPlanItem'],
> = {
  features?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>
  featuresTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  highlighted?: Resolver<Maybe<ResolversTypes['PricingPlanHighlight']>, ParentType, ContextType>
  iconUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isFree?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isNonPlan?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  pricing?: Resolver<Maybe<Array<ResolversTypes['PricingPlanPricing']>>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['PricingPlanAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type PricingPlanPricingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PricingPlanPricing'] = ResolversParentTypes['PricingPlanPricing'],
> = {
  billingInterval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  displayPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  gsuPriceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product'],
> = {
  __resolveType: TypeResolveFn<
    | 'ComplimentaryProduct'
    | 'GrandfatheredProduct'
    | 'GuideProduct'
    | 'PartnershipProduct'
    | 'RecurringSubscriptionProduct'
    | 'ScholarshipProduct'
    | 'SingleSessionProduct'
    | 'StaffProduct'
    | 'TrialProduct',
    ParentType,
    ContextType
  >
}

export type ProductBaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ProductBase'] = ResolversParentTypes['ProductBase'],
> = {
  __resolveType: TypeResolveFn<
    | 'ComplimentaryProduct'
    | 'GrandfatheredProduct'
    | 'GuideProduct'
    | 'PartnershipProduct'
    | 'RecurringSubscriptionProduct'
    | 'ScholarshipProduct'
    | 'SingleSessionProduct'
    | 'StaffProduct'
    | 'TrialProduct',
    ParentType,
    ContextType
  >
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  appInfo?: Resolver<
    Maybe<ResolversTypes['AppInfo']>,
    ParentType,
    ContextType,
    RequireFields<QueryAppInfoArgs, 'appName' | 'currentVersion'>
  >
  attendedSessionsPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryAttendedSessionsPageArgs, 'input'>
  >
  categoryIndexPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>
  categoryPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryCategoryPageArgs, 'slug'>
  >
  classDetailPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryClassDetailPageArgs, 'classId'>
  >
  currentUser?: Resolver<ResolversTypes['CurrentUser'], ParentType, ContextType>
  dialog?: Resolver<Maybe<ResolversTypes['Dialog']>, ParentType, ContextType, RequireFields<QueryDialogArgs, 'type'>>
  embedPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType, RequireFields<QueryEmbedPageArgs, 'id'>>
  entitlementsPage?: Resolver<
    Maybe<ResolversTypes['EntitlementsPage']>,
    ParentType,
    ContextType,
    RequireFields<QueryEntitlementsPageArgs, 'currentEntitlementsInput' | 'previousEntitlementsInput'>
  >
  features?: Resolver<
    Array<ResolversTypes['Feature']>,
    ParentType,
    ContextType,
    RequireFields<QueryFeaturesArgs, 'appName' | 'currentVersion'>
  >
  feedback?: Resolver<
    Maybe<ResolversTypes['Feedback']>,
    ParentType,
    ContextType,
    RequireFields<QueryFeedbackArgs, 'input'>
  >
  getsetupInfo?: Resolver<Maybe<ResolversTypes['GetsetupInfo']>, ParentType, ContextType>
  guideIndexPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>
  guidePage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryGuidePageArgs, 'slug'>
  >
  homepageSections?: Resolver<
    Maybe<ResolversTypes['PageSections']>,
    ParentType,
    ContextType,
    RequireFields<QueryHomepageSectionsArgs, 'id'>
  >
  lobbySession?: Resolver<
    Maybe<ResolversTypes['LobbySession']>,
    ParentType,
    ContextType,
    RequireFields<QueryLobbySessionArgs, 'input'>
  >
  mySchedulePage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryMySchedulePageArgs, 'request'>
  >
  partnerPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryPartnerPageArgs, 'slug'>
  >
  schedulePage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QuerySchedulePageArgs, 'request'>
  >
  searchPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchPageArgs, 'request'>
  >
  seriesIndexPage?: Resolver<Maybe<ResolversTypes['Page']>, ParentType, ContextType>
  seriesPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QuerySeriesPageArgs, 'slug'>
  >
  sessionLobbyPage?: Resolver<
    Maybe<ResolversTypes['SessionLobbyPage']>,
    ParentType,
    ContextType,
    RequireFields<QuerySessionLobbyPageArgs, 'input'>
  >
  sessionNotesPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QuerySessionNotesPageArgs, 'request'>
  >
  sitemapCategories?: Resolver<ResolversTypes['SitemapContent'], ParentType, ContextType>
  sitemapClasses?: Resolver<ResolversTypes['SitemapContent'], ParentType, ContextType>
  sitemapGuides?: Resolver<ResolversTypes['SitemapContent'], ParentType, ContextType>
  sitemapSeries?: Resolver<ResolversTypes['SitemapContent'], ParentType, ContextType>
  sitemapSessions?: Resolver<ResolversTypes['SitemapContent'], ParentType, ContextType>
  slot?: Resolver<Maybe<ResolversTypes['Slot']>, ParentType, ContextType, RequireFields<QuerySlotArgs, never>>
  slotRecommendations?: Resolver<
    ResolversTypes['SlotRecommendations'],
    ParentType,
    ContextType,
    RequireFields<QuerySlotRecommendationsArgs, 'request'>
  >
  staticPage?: Resolver<
    Maybe<ResolversTypes['Page']>,
    ParentType,
    ContextType,
    RequireFields<QueryStaticPageArgs, 'id'>
  >
  video?: Resolver<Maybe<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryVideoArgs, 'input'>>
  videos?: Resolver<Array<ResolversTypes['Video']>, ParentType, ContextType, RequireFields<QueryVideosArgs, 'input'>>
}

export type RecordingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Recording'] = ResolversParentTypes['Recording'],
> = {
  type?: Resolver<ResolversTypes['RecordingType'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecurringPriceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecurringPrice'] = ResolversParentTypes['RecurringPrice'],
> = {
  currencyCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  interval?: Resolver<ResolversTypes['RecurringInterval'], ParentType, ContextType>
  intervalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  productId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  unitAmountCents?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RecurringSubscriptionProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecurringSubscriptionProduct'] = ResolversParentTypes['RecurringSubscriptionProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReviewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Review'] = ResolversParentTypes['Review'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  date?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  rating?: Resolver<ResolversTypes['Float'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ReviewPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ReviewPageSection'] = ResolversParentTypes['ReviewPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['Review']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RichTextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichText'] = ResolversParentTypes['RichText'],
> = {
  __resolveType: TypeResolveFn<'RichTextLinkNode' | 'RichTextTextNode', ParentType, ContextType>
}

export type RichTextHyperlinkLinkNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichTextHyperlinkLinkNode'] = ResolversParentTypes['RichTextHyperlinkLinkNode'],
> = {
  linkType?: Resolver<ResolversTypes['RichTextDataLinkType'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['RichTextDataType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RichTextLinkNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichTextLinkNode'] = ResolversParentTypes['RichTextLinkNode'],
> = {
  data?: Resolver<ResolversTypes['RichTextLinkNodeDataNode'], ParentType, ContextType>
  nodeType?: Resolver<ResolversTypes['RichTextNodeType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RichTextLinkNodeDataNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichTextLinkNodeDataNode'] = ResolversParentTypes['RichTextLinkNodeDataNode'],
> = {
  __resolveType: TypeResolveFn<'RichTextHyperlinkLinkNode' | 'RichTextPageLinkNode', ParentType, ContextType>
}

export type RichTextPageLinkNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichTextPageLinkNode'] = ResolversParentTypes['RichTextPageLinkNode'],
> = {
  linkType?: Resolver<ResolversTypes['RichTextDataLinkType'], ParentType, ContextType>
  targetPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['RichTextDataType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type RichTextTextNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RichTextTextNode'] = ResolversParentTypes['RichTextTextNode'],
> = {
  nodeType?: Resolver<ResolversTypes['RichTextNodeType'], ParentType, ContextType>
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScheduleDaySectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScheduleDaySection'] = ResolversParentTypes['ScheduleDaySection'],
> = {
  date?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SchedulePageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchedulePage'] = ResolversParentTypes['SchedulePage'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['MetaInfo'], ParentType, ContextType>
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['SchedulePageSection']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SchedulePageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchedulePageSection'] = ResolversParentTypes['SchedulePageSection'],
> = {
  facetInfo?: Resolver<Maybe<ResolversTypes['FacetInfo']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ScheduleDaySection']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SchemaBaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchemaBase'] = ResolversParentTypes['SchemaBase'],
> = {
  __resolveType: TypeResolveFn<'ClassSchema' | 'HomeSchema' | 'SessionSchema', ParentType, ContextType>
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
}

export type SchemaProviderResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SchemaProvider'] = ResolversParentTypes['SchemaProvider'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sameAs?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ScholarshipProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScholarshipProduct'] = ResolversParentTypes['ScholarshipProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchClassesFacetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchClassesFacet'] = ResolversParentTypes['SearchClassesFacet'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  facet?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchClassesResultsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchClassesResults'] = ResolversParentTypes['SearchClassesResults'],
> = {
  classes?: Resolver<Array<ResolversTypes['Class']>, ParentType, ContextType>
  facets?: Resolver<Maybe<Array<ResolversTypes['SearchClassesFacet']>>, ParentType, ContextType>
  hits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SearchPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchPageSection'] = ResolversParentTypes['SearchPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionGroupPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SectionGroupPageSection'] = ResolversParentTypes['SectionGroupPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  layoutHint?: Resolver<Maybe<ResolversTypes['SectionGroupLayoutHint']>, ParentType, ContextType>
  sections?: Resolver<Array<Maybe<ResolversTypes['PageSection']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SectionNavigationActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SectionNavigationAction'] = ResolversParentTypes['SectionNavigationAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  targetPageId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  theme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesCard'] = ResolversParentTypes['SeriesCard'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>
  numberOfClasses?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sponsor?: Resolver<Maybe<ResolversTypes['Sponsor']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesClassesPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesClassesPageSection'] = ResolversParentTypes['SeriesClassesPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ClassCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesClassesRowSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesClassesRowSection'] = ResolversParentTypes['SeriesClassesRowSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['ClassCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesIndexInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesIndexInfoContent'] = ResolversParentTypes['SeriesIndexInfoContent'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesIndexInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesIndexInfoPageSection'] = ResolversParentTypes['SeriesIndexInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['SeriesIndexInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesIndexPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesIndexPage'] = ResolversParentTypes['SeriesIndexPage'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['MetaInfo'], ParentType, ContextType>
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  sections?: Resolver<Array<ResolversTypes['PageSection']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesInfoContent'] = ResolversParentTypes['SeriesInfoContent'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hasSessions?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesInfoPageSection'] = ResolversParentTypes['SeriesInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['SeriesInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SeriesPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SeriesPageSection'] = ResolversParentTypes['SeriesPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SeriesCard']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionCard'] = ResolversParentTypes['SessionCard'],
> = {
  accessDisplay?: Resolver<ResolversTypes['SessionCardAccessDisplay'], ParentType, ContextType>
  aolVodVideoDurationInSecondsHack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  booked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  classImageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  classIsNew?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  classSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  durationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  end?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  guideAvatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideByline?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  isAolVodClassHack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isCommunityLedClass?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isEncore?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  metaInfo?: Resolver<ResolversTypes['SessionMetaInfo'], ParentType, ContextType>
  navigateToAttendedSessionDetailAction?: Resolver<
    Maybe<ResolversTypes['NavigateToAttendedSessionDetailAction']>,
    ParentType,
    ContextType
  >
  navigateToGuideAction?: Resolver<Maybe<ResolversTypes['NavigateToGuideAction']>, ParentType, ContextType>
  navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['CardAction']>, ParentType, ContextType>
  rating?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>
  secondaryAction?: Resolver<Maybe<ResolversTypes['CardAction']>, ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  sponsor?: Resolver<Maybe<ResolversTypes['Sponsor']>, ParentType, ContextType>
  start?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionCardAccessDisplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionCardAccessDisplay'] = ResolversParentTypes['SessionCardAccessDisplay'],
> = {
  __resolveType: TypeResolveFn<'LockedAccessDisplay' | 'UnLockedAccessDisplay', ParentType, ContextType>
}

export type SessionInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionInfoContent'] = ResolversParentTypes['SessionInfoContent'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  classSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  classTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  durationMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  end?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  guideAvatarUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guideByline?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guideId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  guideSlug?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  navigateToGuideAction?: Resolver<Maybe<ResolversTypes['NavigateToGuideAction']>, ParentType, ContextType>
  navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  start?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionLobbyPageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionLobbyPage'] = ResolversParentTypes['SessionLobbyPage'],
> = {
  onLoadAction?: Resolver<Maybe<ResolversTypes['OnLoadAction']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionMetaInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionMetaInfo'] = ResolversParentTypes['SessionMetaInfo'],
> = {
  jsonLd?: Resolver<ResolversTypes['SessionSchema'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionNotesAttachmentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionNotesAttachment'] = ResolversParentTypes['SessionNotesAttachment'],
> = {
  created?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  sizeInBytes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  updated?: Resolver<ResolversTypes['UTCTimestamp'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionNotesDetailsPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionNotesDetailsPageSection'] = ResolversParentTypes['SessionNotesDetailsPageSection'],
> = {
  attachments?: Resolver<Array<ResolversTypes['SessionNotesAttachment']>, ParentType, ContextType>
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  notesHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionNotesInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionNotesInfoPageSection'] = ResolversParentTypes['SessionNotesInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['SessionInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SessionSchemaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SessionSchema'] = ResolversParentTypes['SessionSchema'],
> = {
  context?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  eventAttendanceMode?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  image?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  location?: Resolver<ResolversTypes['LocationBase'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SetPartnerCookieByCodeOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SetPartnerCookieByCodeOutput'] = ResolversParentTypes['SetPartnerCookieByCodeOutput'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SimilarClassesPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SimilarClassesPageSection'] = ResolversParentTypes['SimilarClassesPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SingleSessionProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SingleSessionProduct'] = ResolversParentTypes['SingleSessionProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  session?: Resolver<Maybe<ResolversTypes['SessionCard']>, ParentType, ContextType>
  sessionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SitemapContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SitemapContent'] = ResolversParentTypes['SitemapContent'],
> = {
  urlset?: Resolver<Array<Maybe<ResolversTypes['SitemapUrl']>>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SitemapUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SitemapUrl'] = ResolversParentTypes['SitemapUrl'],
> = {
  lastmod?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  loc?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SlotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Slot'] = ResolversParentTypes['Slot'],
> = {
  aolVodVideoDurationInSecondsHack?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>
  authenticatedUserIsGuide?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  canHaveStream?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  class?: Resolver<Maybe<ResolversTypes['Class']>, ParentType, ContextType>
  durationInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  endTimeUTC?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  fallbackLaunchMeetingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  guide?: Resolver<Maybe<ResolversTypes['Guide']>, ParentType, ContextType>
  guideId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  isAolVodClassHack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  isMeetingProtected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  launchMeetingPrivateUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  launchMeetingPublicUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  launchMeetingUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  livenessType?: Resolver<ResolversTypes['LivenessType'], ParentType, ContextType>
  lobbyType?: Resolver<ResolversTypes['LobbyType'], ParentType, ContextType>
  nonStreamableReason?: Resolver<Maybe<ResolversTypes['NonStreamableReason']>, ParentType, ContextType>
  recording?: Resolver<Maybe<ResolversTypes['Recording']>, ParentType, ContextType>
  seatBooked?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  seatCapacity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  seatsAvailable?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  startTimeUTC?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  stream?: Resolver<Maybe<ResolversTypes['LiveStream']>, ParentType, ContextType>
  timekitProjectId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  timekitSlotId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  userHasEntitlement?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  zoomMeetingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SlotRecommendationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlotRecommendation'] = ResolversParentTypes['SlotRecommendation'],
> = {
  feedback?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  slot?: Resolver<ResolversTypes['Slot'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SlotRecommendationsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlotRecommendations'] = ResolversParentTypes['SlotRecommendations'],
> = {
  slots?: Resolver<Array<ResolversTypes['SlotRecommendation']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SnippetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Snippet'] = ResolversParentTypes['Snippet'],
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  primaryAction?: Resolver<Maybe<ResolversTypes['UrlAction']>, ParentType, ContextType>
  secondaryAction?: Resolver<Maybe<ResolversTypes['UrlAction']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SnippetPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SnippetPageSection'] = ResolversParentTypes['SnippetPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['Snippet']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SponsorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Sponsor'] = ResolversParentTypes['Sponsor'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SponsorInfoContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SponsorInfoContent'] = ResolversParentTypes['SponsorInfoContent'],
> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  endDate?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  startDate?: Resolver<Maybe<ResolversTypes['UTCTimestamp']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type SponsorInfoPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SponsorInfoPageSection'] = ResolversParentTypes['SponsorInfoPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  content?: Resolver<ResolversTypes['SponsorInfoContent'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StaffProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StaffProduct'] = ResolversParentTypes['StaffProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type StartingSoonPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StartingSoonPageSection'] = ResolversParentTypes['StartingSoonPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag'],
> = {
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TagsPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagsPageSection'] = ResolversParentTypes['TagsPageSection'],
> = {
  catalogueLanguage?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['Tag']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestimonialCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestimonialCard'] = ResolversParentTypes['TestimonialCard'],
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  emphasised?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TestimonialsPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TestimonialsPageSection'] = ResolversParentTypes['TestimonialsPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<Maybe<ResolversTypes['TestimonialCard']>>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TextContentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextContent'] = ResolversParentTypes['TextContent'],
> = {
  html?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type TrialProductResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TrialProduct'] = ResolversParentTypes['TrialProduct'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export interface UtcTimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UTCTimestamp'], any> {
  name: 'UTCTimestamp'
}

export type UnLockedAccessDisplayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnLockedAccessDisplay'] = ResolversParentTypes['UnLockedAccessDisplay'],
> = {
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UnbanUserOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnbanUserOutput'] = ResolversParentTypes['UnbanUserOutput'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UpcomingPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UpcomingPageSection'] = ResolversParentTypes['UpcomingPageSection'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  displayPrice?: Resolver<Maybe<ResolversTypes['DisplayPrice']>, ParentType, ContextType>
  displayPriceDecoration?: Resolver<Maybe<ResolversTypes['DisplayPriceDecorationType']>, ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<ResolversTypes['SessionCard']>, ParentType, ContextType>
  navigationActions?: Resolver<Array<ResolversTypes['SectionNavigationAction']>, ParentType, ContextType>
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UrlActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UrlAction'] = ResolversParentTypes['UrlAction'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type UserStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserStats'] = ResolversParentTypes['UserStats'],
> = {
  activeDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  formattedActiveDays?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  formattedTotalBookings?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  formattedTotalMinutes?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  isActiveUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  isNewUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>
  totalBookings?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  totalMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ValidateAutoLoginLinkOutputResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ValidateAutoLoginLinkOutput'] = ResolversParentTypes['ValidateAutoLoginLinkOutput'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  redirectTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VideoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Video'] = ResolversParentTypes['Video'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  originalSource?: Resolver<ResolversTypes['OriginalVideoSource'], ParentType, ContextType>
  standard?: Resolver<ResolversTypes['VideoStandard'], ParentType, ContextType>
  status?: Resolver<ResolversTypes['VideoStatus'], ParentType, ContextType>
  streams?: Resolver<ResolversTypes['VideoStreams'], ParentType, ContextType>
  type?: Resolver<ResolversTypes['VideoType'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VideoAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoAsset'] = ResolversParentTypes['VideoAsset'],
> = {
  dash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  hls?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  thumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VideoSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoSession'] = ResolversParentTypes['VideoSession'],
> = {
  video?: Resolver<ResolversTypes['Video'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type VideoStreamsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VideoStreams'] = ResolversParentTypes['VideoStreams'],
> = {
  Dash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  HLS?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type ViewClassDetailCardActionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ViewClassDetailCardAction'] = ResolversParentTypes['ViewClassDetailCardAction'],
> = {
  classId?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  classSlug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalkthroughCardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalkthroughCard'] = ResolversParentTypes['WalkthroughCard'],
> = {
  actionLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  imageUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type WalkthroughPageSectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WalkthroughPageSection'] = ResolversParentTypes['WalkthroughPageSection'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>
  items?: Resolver<Array<Maybe<ResolversTypes['WalkthroughCard']>>, ParentType, ContextType>
  subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>
}

export type Resolvers<ContextType = any> = {
  AccessMessageContent?: AccessMessageContentResolvers<ContextType>
  AccessMessagePrimaryAction?: AccessMessagePrimaryActionResolvers<ContextType>
  AccessMessageSection?: AccessMessageSectionResolvers<ContextType>
  AolCategoryPillsSection?: AolCategoryPillsSectionResolvers<ContextType>
  AolCategoryPillsSectionNavigationAction?: AolCategoryPillsSectionNavigationActionResolvers<ContextType>
  AolHubHeroContent?: AolHubHeroContentResolvers<ContextType>
  AolHubHeroContentHeading?: AolHubHeroContentHeadingResolvers<ContextType>
  AolHubHeroSection?: AolHubHeroSectionResolvers<ContextType>
  AppInfo?: AppInfoResolvers<ContextType>
  AttendedSessionsPage?: AttendedSessionsPageResolvers<ContextType>
  AttendedSessionsPageSection?: AttendedSessionsPageSectionResolvers<ContextType>
  AuthenticatedUser?: AuthenticatedUserResolvers<ContextType>
  BanUserOutput?: BanUserOutputResolvers<ContextType>
  BannerImageUrl?: BannerImageUrlResolvers<ContextType>
  BookMultipleSessionsSection?: BookMultipleSessionsSectionResolvers<ContextType>
  BookSessionCardAction?: BookSessionCardActionResolvers<ContextType>
  CTAPageSection?: CtaPageSectionResolvers<ContextType>
  CallRequiredActionResult?: CallRequiredActionResultResolvers<ContextType>
  Callout?: CalloutResolvers<ContextType>
  CancelSessionBookingCardAction?: CancelSessionBookingCardActionResolvers<ContextType>
  CancelSessionBookingResult?: CancelSessionBookingResultResolvers<ContextType>
  Card?: CardResolvers<ContextType>
  CardAction?: CardActionResolvers<ContextType>
  Category?: CategoryResolvers<ContextType>
  CategoryCard?: CategoryCardResolvers<ContextType>
  CategoryClassesSection?: CategoryClassesSectionResolvers<ContextType>
  CategoryIndexPageSection?: CategoryIndexPageSectionResolvers<ContextType>
  CategoryInfoContent?: CategoryInfoContentResolvers<ContextType>
  CategoryInfoSection?: CategoryInfoSectionResolvers<ContextType>
  CategoryRecommendation?: CategoryRecommendationResolvers<ContextType>
  CategoryRecommendations?: CategoryRecommendationsResolvers<ContextType>
  CategorySessionsSection?: CategorySessionsSectionResolvers<ContextType>
  Class?: ClassResolvers<ContextType>
  ClassCard?: ClassCardResolvers<ContextType>
  ClassDetailsAccessDisplay?: ClassDetailsAccessDisplayResolvers<ContextType>
  ClassSchema?: ClassSchemaResolvers<ContextType>
  ClassSummaryContent?: ClassSummaryContentResolvers<ContextType>
  ClassSummaryPageSection?: ClassSummaryPageSectionResolvers<ContextType>
  CommunityBannerCard?: CommunityBannerCardResolvers<ContextType>
  CommunityBannersSection?: CommunityBannersSectionResolvers<ContextType>
  CompanyInfoBlock?: CompanyInfoBlockResolvers<ContextType>
  CompanyInfoPageSection?: CompanyInfoPageSectionResolvers<ContextType>
  CompanyStat?: CompanyStatResolvers<ContextType>
  ComplimentaryProduct?: ComplimentaryProductResolvers<ContextType>
  ContentPageSection?: ContentPageSectionResolvers<ContextType>
  CreateFeedbackResult?: CreateFeedbackResultResolvers<ContextType>
  CurrentUser?: CurrentUserResolvers<ContextType>
  Dialog?: DialogResolvers<ContextType>
  DialogAction?: DialogActionResolvers<ContextType>
  DialogActions?: DialogActionsResolvers<ContextType>
  DialogConfirmationAction?: DialogConfirmationActionResolvers<ContextType>
  DialogDismissAction?: DialogDismissActionResolvers<ContextType>
  DialogFacebookConnectAction?: DialogFacebookConnectActionResolvers<ContextType>
  DialogPageAction?: DialogPageActionResolvers<ContextType>
  DialogUrlAction?: DialogUrlActionResolvers<ContextType>
  DisplayPrice?: DisplayPriceResolvers<ContextType>
  EmbedCategoryPillsSection?: EmbedCategoryPillsSectionResolvers<ContextType>
  EmbedCategoryPillsSectionNavigationAction?: EmbedCategoryPillsSectionNavigationActionResolvers<ContextType>
  EmbedHubHeroContent?: EmbedHubHeroContentResolvers<ContextType>
  EmbedHubHeroContentHeading?: EmbedHubHeroContentHeadingResolvers<ContextType>
  EmbedHubHeroSection?: EmbedHubHeroSectionResolvers<ContextType>
  EmbedLiveAndUpcomingSection?: EmbedLiveAndUpcomingSectionResolvers<ContextType>
  EmbedTrendingSection?: EmbedTrendingSectionResolvers<ContextType>
  Entitlement?: EntitlementResolvers<ContextType>
  EntitlementCard?: EntitlementCardResolvers<ContextType>
  EntitlementsPage?: EntitlementsPageResolvers<ContextType>
  EntitlementsSection?: EntitlementsSectionResolvers<ContextType>
  FacetCount?: FacetCountResolvers<ContextType>
  FacetCounts?: FacetCountsResolvers<ContextType>
  FacetInfo?: FacetInfoResolvers<ContextType>
  FacetedList?: FacetedListResolvers<ContextType>
  Feature?: FeatureResolvers<ContextType>
  FeaturedClassesSection?: FeaturedClassesSectionResolvers<ContextType>
  FeaturedLogo?: FeaturedLogoResolvers<ContextType>
  FeaturedSessionsSection?: FeaturedSessionsSectionResolvers<ContextType>
  Feedback?: FeedbackResolvers<ContextType>
  GetAccessSessionCardAction?: GetAccessSessionCardActionResolvers<ContextType>
  GetsetupInfo?: GetsetupInfoResolvers<ContextType>
  GrandfatheredProduct?: GrandfatheredProductResolvers<ContextType>
  Guide?: GuideResolvers<ContextType>
  GuideCard?: GuideCardResolvers<ContextType>
  GuideClassContent?: GuideClassContentResolvers<ContextType>
  GuideClassPageSection?: GuideClassPageSectionResolvers<ContextType>
  GuideClassesPageSection?: GuideClassesPageSectionResolvers<ContextType>
  GuideIndexInfoContent?: GuideIndexInfoContentResolvers<ContextType>
  GuideIndexInfoPageSection?: GuideIndexInfoPageSectionResolvers<ContextType>
  GuideIndexPage?: GuideIndexPageResolvers<ContextType>
  GuideInfoContent?: GuideInfoContentResolvers<ContextType>
  GuideInfoPageSection?: GuideInfoPageSectionResolvers<ContextType>
  GuidePageSection?: GuidePageSectionResolvers<ContextType>
  GuideProduct?: GuideProductResolvers<ContextType>
  GuideSessionsPageSection?: GuideSessionsPageSectionResolvers<ContextType>
  HelpInfo?: HelpInfoResolvers<ContextType>
  HelpInfoPageSection?: HelpInfoPageSectionResolvers<ContextType>
  HeroInfoContent?: HeroInfoContentResolvers<ContextType>
  HeroInfoContentVideo?: HeroInfoContentVideoResolvers<ContextType>
  HeroInfoNavigationAction?: HeroInfoNavigationActionResolvers<ContextType>
  HeroInfoPrimaryAction?: HeroInfoPrimaryActionResolvers<ContextType>
  HeroInfoSection?: HeroInfoSectionResolvers<ContextType>
  HomeSchema?: HomeSchemaResolvers<ContextType>
  Image?: ImageResolvers<ContextType>
  ImagePageSection?: ImagePageSectionResolvers<ContextType>
  JoinBookedSessionCardAction?: JoinBookedSessionCardActionResolvers<ContextType>
  JoinLiveSessionCardAction?: JoinLiveSessionCardActionResolvers<ContextType>
  LiveAndUpcomingClassesPageSection?: LiveAndUpcomingClassesPageSectionResolvers<ContextType>
  LiveGuidesSection?: LiveGuidesSectionResolvers<ContextType>
  LiveNowPageSection?: LiveNowPageSectionResolvers<ContextType>
  LiveStream?: LiveStreamResolvers<ContextType>
  LobbySession?: LobbySessionResolvers<ContextType>
  LocationBase?: LocationBaseResolvers<ContextType>
  LockedAccessDisplay?: LockedAccessDisplayResolvers<ContextType>
  MetaInfo?: MetaInfoResolvers<ContextType>
  MetaInfoSchema?: MetaInfoSchemaResolvers<ContextType>
  MicroLearningShortsSection?: MicroLearningShortsSectionResolvers<ContextType>
  MicroShortCard?: MicroShortCardResolvers<ContextType>
  MostPopularPageSection?: MostPopularPageSectionResolvers<ContextType>
  MustAuthenticateUserCardAction?: MustAuthenticateUserCardActionResolvers<ContextType>
  Mutation?: MutationResolvers<ContextType>
  NavigateBackButtonAction?: NavigateBackButtonActionResolvers<ContextType>
  NavigateToAttendedSessionDetailAction?: NavigateToAttendedSessionDetailActionResolvers<ContextType>
  NavigateToClassDetailAction?: NavigateToClassDetailActionResolvers<ContextType>
  NavigateToGuideAction?: NavigateToGuideActionResolvers<ContextType>
  NavigateToSeriesAction?: NavigateToSeriesActionResolvers<ContextType>
  NavigationAction?: NavigationActionResolvers<ContextType>
  NextSessionSection?: NextSessionSectionResolvers<ContextType>
  NonRecurringPrice?: NonRecurringPriceResolvers<ContextType>
  OnLoadAction?: OnLoadActionResolvers<ContextType>
  Page?: PageResolvers<ContextType>
  PageInfo?: PageInfoResolvers<ContextType>
  PageSection?: PageSectionResolvers<ContextType>
  PageSectionUnion?: PageSectionUnionResolvers<ContextType>
  PageSectionWithNavigation?: PageSectionWithNavigationResolvers<ContextType>
  PageSections?: PageSectionsResolvers<ContextType>
  PageUnion?: PageUnionResolvers<ContextType>
  PaginatedEntitlements?: PaginatedEntitlementsResolvers<ContextType>
  PaginatedList?: PaginatedListResolvers<ContextType>
  Partner?: PartnerResolvers<ContextType>
  PartnerInfoContent?: PartnerInfoContentResolvers<ContextType>
  PartnerInfoContentVideo?: PartnerInfoContentVideoResolvers<ContextType>
  PartnerInfoNavigationAction?: PartnerInfoNavigationActionResolvers<ContextType>
  PartnerInfoPageSection?: PartnerInfoPageSectionResolvers<ContextType>
  PartnerInfoPrimaryAction?: PartnerInfoPrimaryActionResolvers<ContextType>
  PartnershipBannerContent?: PartnershipBannerContentResolvers<ContextType>
  PartnershipBannerImageUrls?: PartnershipBannerImageUrlsResolvers<ContextType>
  PartnershipBannerSection?: PartnershipBannerSectionResolvers<ContextType>
  PartnershipProduct?: PartnershipProductResolvers<ContextType>
  PopularCategoriesSection?: PopularCategoriesSectionResolvers<ContextType>
  PopularSeriesSection?: PopularSeriesSectionResolvers<ContextType>
  PotentialActionSchema?: PotentialActionSchemaResolvers<ContextType>
  Price?: PriceResolvers<ContextType>
  PricingPageSection?: PricingPageSectionResolvers<ContextType>
  PricingPlanAction?: PricingPlanActionResolvers<ContextType>
  PricingPlanHighlight?: PricingPlanHighlightResolvers<ContextType>
  PricingPlanItem?: PricingPlanItemResolvers<ContextType>
  PricingPlanPricing?: PricingPlanPricingResolvers<ContextType>
  Product?: ProductResolvers<ContextType>
  ProductBase?: ProductBaseResolvers<ContextType>
  Query?: QueryResolvers<ContextType>
  Recording?: RecordingResolvers<ContextType>
  RecurringPrice?: RecurringPriceResolvers<ContextType>
  RecurringSubscriptionProduct?: RecurringSubscriptionProductResolvers<ContextType>
  Review?: ReviewResolvers<ContextType>
  ReviewPageSection?: ReviewPageSectionResolvers<ContextType>
  RichText?: RichTextResolvers<ContextType>
  RichTextHyperlinkLinkNode?: RichTextHyperlinkLinkNodeResolvers<ContextType>
  RichTextLinkNode?: RichTextLinkNodeResolvers<ContextType>
  RichTextLinkNodeDataNode?: RichTextLinkNodeDataNodeResolvers<ContextType>
  RichTextPageLinkNode?: RichTextPageLinkNodeResolvers<ContextType>
  RichTextTextNode?: RichTextTextNodeResolvers<ContextType>
  ScheduleDaySection?: ScheduleDaySectionResolvers<ContextType>
  SchedulePage?: SchedulePageResolvers<ContextType>
  SchedulePageSection?: SchedulePageSectionResolvers<ContextType>
  SchemaBase?: SchemaBaseResolvers<ContextType>
  SchemaProvider?: SchemaProviderResolvers<ContextType>
  ScholarshipProduct?: ScholarshipProductResolvers<ContextType>
  SearchClassesFacet?: SearchClassesFacetResolvers<ContextType>
  SearchClassesResults?: SearchClassesResultsResolvers<ContextType>
  SearchPageSection?: SearchPageSectionResolvers<ContextType>
  SectionGroupPageSection?: SectionGroupPageSectionResolvers<ContextType>
  SectionNavigationAction?: SectionNavigationActionResolvers<ContextType>
  SeriesCard?: SeriesCardResolvers<ContextType>
  SeriesClassesPageSection?: SeriesClassesPageSectionResolvers<ContextType>
  SeriesClassesRowSection?: SeriesClassesRowSectionResolvers<ContextType>
  SeriesIndexInfoContent?: SeriesIndexInfoContentResolvers<ContextType>
  SeriesIndexInfoPageSection?: SeriesIndexInfoPageSectionResolvers<ContextType>
  SeriesIndexPage?: SeriesIndexPageResolvers<ContextType>
  SeriesInfoContent?: SeriesInfoContentResolvers<ContextType>
  SeriesInfoPageSection?: SeriesInfoPageSectionResolvers<ContextType>
  SeriesPageSection?: SeriesPageSectionResolvers<ContextType>
  SessionCard?: SessionCardResolvers<ContextType>
  SessionCardAccessDisplay?: SessionCardAccessDisplayResolvers<ContextType>
  SessionInfoContent?: SessionInfoContentResolvers<ContextType>
  SessionLobbyPage?: SessionLobbyPageResolvers<ContextType>
  SessionMetaInfo?: SessionMetaInfoResolvers<ContextType>
  SessionNotesAttachment?: SessionNotesAttachmentResolvers<ContextType>
  SessionNotesDetailsPageSection?: SessionNotesDetailsPageSectionResolvers<ContextType>
  SessionNotesInfoPageSection?: SessionNotesInfoPageSectionResolvers<ContextType>
  SessionSchema?: SessionSchemaResolvers<ContextType>
  SetPartnerCookieByCodeOutput?: SetPartnerCookieByCodeOutputResolvers<ContextType>
  SimilarClassesPageSection?: SimilarClassesPageSectionResolvers<ContextType>
  SingleSessionProduct?: SingleSessionProductResolvers<ContextType>
  SitemapContent?: SitemapContentResolvers<ContextType>
  SitemapUrl?: SitemapUrlResolvers<ContextType>
  Slot?: SlotResolvers<ContextType>
  SlotRecommendation?: SlotRecommendationResolvers<ContextType>
  SlotRecommendations?: SlotRecommendationsResolvers<ContextType>
  Snippet?: SnippetResolvers<ContextType>
  SnippetPageSection?: SnippetPageSectionResolvers<ContextType>
  Sponsor?: SponsorResolvers<ContextType>
  SponsorInfoContent?: SponsorInfoContentResolvers<ContextType>
  SponsorInfoPageSection?: SponsorInfoPageSectionResolvers<ContextType>
  StaffProduct?: StaffProductResolvers<ContextType>
  StartingSoonPageSection?: StartingSoonPageSectionResolvers<ContextType>
  Tag?: TagResolvers<ContextType>
  TagsPageSection?: TagsPageSectionResolvers<ContextType>
  TestimonialCard?: TestimonialCardResolvers<ContextType>
  TestimonialsPageSection?: TestimonialsPageSectionResolvers<ContextType>
  TextContent?: TextContentResolvers<ContextType>
  TrialProduct?: TrialProductResolvers<ContextType>
  UTCTimestamp?: GraphQLScalarType
  UnLockedAccessDisplay?: UnLockedAccessDisplayResolvers<ContextType>
  UnbanUserOutput?: UnbanUserOutputResolvers<ContextType>
  UpcomingPageSection?: UpcomingPageSectionResolvers<ContextType>
  UrlAction?: UrlActionResolvers<ContextType>
  UserStats?: UserStatsResolvers<ContextType>
  ValidateAutoLoginLinkOutput?: ValidateAutoLoginLinkOutputResolvers<ContextType>
  Video?: VideoResolvers<ContextType>
  VideoAsset?: VideoAssetResolvers<ContextType>
  VideoSession?: VideoSessionResolvers<ContextType>
  VideoStreams?: VideoStreamsResolvers<ContextType>
  ViewClassDetailCardAction?: ViewClassDetailCardActionResolvers<ContextType>
  WalkthroughCard?: WalkthroughCardResolvers<ContextType>
  WalkthroughPageSection?: WalkthroughPageSectionResolvers<ContextType>
}

export type DirectiveResolvers<ContextType = any> = {
  cacheControl?: CacheControlDirectiveResolver<any, any, ContextType>
}
