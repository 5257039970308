import { css } from 'linaria';
import { TFunction } from 'next-i18next';
const ulClass = "u1ooab2r";
const liClass = "l19qbhhc";
const linkClass = "l1uisxzp";

function joinZoom(t: TFunction, isDisabled: boolean): string {
  if (isDisabled) return '';
  const text = t('joinZoom', {
    ns: 'fixtureMessage'
  }).replace(/<u>/g, `<span class="${linkClass}">`).replace(/<\/u>/g, '</span>');
  return `<li class="${liClass}">${text}</li>`;
}

export const embeddedWelcomeMessage = (t: TFunction): {
  content: string;
} => ({
  content: t('embeddedWelcome.content', {
    ns: 'fixtureMessage'
  })
});
export const embeddedChatModerationAfterHoursMessage = (t: TFunction): {
  content: string;
} => ({
  content: t('embeddedChatModerationAfterHours.content', {
    ns: 'fixtureMessage'
  })
});
export const guideCombinedWelcomeMessage = (t: TFunction, isJoinDisabled: boolean = false): {
  content: string;
  footerContent: string;
} => ({
  content: t('guideCombinedWelcome.content', {
    ns: 'fixtureMessage'
  }).replace(/{{recipientName}}/g, '%GREET_USER_FIRST%').replace(/<ul>/g, `<ul class="${ulClass}">`).replace(/<lijoinzoom><\/lijoinzoom>/g, joinZoom(t, isJoinDisabled)).replace(/<li>/g, `<li class="${liClass}">`),
  footerContent: t('guideCombinedWelcome.footerContent', {
    ns: 'fixtureMessage'
  })
});
export const replayWelcomeMessage = (t: TFunction): {
  content: string;
} => ({
  content: t('replayWelcome.content', {
    ns: 'fixtureMessage'
  }).replace(/{{recipientName}}/g, '%GREET_USER_FIRST%').replace(/<ul>/g, `<ul class="${ulClass}">`).replace(/<li>/g, `<li class="${liClass}">`).replace(/<browse>/g, '<a href="https://www.getsetup.io/schedule/browse" target="_blank" rel="noopener">').replace(/<\/browse>/g, '</a>').replace(/<help>/g, '<a href="https://getsetup6054.zendesk.com/hc/en-us" target="_blank" rel="noopener">').replace(/<\/help>/g, '</a>')
});

require("../../../.linaria-cache/fixtures/messages/guide/pre_combined_welcome.linaria.module.css");