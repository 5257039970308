import { css } from 'linaria';
import React from 'react';
type LayoutProps = {
  children: React.ReactNode;
  isScrollable?: boolean;
};

const SparseLayout: React.FunctionComponent<LayoutProps> = ({
  children,
  isScrollable
}) => {
  return <div className={`${pageContainer} ${isScrollable ? scrollable : ''}`}>
      <main className={mainContainer}>{children}</main>
    </div>;
};

const pageContainer = "p1bgmxi9";
const mainContainer = "mtglwiz";
const scrollable = "soaqu9o";
export default SparseLayout;

require("../../.linaria-cache/layouts/Sparse/index.linaria.module.css");