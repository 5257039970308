import { darken } from '@theme-ui/color'
import type { Theme } from '@theme-ui/core'

const womansworldTheme: Theme = {
  colors: {
    primary: '#DA006F',
    secondary: '#85019C',
    tertiary: '#e2cda8',
    blue: '#2a5dde',
    darkGrey: '#4a4a68',
    grey: '#757582',
    grey10: '#f6f8fa',
    primary20: '#e0e9ff',
    primary70: '#b2c8ff',
    secondary20: '#feddd1',
    secondary70: '#ff7845',
    tertiary20: '#f9f5ef',
    white: 'white',
  },
  buttons: {
    primary: {
      backgroundColor: 'primary',
      borderRadius: '4px',
      textTransform: 'uppercase',
      fontWeight: 700,
      transition: `background 200ms ease-out`,

      ':disabled': {
        backgroundColor: 'primary20',
      },
      ':hover': {
        backgroundColor: darken('primary', 0.1),
      },
    },
    primaryOutline: {
      backgroundColor: 'transparent',
      border: '2px solid',
      borderColor: 'primary',
      color: 'primary',
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '16px',
      transition: `background 200ms ease-out`,
    },
    secondary: {
      backgroundColor: 'secondary',
      textTransform: 'uppercase',
      fontWeight: 700,
      fontSize: '16px',
      transition: `background 200ms ease-out`,
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: darken('secondary', 0.1),
      },
    },
    tertiary: {
      color: 'primary',
      fontWeight: 700,
      fontSize: '16px',
      transition: `background 200ms ease-out, color 200ms ease-out`,
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: 'primary',
        color: 'white',
      },
    },
    greyOutline: {
      backgroundColor: 'transparent',
      border: '1px solid grey',
      color: 'grey',
      textTransform: 'uppercase',
    },
    clearMuted: {
      backgroundColor: 'transparent',
      color: 'primary',
      textTransform: 'uppercase',
      ':disabled': {
        backgroundColor: 'secondary70',
      },
      ':hover': {
        backgroundColor: darken('primary', 0.1),
      },
    },
    slideToggle: {
      backgroundColor: 'primary',
      borderRadius: '4px 0 0 4px',
      ':hover': {
        backgroundColor: darken('primary', 0.1),
      },
    },
    chatInput: {
      close: {
        color: 'white',
        background: 'primary',
      },
      smiley: {
        color: 'white',
        background: 'primary',
        borderRadius: '4px',
      },
      send: {
        color: 'white',
        background: 'primary',
      },
    },
  },
  text: {
    primary: {
      color: 'primary',
    },
  },
  styles: {
    chat: {
      input: {
        borderRadius: '4px',
      },
      message: {
        header: {
          background: 'primary',
        },
        self: {
          background: 'blue',
        },
        other: {
          background: 'grey10',
        },
        welcome: {
          background: '#f4f4f7',
        },
      },
    },
    dialog: { container: { borderRadius: 0 } },
    message: {
      container: {
        background: 'white',
        borderRadius: 0,
      },
      title: {
        color: '#333333',
      },
      status: {
        finished: {
          color: 'primary',
        },
        private: {
          color: 'primary',
        },
        startingSoon: {
          color: 'primary',
        },
      },
    },
    overlay: {
      title: {
        borderRadius: '4px',
      },
    },
    sidebar: {
      header: {
        root: {
          backgroundColor: 'white',
        },
        counter: {
          backgroundColor: 'primary',
        },
        settings: {
          color: 'primary',
        },
        participants: {
          'img:first-child': {
            color: 'grey10',
          },
          live: {
            color: 'grey10',
            backgroundColor: 'primary',
          },
        },
      },
      tabs: {
        container: {
          background: 'white',
        },
        item: {
          "&[data-active='true']": {
            backgroundColor: 'white',
            color: 'primary',
            fontWeight: 700,
            borderBottomWidth: '4px',
            borderBottomColor: 'primary',
            borderBottomStyle: 'solid',
          },
          "&[data-active='false']": {
            backgroundColor: 'white',
            color: 'primary',
            borderBottomWidth: '1px',
            borderBottomColor: '#d6d6e0',
            borderBottomStyle: 'solid',
          },
        },
      },
      toggle: {
        borderRadius: 'unset',
      },
    },
    ratings: {
      active: {
        backgroundColor: 'primary',
        color: 'white',
      },
      passive: {
        backgroundColor: 'white',
        border: '3px solid',
        borderColor: 'primary',
        color: 'primary',
      },
    },
  },
}

export default womansworldTheme
