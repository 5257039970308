export function selectFirstIfMany(input: string | string[]): string {
  if (Array.isArray(input)) {
    return input[0]
  }
  return input
}

export function getQueryParamValue(input: string | string[] | undefined): boolean | string {
  if (!input) return false
  const value = selectFirstIfMany(input)

  if (value === 'false') {
    return false
  }

  if (value === 'true') {
    return true
  }

  return value
}

export function firstStringVal(input: string | string[] | undefined): string {
  if (!input) return null

  return selectFirstIfMany(input)
}

export function strToBool(input: string): boolean {
  try {
    return !!JSON.parse(input)
  } catch {
    return false
  }
}
