import { useEffect } from 'react'
import { useExternalToken } from '~/hooks/useExternalToken'
import { useAuth } from '~/store/auth'

interface Props {
  children?: JSX.Element | undefined
}

export const ExternalAuthHandler = ({ children }: Props): JSX.Element | undefined => {
  const { externalUserId, encodedToken, decodedToken } = useExternalToken()
  const [, setAuth] = useAuth()

  useEffect(() => {
    setAuth({ encodedExternalToken: encodedToken, externalUserId: externalUserId, decodedExternalToken: decodedToken })
  }, [encodedToken])

  return children
}
