import { css } from 'linaria';
interface Props {
  loading: boolean;
}
export default function Loader({
  loading
}: Props): JSX.Element {
  if (!loading) return null;
  return <span className={circle_spinner}></span>;
}
const circle_spinner = "c1wph0gj";

require("../../../.linaria-cache/components/widgets/Loader/index.linaria.module.css");