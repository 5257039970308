import React from 'react'
import { ICONS, SIZES } from '~/utils/icons'

interface Props {
  className?: string
  color?: string
  icon: ICONS
  size?: SIZES
  marginLeft?: boolean
  marginRight?: boolean
  onClick?: any
}

function getSize(size: SIZES): string {
  switch (size) {
    case SIZES.SMALL:
      return '1rem'

    case SIZES.SMALL_PLUS:
      return '1.125rem'

    case SIZES.NORMAL:
      return '1.5rem'

    case SIZES.NORMAL_PLUS:
      return '1.75rem'

    case SIZES.MEDIUM:
      return '2rem'

    case SIZES.LARGE:
      return '3rem'

    default:
      return '1rem'
  }
}

export default function Icon({ className, color, icon, marginLeft, marginRight, size, onClick }: Props): JSX.Element {
  function handleOnClick(): void {
    if (onClick) onClick()
  }

  return (
    <span
      className={`${icon} ${className ?? ''}`}
      style={{
        color: color ?? '',
        fontSize: getSize(size),
        marginLeft: marginLeft ? '0.25rem' : 0,
        marginRight: marginRight ? '0.25rem' : 0,
      }}
      onClick={handleOnClick}
    ></span>
  )
}
