export enum ICONS {
  ACTIVITY = 'icon-activity',
  ALARM = 'icon-alarm',
  ALERT = 'icon-alert',
  APPS = 'icon-apps',
  ARCADE = 'icon-arcade',
  ARCHIVE = 'icon-archive',
  ARROW_DOWN = 'icon-arrow-down',
  ARROW_LEFT = 'icon-arrow-left',
  ARROW_RIGHT = 'icon-arrow-right',
  ARROW_UP = 'icon-arrow-up',
  AUDIO_STATUS_ACTIVE = 'icon-audio-status-active',
  AUDIO_STATUS = 'icon-audio-status',
  AWARD = 'icon-award',
  BELL = 'icon-bell',
  BOOK = 'icon-book',
  BOX = 'icon-box',
  BRIEFCASE = 'icon-briefcase',
  BULB = 'icon-bulb',
  CALCULATOR = 'icon-calculator',
  CALENDAR_FULL = 'icon-calendar-full',
  CALENDAR = 'icon-calendar',
  CAMERA_PLUS = 'icon-camera-plus',
  CAPTURE = 'icon-capture',
  CHAT = 'icon-chat',
  CHECK = 'icon-check',
  CHECKBOX = 'icon-checkbox',
  CHECKET = 'icon-checket',
  CHECKS = 'icon-checks',
  CHEVRON_DOWN = 'icon-chevron-down',
  CHEVRON_LEFT = 'icon-chevron-left',
  CHEVRON_RIGHT = 'icon-chevron-right',
  CHEVRON_UP = 'icon-chevron-up',
  CLASS = 'icon-class',
  CLICK = 'icon-click',
  CLIPBOARD_CHECK = 'icon-clipboard-check',
  CLIPBOARD = 'icon-clipboard',
  CLOCK = 'icon-clock',
  CLOSE = 'icon-close',
  COMPASS = 'icon-compass',
  COPY = 'icon-copy',
  CROWN_FULL = 'icon-crown-full',
  DESKTOP = 'icon-desktop',
  DEVICES = 'icon-devices',
  DIALOG = 'icon-dialog',
  DICE = 'icon-dice',
  DIRECTIONS = 'icon-directions',
  DOWNLOAD = 'icon-download',
  DURATION = 'icon-duration',
  EAR = 'icon-ear',
  EDIT = 'icon-edit',
  ENTER = 'icon-enter',
  EXIT = 'icon-exit',
  EXPLORE_FULL = 'icon-explore-full',
  EXPLORE = 'icon-explore',
  EXPOSURE = 'icon-exposure',
  EYE_OFF = 'icon-eye-off',
  EYE = 'icon-eye',
  FACE_ID = 'icon-face-id',
  FACEBOOK_EMPTY = 'icon-facebook-empty',
  FACEBOOK_FULL = 'icon-facebook-full',
  FILE_TEXT = 'icon-file-text',
  FILE = 'icon-file',
  FILTERS = 'icon-filters',
  FINGERPRINT = 'icon-fingerprint',
  FLAG = 'icon-flag',
  FLAME = 'icon-flame',
  FLIP_CAMERA = 'icon-flip-camera',
  FOCUS = 'icon-focus',
  FOLDER = 'icon-folder',
  FOLDERS = 'icon-folders',
  FRIENDS = 'icon-friends',
  GAMEPAD = 'icon-gamepad',
  GHOST = 'icon-ghost',
  GIFT = 'icon-gift',
  GLASS_FULL = 'icon-glass-full',
  GLOBE = 'icon-globe',
  GRID_DOTS = 'icon-grid-dots',
  HEART = 'icon-heart',
  HELP = 'icon-help',
  HISTORY = 'icon-history',
  HOME_FULL = 'icon-home-full',
  HOME = 'icon-home',
  INFO = 'icon-info',
  INSTAGRAM = 'icon-instagram',
  INTRODUCTION_VIEW = 'icon-introduction-view',
  KEY = 'icon-key',
  LANGUAGE = 'icon-language',
  LAYOUT_HORIZONTAL = 'icon-layout-horizontal',
  LAYOUT_LIST = 'icon-layout-list',
  LAYOUT_VERTICAL = 'icon-layout-vertical',
  LEAF = 'icon-leaf',
  LINK = 'icon-link',
  LINKEDIN_FULL = 'icon-linkedin-full',
  LINKEDIN = 'icon-linkedIn',
  LIST_CHECKS = 'icon-list-checks',
  LIST = 'icon-list',
  LIVE = 'icon-live',
  LOADER_CIRCLE = 'icon-loader-circle',
  LOADER = 'icon-loader',
  LOCATION = 'icon-location',
  LOCK_OPEN = 'icon-lock-open',
  LOCK = 'icon-lock',
  LOGIN = 'icon-login',
  LOGOUT = 'icon-logout',
  MAIL = 'icon-mail',
  MAP_PIN = 'icon-map-pin',
  MAXIMIZE = 'icon-maximize',
  MENU = 'icon-menu',
  MIC_OFF = 'icon-mic-off',
  MIC = 'icon-mic',
  MINIMIZE = 'icon-minimize',
  MOBILE = 'icon-mobile',
  MORE_ACTIONS = 'icon-more-actions',
  MOVE_BTM = 'icon-move-btm',
  MOVE_TOP = 'icon-move-top',
  NEWBIE = 'icon-newbie',
  NEWS_FULL = 'icon-news-full',
  NEWS = 'icon-news',
  NOTE = 'icon-note',
  NOTEBOOK = 'icon-notebook',
  NOTES = 'icon-notes',
  PAPERCLIP = 'icon-paperclip',
  PAUSE = 'icon-pause',
  PEN = 'icon-pen',
  PEOPLE_ADD = 'icon-people-add',
  PHONE_CALL = 'icon-phone-call',
  PHOTO = 'icon-photo',
  PINNED = 'icon-pinned',
  PLANS = 'icon-plans',
  PLAY_FULL = 'icon-play-full',
  PLAY = 'icon-play',
  PLUS = 'icon-plus',
  POP_OUT = 'icon-pop-Out',
  PRESENTATION = 'icon-presentation',
  PUZZLE = 'icon-puzzle',
  QUESTION = 'icon-question',
  RADIO_OFF = 'icon-radio-off',
  REACT = 'icon-react',
  RESET = 'icon-reset',
  RESIZE_MAXIMISE = 'icon-resize-maximise',
  RESIZE_MINIMISE = 'icon-resize-minimise',
  RETRY = 'icon-retry',
  ROOM = 'icon-room',
  ROTATE = 'icon-rotate',
  SAD = 'icon-sad',
  SCHOOL = 'icon-school',
  SCREEN_SHARE_OFF = 'icon-screen-share-off',
  SEARCH = 'icon-search',
  SELECTOR = 'icon-selector',
  SEND = 'icon-send',
  SETTINGS = 'icon-settings',
  SHAPES = 'icon-shapes',
  SHARE_SCREEN = 'icon-share-screen',
  SHARE = 'icon-share',
  SHIELD_CHECK = 'icon-shield-check',
  SMILE = 'icon-smile',
  SPEAKERPHONE = 'icon-speakerphone',
  STAR_EMPTY = 'icon-star-empty',
  STAR_FULL = 'icon-star-full',
  STOP = 'icon-stop',
  SWITCH_CAMERA = 'icon-switch--camera',
  TABLET = 'icon-tablet',
  TAG = 'icon-tag',
  TRASH = 'icon-trash',
  TROPHY = 'icon-trophy',
  TWITTER_FULL = 'icon-twitter-full',
  TWITTER = 'icon-twitter',
  UPLOAD = 'icon-upload',
  USER_CHECK = 'icon-user-check',
  USER_FULL = 'icon-user-full',
  USER = 'icon-user',
  USERS = 'icon-users',
  VIDEO_OFF = 'icon-video-off',
  VIDEO = 'icon-video',
  VIEW_COLUMNS = 'icon-view-columns',
  VIEW_GALLERY = 'icon-view-gallery',
  VIEW_ONE_TO_ONE_OFF = 'icon-view-one-to-one-off',
  VIEW_ONE_TO_ONE = 'icon-view-one-to-one',
  VIEW_SPEAKER = 'icon-view-speaker',
  VIEW_STAGE = 'icon-view-stage',
  VOLUME_OFF = 'icon-volume-off',
  VOLUME = 'icon-volume',
  WINDOW = 'icon-window',
  YOUTUBE = 'icon-youtube',
}

export enum SIZES {
  SMALL,
  SMALL_PLUS,
  NORMAL,
  NORMAL_PLUS,
  MEDIUM,
  LARGE,
}
