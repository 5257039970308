import { useRouter } from 'next/router'
import { Dispatch, ReactNode, useEffect } from 'react'
import { io } from 'socket.io-client'
import { getConfig } from '~/config/config'
import { getDeviceId } from '~/libs/device'
import { useAuth } from '~/store/auth'
import { useIO } from '~/store/socket'
import { Action, SessionState, useAppContext } from '~/store/state'

const { publicRuntimeConfig: config } = getConfig()

interface Props {
  children: ReactNode | ReactNode[]
  dispatch: Dispatch<Action>
  roomId: string
  classId?: string
  classTitle?: string
}

export function ConnectionHandler(props: Props): JSX.Element {
  const router = useRouter()
  const { state } = useAppContext()
  const [IO, setIO] = useIO()
  const [auth] = useAuth()

  const isAuthed = auth.isAuthed
  const externalToken = auth.encodedExternalToken
  const { children, dispatch, roomId, classId, classTitle } = props

  // Use a "run once" useEffect to set up the socket and event listeners.
  // We do not actually connect the socket to the server in this block,
  // we only set up the lobby side of the connection and will start the connection
  // in the next useEffect below that will run every time auth changes.
  // So we only ever setup the connection once, but we can connect and disconnect
  // when we get a token from a hosting site, or the token expires/
  // If we are running in non-embedded (getsetup.io) mode then both these
  // useEffects will run once and only once because the isAuthed property will
  // never change.
  useEffect(() => {
    /**
     * Don't connect to socket server if
     * 1. User is not authenticated.
     * 2. Chat is disabled by query string argument.
     * */
    if (state.disableChat) return

    let connection = IO.connection
    //If we don't have a current connection then create a new connection and store it.
    if (!connection) {
      connection = io(config.orchestrationService, { transports: ['websocket'] })
      setIO({
        connection,
        error: false,
        ready: false,
      })
    }

    // Set up event listeners for the socket.

    // When we connect we will automatically send an auth message to the server
    // and ask for session state.
    connection.on('connect', () => {
      console.log('***** Connected to chat server')
      setIO({
        error: false,
      })
    })

    connection.on('connect_error', (error) => {
      console.log('***** Failed connection to chat server')
      setIO({
        error: true,
      })
    })

    connection.on(
      'authed',
      ({ success, isExternalUserAssignedName }: { success: boolean; isExternalUserAssignedName: boolean }) => {
        if (!success) {
          console.log('***** Socket failed to authenticate - disconnecting')
          connection.disconnect()
          return
        }

        console.log('***** Connected & authenticated socket', { success, isExternalUserAssignedName })

        dispatch({ type: 'SET_EXTERNAL_USER_ASSIGNED_NAME', payload: isExternalUserAssignedName })

        setIO({
          error: false,
          ready: true,
        })
      },
    )

    connection.on('sessionStateBootstrap', (data: SessionState): void => {
      dispatch({ type: 'SET_SESSION_STATE_READY', payload: true })
      dispatch({ type: 'SET_JOIN_DISABLED', payload: data.disableJoin })
    })

    connection.on('sessionStateUpdate', (data: SessionState): void => {
      dispatch({ type: 'SET_JOIN_DISABLED', payload: data.disableJoin })
    })

    connection.on('disconnect', () => {
      console.log('***** Setting disconnect state')
      // We don't dispose of the connection in the state object because
      // we might want to reconnect with the same socket later.
      setIO({
        error: true,
        ready: false,
      })
    })

    //We are setup and connected, now the other useEffect below will run and ask for auth and the session state.

    return () => {
      //Clean up
      connection.disconnect()
      setIO({
        connection: null,
        error: false,
        ready: false,
      })
    }
  }, [])

  // Run this useEffect every time auth changes.
  useEffect(() => {
    // Don't connect to socket server if Chat is disabled.
    if (state.disableChat) return
    // Get current connection.
    let connection = IO.connection

    // If we don't have  a connection, then no point in doing anything.
    if (!connection) {
      return
    }

    // We don't have currently valid auth, just give up.
    // But allow Woman's World to auth anonymously.
    if (!isAuthed && state.embeddingOrgId !== 'womansworld') {
      connection.disconnect()
      return
    }

    // We have a connection but we need to make sure the connection is actually connected.
    // We might have disconnected because of an auth failure before.
    // Either way, we need to send an auth message with the new tokens, so the server can reject us if the token isn't valid.
    // Then our on 'authed' listener above will disconnect the socket if we fail auth.

    if (!connection.connected) {
      connection.connect()
    }

    // So now we are connected and in one of three states:
    // 1) We *are not* Woman's World and we have an auth token.
    // 2) We *are* Woman's World and we have an auth token.
    // 3) We *are* Woman's World and we *do not* have an auth token.

    // Case one, we *are not* Woman's World and we have an auth token.
    if (state.embeddingOrgId !== 'womansworld') {
      // Send an auth message with the current tokens.
      console.log('***** Socket emitting auth')
      connection.emit('auth', {
        token: auth.encodedGsuToken,
        externalAuthToken: externalToken,
        room: roomId,
        classId: classId,
        classTitle: classTitle,
        isEmbeddedLobby: state.embeddingOrgId !== undefined,
        embeddingOrgId: state.embeddingOrgId,
      })
      // Get the session state.
      connection.emit('bootstrapSessionState')
      return
    }

    // Case two, we *are* Woman's World and we have an auth token.
    if (state.embeddingOrgId === 'womansworld' && isAuthed) {
      // Send an auth message with the current tokens.
      console.log('***** Socket emitting auth')
      connection.emit('auth', {
        token: auth.encodedGsuToken,
        externalAuthToken: externalToken,
        room: roomId,
        classId: classId,
        classTitle: classTitle,
        isEmbeddedLobby: state.embeddingOrgId !== undefined,
        embeddingOrgId: state.embeddingOrgId,
      })
      // Get the session state.
      connection.emit('bootstrapSessionState')
      return
    }

    // Case three, we *are* Woman's World and we *do not* have an auth token.
    if (state.embeddingOrgId === 'womansworld' && !isAuthed) {
      getDeviceId().then((userId) => {
        console.log('***** Socket emitting (anonymous) auth', { userId })
        connection.emit('embedNoAuth', {
          userId,
          room: roomId,
          classId: classId,
          isEmbeddedLobby: true,
          partnerId: state.embeddingOrgId,
        })

        // Get the session state.
        connection.emit('bootstrapSessionState')
      })
      return
    }
  }, [router.query, isAuthed, externalToken])

  return <>{children}</>
}
