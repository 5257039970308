import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'next-i18next'
import Icon from '~/components/v2/icon'
import Dialog from '~/components/widgets/Dialog'
import { useChat, useDialog } from '~/contexts'
import { joinSessionMessage } from '~/fixtures/messages/broadcast'
import { LivenessType, LobbySession } from '~/shared/api-generated-types'
import { getConnection } from '~/store/socket'
import { ICONS, SIZES } from '~/utils/icons'
import * as styles from './styles'

interface Props {
  lobbySession: LobbySession | undefined
}

export default function SettingsDialog({ lobbySession }: Props): JSX.Element {
  const { t } = useTranslation('lobby')
  const { connection } = getConnection()
  const { disableJoin } = useChat()
  const { toggleSettingsDialog } = useDialog()
  const isReplay = lobbySession?.livenessType === LivenessType.Replay
  const [isChecked, setIsChecked] = useState(disableJoin)

  function toggleJoin(): void {
    setIsChecked(!isChecked)
    if (connection) connection.emit('setSessionState', { sessionId: lobbySession?.id, disableJoin: !isChecked })
    if (isChecked) {
      toast.success(t('launchMeetingEnabled'), { duration: 3500 })
    } else {
      toast.success(t('launchMeetingDisabled'), { duration: 3500 })
    }
  }

  function copyUrlToClipBoard(url: string): void {
    if (!url) {
      toast.error(t('urlNotAvailable'), { duration: 3500 })
      return
    }

    const el = document.createElement('textarea')
    el.value = url
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    toast.success(t('urlCopiedToClipboard'), { duration: 3500 })
  }

  const meetingUrl = lobbySession?.launchMeetingUrl

  function broadcast(): void {
    if (connection)
      connection.emit('message', {
        content: joinSessionMessage(t),
        link: meetingUrl,
        type: 'broadcast',
      })
    toast.success(t('launchMeetingUrlWillBeBroadcasted'), { duration: 3500 })
  }

  function earlyAccess(): void {
    if (meetingUrl) window.open(meetingUrl)
  }

  return (
    <Dialog customClass={styles.customClass} onClose={toggleSettingsDialog}>
      <div className={styles.container}>
        <h1 className={styles.heading}>{t('sessionSettings')}</h1>
        {isReplay ? <div className={styles.settingsDialogReplayMessage}>{t('sessionIsReplayZoomDisabled')}</div> : null}

        {/* Wrapper for Zoom specific settings */}
        <div className={isReplay ? styles.settingsDialogDisabledZoomSettings : ''}>
          <div className={styles.disableJoin}>
            <span>{t('disableJoining')}</span>
            <span>
              <input type="checkbox" className={styles.checkbox} checked={isChecked} onChange={toggleJoin} />
            </span>
          </div>
          <hr className={styles.border} />
          <div className={styles.row}>
            <div>{t('zoomJoinUrl')}</div>
            <div className={styles.buttonContainer}>
              <button className={styles.blueButton} onClick={() => copyUrlToClipBoard(meetingUrl)}>
                <Icon icon={ICONS.COPY} size={SIZES.SMALL} />
                <span>{t('copy')}</span>
              </button>
              <button className={styles.blueButton} onClick={broadcast}>
                <Icon icon={ICONS.LIVE} size={SIZES.SMALL} />
                <span>{t('broadcast')}</span>
              </button>
            </div>
          </div>
          <br />
          <div className={styles.row}>
            <div>{t('zoomEarlyAccess')}</div>
            <div className={styles.buttonContainer}>
              <button className={styles.orangeButton} onClick={earlyAccess}>
                <Icon icon={ICONS.VIDEO} size={SIZES.SMALL} />
                <span>{t('launchZoom')}</span>
              </button>
            </div>
          </div>
          <br />
          <div className={styles.row}>
            <div>{t('liveStreamUrl')}</div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.blueButton}
                onClick={() => copyUrlToClipBoard(lobbySession?.stream?.playbackUrl)}
              >
                <Icon icon={ICONS.COPY} size={SIZES.SMALL} />
                <span>{t('copy')}</span>
              </button>
            </div>
          </div>
          <hr className={styles.border} />
          <div className={styles.row}>
            <div>{t('sessionIsStreamable')}</div>
            <div>
              {lobbySession?.canHaveStream && <Icon color="#23a868" icon={ICONS.CHECK} size={SIZES.MEDIUM} />}
              {!lobbySession?.canHaveStream && <Icon color="#e61535" icon={ICONS.CLOSE} size={SIZES.MEDIUM} />}
            </div>
          </div>
          {lobbySession?.nonStreamableReason && (
            <div className={styles.row}>
              <div>{t('nonStreamableReasonLabel')}</div>
              <div>{lobbySession?.nonStreamableReason}</div>
            </div>
          )}
          <hr className={styles.border} />
          <h1 className={styles.heading}>{t('streamConfig')}</h1>
          <div>
            <div>{t('streamKeyLabel')}</div>
            <div className={styles.code}>{lobbySession?.stream?.streamKey}</div>
          </div>
          <br />
          <div>
            <div>{t('ingestServerLabel')}</div>
            <div className={styles.code}>{lobbySession?.stream?.ingestServer}</div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
