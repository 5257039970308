import nextGetConfig from 'next/config'
import * as s from 'superstruct'

export const EmbeddingPartners = s.enums([
  'aol',
  'gsudemo',
  'imedia',
  'womansworld',
  'silversneakers',
  'silversneakers-aetna',
  'silversneakers-fitness',
])
export type EmbeddingPartners = s.Infer<typeof EmbeddingPartners>

const EmbeddedNotificationsConfig = s.object({
  affiliateMarketing: s.object({
    waitForMilliseconds: s.number(),
  }),
  askFeedback: s.object({
    waitForMilliseconds: s.number(),
  }),
})

const EmbeddedAffiliateMarketingConfig = s.object({
  isEnabled: s.boolean(),
  enabledInListingPages: s.array(s.enums(['fitness', 'learn'])),
  targetPageUrl: s.string(),
})

const EmbeddedPartnerSpecificConfig = s.object({
  affiliateMarketing: s.optional(EmbeddedAffiliateMarketingConfig),
})

const EmbedsConfig = s.object({
  global: s.object({
    notifications: EmbeddedNotificationsConfig,
  }),
  partner: s.record(EmbeddingPartners, s.optional(EmbeddedPartnerSpecificConfig)),
})
type EmbedsConfig = s.Infer<typeof EmbedsConfig>

const PublicRuntimeConfig = s.object({
  environment: s.string(),
  api: s.string(),
  getsetup_io_url: s.string(),
  orchestrationService: s.string(),
  recordedSessionSourceBaseURL: s.string(),
  lobbyBaseURL: s.string(),
  events: s.object({
    cookieDomain: s.string(),
  }),
  website: s.object({
    cookieDomain: s.string(),
  }),
  facebook: s.object({
    appID: s.number(),
  }),
  zoomConfiguration: s.object({
    jwtAPIKey: s.string(),
  }),
  googleTagManager: s.object({
    ID: s.string(),
  }),
  encores: s.object({
    autoplayTimeout: s.number(), // Timeout for autoplaying encores without user's interaction
  }),
  dialogs: s.object({
    auth: s.object({
      delay: s.number(), // delay in opening auth dialog for unauthenticated users while waiting/watching in session
    }),
  }),
  embeds: EmbedsConfig,
})
type PublicRuntimeConfig = s.Infer<typeof PublicRuntimeConfig>

const ServerRuntimeConfig = s.object({
  sitesAllowedToEmbed: s.array(s.object({ embeddingOrgId: s.string(), hosts: s.array(s.string()) })),
})
type ServerRuntimeConfig = s.Infer<typeof ServerRuntimeConfig>

const Config = s.object({
  publicRuntimeConfig: PublicRuntimeConfig,
  serverRuntimeConfig: s.optional(ServerRuntimeConfig),
})
type Config = s.Infer<typeof Config>

let config: Config

export function getConfig(resetSingleton = false): Config {
  if (config && !resetSingleton) {
    return config
  }

  const { serverRuntimeConfig, publicRuntimeConfig } = nextGetConfig()

  config = {
    serverRuntimeConfig: isEmpty(serverRuntimeConfig) ? undefined : serverRuntimeConfig,
    publicRuntimeConfig,
  }

  s.assert(config, Config)

  return config
}

function isEmpty(o: Record<string, unknown>): boolean {
  return Boolean(o) && Object.keys(o).length === 0
}
