import React from 'react'
import { useTranslation } from 'next-i18next'
import Button from '~/components/buttons/Button'
import Dialog from '~/components/widgets/Dialog'
import * as styles from './styles'
import { useDialog } from '~/contexts'

export default function EarlyDialog(): JSX.Element {
  const { t } = useTranslation('lobby')
  const { toggleEarlyDialog } = useDialog()

  return (
    <Dialog customClass={styles.customClass} onClose={toggleEarlyDialog}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <img src="/icons/v2/teacup.svg" width="96px" />
        </div>
        <h1 className={styles.heading}>{t('tooEarlyToClassInfo')}</h1>
        <h3 className={styles.subheading}>{t('tooEarlyToClassInfoSubtitle')}</h3>
        <div className={styles.buttonContainer}>
          <Button primary rounded small fullWidth onClick={toggleEarlyDialog}>
            {t('okay')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
