import { css } from 'linaria';
export const customClass = "c14eye5b";
export const container = "c1v1ig5k";
export const buttonContainer = "b1q4irjk";
export const imageContainer = "i13dsksn";
export const heading = "hr2nrwr";
export const subheading = "s1lvs6st";
export const separator = "s1foi8ij";
export const orText = "o1az1hqp";

require("../../../../.linaria-cache/components/v2/dialogs/Early/styles.linaria.module.css");